import React, { useRef } from "react";
import Blog from "../../Component/Blog/Blog";
import SignUpBlog from "../../Component/BlogsPage/SignUpBlog/SignUpBlog";
import ScooPageBanner from "../../Component/ScooPage/ScooPageBanner/ScooPageBanner";
import ScooPageBlog from "../../Component/ScooPage/ScooPageBlog/ScooPageBlog";
import ScooPageInfoOne from "../../Component/ScooPage/ScooPageInfoOne/ScooPageInfoOne";
import ScooPageInfoTwo from "../../Component/ScooPage/ScooPageInfoOne/ScooPageInfoTwo";
import ScooPageIoT from "../../Component/ScooPage/ScooPageIoT/ScooPageIoT";
import ScooPagePartnership from "../../Component/ScooPage/ScooPagePartnership/ScooPagePartnership";
import ScooPara from "../../Component/ScooPage/ScooPara/ScooPara";
import FAQs from "../../Component/ScooLuxe/FAQs/FAQs";
import "./scooPage.css";
import NesLetter from "../../Component/NewsLetterSection/NesLetter";
import { PreneuraccordianData } from "../../data/dataApi";
import { Helmet } from "react-helmet";

export default function ScooPage() {
  const bannerRef = useRef(null);
  const handleScrollDown = () => {
    bannerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <>
      <div className="ScooPageContainer">
        <Helmet>
          <title>Join the Let's Scoo Revolution in Bengaluru | ScooEV</title>
          <meta
            name="description"
            content="Join the Let's Scoo revolution and ride into a cleaner, brighter future with ScooEV. "
          />
          <meta
            name="keywords"
            content="Electric Bike Rental in Bangalore, Electric Bikes on Rental Near Me, Ather  Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore,Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike.  "
          />
        </Helmet>
        <ScooPageBanner handleScrollDown={handleScrollDown} />
        <ScooPara />
        <ScooPageInfoOne />
        {/* <ScooPageInfoTwo /> */}
        {/* <ScooPagePartnership /> */}
        <ScooPageIoT bannerRef={bannerRef} />
        {/* <ScooPageBlog /> */}
        {/* <Blog /> */}
        {/* <SignUpBlog /> */}
        {/* <NesLetter /> */}
        <FAQs faqdata={PreneuraccordianData} />
      </div>
    </>
  );
}
