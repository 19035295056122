import React from "react";
import Blog from "../Component/Blog/Blog";
import SignUpBlog from "../Component/BlogsPage/SignUpBlog/SignUpBlog";
import NesLetter from "../Component/NewsLetterSection/NesLetter";
import EnrollNow from "../Component/ScooNinja/EnrollNow/EnrollNow";
import HeroBanner from "../Component/ScooNinja/HeroBanner/HeroBanner";
import KYC from "../Component/ScooNinja/KYC/KYC";
import KYCWithoutNumber from "../Component/ScooNinja/KYC/KYCWIthoutNumber";
import SubBanner from "../Component/ScooNinja/SubBanner/SubBanner";
import Testimonial from "../Component/ScooNinja/Testimonial/Testimonial";
import WhyBecomeScooNinja from "../Component/ScooNinja/WhyBecomeScooNinja/WhyBecomeScooNinja";
import { Helmet } from "react-helmet";

const ScooNinja = () => {
  return (
    <div>
      <Helmet>
        <title>
          Become a Scoo Ninja Now | Scoo Ninja in Bangalore - ScooEV
        </title>
        <meta
          name="description"
          content="You can earn effortlessly and save big on fuel and maintenance! Become a Scoo Ninja now and electrify your dreams! Scoo Ninja in Bangalore."
        />
        <meta
          name="keywords"
          content="Electric Bike Rental in Bangalore, Scoo Ninja in Bangalore, Electric Bikes on Rental Near Me, Ather Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore  , Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike . "
        />
      </Helmet>
      <HeroBanner />
      <SubBanner />
      <WhyBecomeScooNinja />
      {/* <EnrollNow /> */}
      <KYCWithoutNumber />
      {/* <Testimonial /> */}
      {/* <Blog /> */}

      {/* <NesLetter /> */}
    </div>
  );
};

export default ScooNinja;
