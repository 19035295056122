import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import "./scooPageBanner.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import rentIcon from "../../../Assets/image/logo/Icon.png";
import letscoo from "../../../Assets/image/Banner/scoo.webp";
import rupee from "../../../Assets/image/logo/₹.png";

export default function ScooPageBanner(props) {
  const { handleScrollDown } = props;

  return (
    <>
      <Container fluid className="scoo-banner-container  md-lg-2 prenure_bg">
        <section className="heroBanner_wrapper">
          <Container className="mt-lg-5 herobanner_center ">
            <Row className="d-flex py-4">
              <Col lg={5} className="banner_wrapper ">
                <div className="pre-wrap">
                  <p className="pre_header">
                    👍 Most reliable and eco-friendly E-scooter rental platform
                    in Bangalore
                  </p>
                  <h3 className="mb-3">
                    <span className="pre_header2 mb-2">
                      Ever thought of managing your own
                    </span>
                    <span className="pre_header3">EV Fleet? </span>
                  </h3>
                  <p className="pre-para ">
                    Scoo Preneur gives you the opportunity to kick start your EV
                    rental business in your area with some of the best e-bikes
                    in town. Earn well with the benefits of Scoo Preneur at your
                    own ease.
                  </p>
                  <div className="d-flex btn_Wrapper">
                    <Button className="luxe_btn  d-flex">
                      <div>
                        <img
                          src={rentIcon}
                          alt="Luxury EV for rent"
                          className="rentluxe_img"
                        />
                      </div>

                      <div>
                        <Link
                          to="https://scooev.com/Contact"
                          className="link_lux"
                          target="_blank"
                        >
                          <spna className="">Rent now</spna>
                        </Link>
                      </div>
                    </Button>
                  </div>
                </div>
              </Col>

              <Col lg={6} className="py-lg-5">
                <img
                  src={letscoo}
                  alt="Luxury EV for rent "
                  className="scoohero mx-lg-3 circle_img"
                />
                {/* <img src={rupee} alt="" className="rupee" /> */}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
}
