import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../RentalBanner/Rental.css";
import down from "../../../Assets/image/Rentals/down.png";
import arrow from "../../../Assets/image/Rentals/Right.png";
import LeftArrow from "../../../Assets//image/Rentals/arrow2.png";
import rightArrow from "../../../Assets//image/Rentals/arrow.png";
import RentalCard from "../RentalCard/RentalCard";

import {
  RentalsData,
  RangeData,
  Rentals2Data,
  RangeData2,
} from "../../../data/dataApi";
import upIcon from "../../../Assets/image/Rentals/upIcon.png";
import ContactUsModal from "../../CommonComponents/ContactUsModal/ContactUsModal";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import scooty from "../../../Assets/image/Rentals/scootyGreen.webp";
import animationData from "../../../data/datalotti.json";
import Lottie from "react-lottie";
import rentIcon from "../../../Assets/image/header/Icon.svg";

const RentalBanner = () => {
  let sliderRef = React.useRef();
  let sliderRef2 = React.useRef();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const handleArrowClick = (isVisible) => {
    setIsCardVisible(isVisible);
  };

  const [isCardVisible2, setIsCardVisible2] = useState(false);
  const handleArrowClick2 = (isVisible2) => {
    setIsCardVisible2(isVisible2);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container fluid className="lottiBanner_light  landdding_banner">
        <Container className="containerGeenBanner ">
          <Row className="d-flex ">
            <Col lg={5} md={5} sm={12} className="banner_wrapper">
              <div className="mt-lg-5 mt-md-5">
                <h5 className="banner_headbg lotti_headmax mb-3 green_head">
                  👍 Most reliable and eco-friendly E-scooter rentals platform
                  in Bengaluru
                </h5>
                <h3 className="">
                  <span className="banner_header scoo_white scoo_white2 green_para">
                    Scoo EV is leading the <br />
                    way in
                    <span className="craft_banner mx-3">
                      rider-centric <br /> e-bike rentals
                    </span>
                  </span>
                  {/* <br></br>
                            <span className="craft_banner">{details}</span> */}
                </h3>
                <p className="banner_para scoo_white mb-3">
                  Our e-scooters are customizable based on your needs and usage.
                  Scoo delivers the perfect EV bike for you.
                </p>
                <div className="d-flex p-lg-0 m-lg-0">
                  <Link
                    to="https://scoomerchandise.myshopify.com"
                    className="link_text"
                    target="_blank"
                  >
                    <Button className="rent_btn  d-flex">
                      <div>
                        <img
                          src={rentIcon}
                          alt="Scoo Rental"
                          className="rent_img mt-1 me-2"
                        />
                      </div>

                      <div>
                        <spna className="">Rent now</spna>
                      </div>
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={7} md={7} sm={12} className="p-lg-5  green_scooty_col">
              <img
                src={scooty}
                alt="Scoo Rental"
                className="p-lg-5 mx-lg-5 mt-lg-4"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="rentalsBannter_bg  ">
        <Row className="mb-lg-4  mb-4 padding_Title mt-lg-5 mt-md-4 p-lg-5">
          <Col
            lg={12}
            md={12}
            sm={12}
            className="border_title  rentaltop_scooty  py-lg-5  mb-3"
          >
            {/* <section className="rentalCard_section">
              <Row>
                <Col lg={6}>
                  <div className="slider_wrapper mb-4  mt-4">
                    <div>
                      <img
                        src={LeftArrow}
                        alt="Scoo Rental"
                        className="icon_arrows Next_ARROW"
                        onClick={() => sliderRef?.current?.slickPrev()}
                      />
                    </div>
                    <div>
                      <Slider {...settings} ref={sliderRef} className="">
                        {RentalsData.map((data) => {
                          const { id, img } = data;
                          return (
                            <Col
                              md={3}
                              sx={12}
                              key={id}
                              className="center_Title"
                            >
                              <div className="mx-3"></div>
                              <div className="racer_wrraper   ">
                                <img
                                  src={img}
                                  alt="Scoo Rental"
                                  className="rentalimg_banner mt-3  "
                                />
                              </div>
                            </Col>
                          );
                        })}
                      </Slider>
                    </div>

                    <div>
                      <img
                        src={rightArrow}
                        alt="Scoo Rental"
                        className="icon_arrows prve_ARROW"
                        onClick={() => sliderRef?.current?.slickNext()}
                      />
                    </div>
                  </div>

                  <Row className="mb-lg-4 nyxLEft_padding ">
                    <Col xl={3} lg={4} md={4} sm={12} className="p-0 m-0">
                      <div>
                        <h4 className="NYX_title d-flex ">Scoo NYX</h4>
                      </div>
                    </Col>
                    <Col
                      xl={9}
                      lg={8}
                      md={8}
                      className="py-lg-1 line_nyxnone p-0 m-0"
                    >
                      <span>
                        <hr className="nyx_line" />
                      </span>
                    </Col>
                  </Row>
                </Col>

                <Col lg={6}>
                  <Row className="mb-lg-4 nyxLEft_padding mt-3">
                    {RangeData.map((item) => {
                      const { id, img, title, kmeters } = item;
                      return (
                        <>
                          <Col md={12} className="range_col m-0 mb-3" key={id}>
                            <Card className="nyx_Card ">
                              <div>
                                <ul className="nyx_list mb-2">
                                  <li>
                                    <div className="mb-1">
                                      <img src={img} alt="Scoo Rental" />
                                    </div>
                                  </li>
                                  <li>
                                    <div className="speed_Title">{title}</div>
                                  </li>
                                  <li>
                                    <div className="km_title">{kmeters}</div>
                                  </li>
                                </ul>
                              </div>
                            </Card>
                          </Col>
                        </>
                      );
                    })}
                  </Row>

                  <Row className=" nyxLEft_padding ">
                    <Col md={12} className="padding_bottom">
                      <div
                        className="rentnow_btn "
                        onClick={
                          isCardVisible === true
                            ? () => handleArrowClick(false)
                            : () => handleArrowClick(true)
                        }
                      >
                        <div className="rent_now">Select Plan</div>
                        <div>
                          <img
                            src={down}
                            // onClick={() => handleArrowClick(true)}
                            style={{
                              display: isCardVisible ? "none" : "block",
                            }}
                            alt="Scoo Rental"
                            className="icon mx-2"
                          />
                        </div>
                        <div>
                          <img
                            src={upIcon}
                            // onClick={() => handleArrowClick(false)}
                            style={{
                              display: isCardVisible ? "block" : "none",
                            }}
                            alt="Scoo Rental"
                            className="icon_up mx-2"
                          />
                        </div>
                      </div>
                      <div>
                        {isCardVisible && (
                          <Card className="dwn_Card ">
                            <div>
                              <ul className="px-4 py-2 mb-2">
                                <li>
                                  <div className="year_wrapper">
                                    <Link
                                      to="https://scoomerchandise.myshopify.com/products/hero-nyx "
                                      className="link_month"
                                      target="_blank"
                                    >
                                      <div className="">
                                        Yearly - 5500/- per month
                                      </div>
                                    </Link>
                                    <div>
                                      <img src={arrow} alt="Scoo Rental" />
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="year_wrapper">
                                    <Link
                                      to="https://scoomerchandise.myshopify.com/products/hero-nyx-half-yearly"
                                      className="link_month"
                                      target="_blank"
                                    >
                                      <div className="">
                                        Half-Yearly - 5700/- per month
                                      </div>
                                    </Link>
                                    <div>
                                      <img src={arrow} alt="Scoo Rental" />
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="year_wrapper">
                                    <Link
                                      to="https://scoomerchandise.myshopify.com/products/hero-nyx-quaterly"
                                      className="link_month"
                                      target="_blank"
                                    >
                                      <div className="mb-1">
                                        Quarterly - 5900/- per month
                                      </div>
                                    </Link>
                                    <div>
                                      <img src={arrow} alt="Scoo Rental" />
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Card>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section> */}
            <RentalCard />
          </Col>

          {/* <Col
            lg={6}
            md={12}
            sm={12}
            className="border_title  rentaltop_scooty  py-lg-5 "
          >
            <section className="rentalCard_section">
              <div className="slider_wrapper">
                <div>
                  <img
                    src={LeftArrow}
                    alt="no logo"
                    className="icon_arrows Next_ARROW"
                    onClick={() => sliderRef2?.current?.slickPrev()}
                  />
                </div>
                <div>
                  <Slider {...settings} ref={sliderRef2} className="">
                    {Rentals2Data.map((data) => {
                      const { id, img } = data;
                      return (
                        <Col md={3} sx={12} key={id} className="center_Title">
                          <div className="mx-3"></div>
                          <div className="racer_wrraper  ">
                            <img
                              src={img}
                              alt="about us"
                              className="rentalimg_banner mt-3 "
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Slider>
                </div>

                <div>
                  <img
                    src={rightArrow}
                    alt="no logo"
                    className="icon_arrows prve_ARROW"
                    onClick={() => sliderRef2?.current?.slickNext()}
                  />
                </div>
              </div>

              <Row className="mb-lg-4 nyxLEft_padding">
                <Col xl={4} lg={3} md={4} className="p-0 m-0">
                  <div>
                    <h4 className="NYX_title d-flex mx-0 ">Scoo Ampere</h4>
                  </div>
                </Col>
                <Col
                  xl={8}
                  lg={9}
                  md={7}
                  className="py-lg-1 line_nyxnone p-0 m-0"
                >
                  <span>
                    <hr className="nyx_line" />
                  </span>
                </Col>
              </Row>

              <Row className="mb-lg-4 nyxLEft_padding">
                {RangeData2.map((item) => {
                  const { id, img, title, kmeters } = item;
                  return (
                    <>
                      <Col md={4} className="range_col m-0" key={id}>
                        <Card className="nyx_Card ">
                          <div>
                            <ul className="nyx_list mb-2">
                              <li>
                                <div className="mb-1">
                                  <img src={img} alt="no data" />
                                </div>
                              </li>
                              <li>
                                <div className="speed_Title">{title}</div>
                              </li>
                              <li>
                                <div className="km_title">{kmeters}</div>
                              </li>
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    </>
                  );
                })}
              </Row>

              <Row className=" nyxLEft_padding ">
                <Col md={12} className="padding_bottom ">
                  <div
                    className="rentnow_btn "
                    onClick={
                      isCardVisible2 === true
                        ? () => handleArrowClick2(false)
                        : () => handleArrowClick2(true)
                    }
                  >
                    <div className="rent_now ">Select Plan</div>
                    <div>
                      <img
                        src={down}
                        // onClick={() => handleArrowClick(true)}
                        style={{ display: isCardVisible ? "none" : "block" }}
                        alt="no data"
                        className="icon mx-2"
                      />
                    </div>
                    <div>
                      <img
                        src={upIcon}
                        // onClick={() => handleArrowClick(false)}
                        style={{ display: isCardVisible ? "block" : "none" }}
                        alt="no data"
                        className="icon_up mx-2"
                      />
                    </div>
                  </div>
                  <div>
                    {isCardVisible2 && (
                      <Card className="dwn_Card ">
                        <div>
                          <ul className="px-4 py-2 mb-2">
                            <li>
                              <div className="year_wrapper">
                                <Link
                                  to="https://scoomerchandise.myshopify.com/products/ampere-zeal-yearly "
                                  className="link_month"
                                  target="_blank"
                                >
                                  <div className="mb-1">Yearly-4500/month</div>
                                </Link>
                                <div>
                                  <img src={arrow} alt="" />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="year_wrapper">
                                <Link
                                  to="https://scoomerchandise.myshopify.com/products/ampere-zeal-half-yearly"
                                  className="link_month"
                                  target="_blank"
                                >
                                  <div className="">Half-Yearly-4600/month</div>
                                </Link>
                                <div>
                                  <img src={arrow} alt="" />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="year_wrapper">
                                <Link
                                  to="https://scoomerchandise.myshopify.com/products/ampere-zeal "
                                  className="link_month"
                                  target="_blank"
                                >
                                  <div className="">Quaterly-4800/month</div>
                                </Link>
                                <div>
                                  <img src={arrow} alt="" />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>
              </Row>
            </section>
          </Col> */}
        </Row>
      </Container>

      <ContactUsModal
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      />
    </>
  );
};

export default RentalBanner;
