import React from "react";
import { Container } from "react-bootstrap";
import Content from "../../Assets/image/deliverEarn/Content.png";
import ContentImage from "../../Assets/image/service/ContentImage.png";
import "../../Component/Journey/journey.css";

const SoocoEvclub = () => {
  return (
    <div>
      <Container fluid className="joury_container p-lg-5 p-2 marginTop">
        <section>
          <div className="scooevhub-container">
            <div className="journey_wrapper journey_padding ">
              <h3 className="soco_header">Scoo Ev Hub </h3>
              <p>
                You can get your electric vehicle to Scoo EV Clinic for the best
                post-purchase maintenance and help. Ride any Electric Vehicle we
                got you serviced for every mile. We have a team of dedicated
                experts well trained to deliver efficient outcomes in repair and
                maintenance thus making sure that your electric vehicle runs
                smoothly. By ensuring unrivalled services in electric vehicle
                after-sales we are targeting at filling that void experienced
                today by our customers which is all about creating seamless
                service experiences. This should lead us to establish an
                e-mobility future community which will help reduce costs and
                ensure sustainability for all in the end.
              </p>
              <img src={Content} alt="ContentImage" height={"auto%"} />
            </div>
            <div>
              <img
                width="100%"
                height={"100%"}
                src={ContentImage}
                alt="ContentImage"
                style={{
                  objectFit: "cover",
                  borderRadius: "18px",
                }}
              />
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default SoocoEvclub;
