import React from "react";
import "./searchBlog.css";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Container } from "react-bootstrap";

export default function SearchBlog() {
  return (
    <Container fluid className="">
      <section>
        <Container className="p-0">
          <div className="SearchBlogBox position-relative">
            <p className="SearchBlogContainerBG_Text text-white position-absolute">
              Blogs
            </p>

            <div className="SearchBlogInputBox">
              <InputGroup className="SearchBlogInputGroup">
                <InputGroup.Text
                  id="basic-addon1"
                  className="bg-white border-0"
                >
                  <img src="/Images/Blogs/search-icon.png" />
                </InputGroup.Text>
                <Form.Control
                  className="border-0"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
          </div>
        </Container>
      </section>
    </Container>
  );
}
