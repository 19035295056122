import React, { useState } from "react";
import adhaarCardLogo from "../../../Assets/image/Assets/adhaar_logo.png";
import phone from "../../../Assets/image/Assets/phone.png";
import bankAccountLogo from "../../../Assets/image/Assets/bankaccount_logo.png";
import licenceLogo from "../../../Assets/image/Assets/driving_licence_logo.png";
import pancardLogo from "../../../Assets/image/Assets/pancard_logo.png";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ninjaKycData, ninjaphoneData } from "../../../data/dataApi";

import "./kyc.css";
import { Link } from "react-router-dom";
const KYCWithoutNumber = () => {
  const [border, setBorder] = useState("");

  const kycBorder = () => {};

  return (
    <>
      <Container fluid className="p-lg-5 mb-3">
        <section>
          <Container>
            <Row className="mb-lg-4 header_explore">
              <Col md={4} className="p-0 m-0">
                <h4 className="header_line p-0 m-0">
                  Begin your Scoo Ninja journey now! Documents required for KYC
                </h4>
              </Col>
              <Col md={8} className="py-2 m-0 px-0">
                <span>
                  <hr />
                </span>
              </Col>
            </Row>

            <Row className="mb-5">
              {ninjaKycData.map((data) => {
                const { id, img, head } = data;
                return (
                  <Col lg={3} md={4} sm={12} key={id} className="m-0 p-0">
                    <Card className="kyc_Card center_Title py-3 me-3 ">
                      <div className="d-flex">
                        <div>
                          <img src={img} alt="logo" className="kyc_img" />
                        </div>
                        <div className="kyc_Title mx-3 py-3">{head}</div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default KYCWithoutNumber;
