import React, { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import Collapse from "react-bootstrap/Collapse";
import { Container, Row, Col } from "react-bootstrap";
import { accordianData } from "../../data/dataApi";
import plus from "../../Assets/image/Accordian/plus.png";
import minus from "../../Assets/image/Accordian/minus.png";

const Accordion = ({ data }) => {
  const [selected, setSelected] = useState(0);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <>
      <ul className="accordiancard">
        {accordianData.map((item, i) => {
          const { id, question, answer, title, kyc, bottomTitle } = item;
          return (
            <>
              <li key={id} className="mb-4" onClick={() => toggle(i)}>
                <div className="description">
                  <div className="accodian_wrapper">
                    <div className="question_head">{question}</div>

                    <div>
                      {selected === i ? (
                        <img
                          src={minus}
                          alt="Electric Vehicle Rental in Bangalore"
                          className="minus_icon"
                        />
                      ) : (
                        <img
                          src={plus}
                          alt="Electric Vehicle Rental in Bangalore"
                          className="minus_icon"
                        />
                      )}
                    </div>
                  </div>
                  <div className={selected === i ? "content show" : "content"}>
                    <p className="answer_title">{answer}</p>
                    <p className="answer_title">{title}</p>
                    <ul className="p-0 m-0">
                      {kyc?.map((data) => {
                        return (
                          <>
                            <li className="answer_title">{data.list}</li>
                          </>
                        );
                      })}
                    </ul>
                    <p className="answer_title mt-3">{bottomTitle}</p>
                  </div>
                </div>
              </li>
            </>
          );
        })}
      </ul>
    </>
  );
};

export default Accordion;
