import React from "react";
import "./member.css";
import { Container, Row, Col } from "react-bootstrap";
import bgImg from "../../Assets/image/club/memberBg.png";
import tabImg from "../../Assets/image/club/Tab.png";
import video from "../../Assets/image/club/clubmem.png";

const ClubMembership = () => {
  return (
    <>
      <Container fluid className="m-0 p-0">
        <Row className="member-bg">
          <Col lg={6} md={6} sm={12}>
            <div className="mem-sec">
              <h4 className="member-head">
                Scoo Club is an Elite EV Rental Membership.
              </h4>
              <p className="member-para">
                Scoo Club provides you a platform to enroll as a member of this
                Elite Club. Through this membership you get to enjoy the benefit
                of having luxury EV bikes delivered to your doorstep.
              </p>
              <img src={tabImg} alt="" className="mt-3" />
            </div>
          </Col>

          <Col lg={6} md={6} sm={12}>
            {/* <img src={video} alt="" className="video-img"/> */}
            <iframe
              className="video-img"
              src="https://www.youtube.com/embed/MoQlZPnaC2E"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClubMembership;
