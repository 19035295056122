import React from "react";
import ContactAddress from "../Component/ContactSection/ContactAddress/ContactAddress";
import ContactBanner from "../Component/ContactSection/ContactBanner/ContactBanner";
import ContactHiring from "../Component/ContactSection/ContactHiringSection/ContactHiring";
import ContactCard from "../Component/ContactSection/ContactImageCard/ContactCard";
import ContactMap from "../Component/ContactSection/ContactMap/ContactMap";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
        Looking for Electric Bike Rental in Bangalore | Contact - ScooEV
        </title>
        <meta
          name="description"
          content="ScooEV offers everyone hassle free electric vehicle rental in Bangalore, EV rentals in Bangalore, self-drive electric car rental in Bangalore contact now.  "
        />
        <meta
          name="keywords"
          content="Electric Bike Rental in Bangalore, Electric Bikes on Rental Near Me, Ather  Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore,Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike.  "
        />
      </Helmet>
      <ContactBanner />
      <ContactMap />
      <ContactAddress />
      {/* <ContactCard /> */}
      <ContactHiring />
    </>
  );
};

export default Contact;
