import React from "react";

import "./whyRentFromUs.css";
import { Container, Row, Col } from "react-bootstrap";
import ScooLuxeBike from "../../../Assets/image/Assets/Scooter-OlaS1Pro-2.webp";

const WhyRentFromUs = () => {
  return (
    <>
      <Container fluid className="whyRentFromUs-container ">
        <section>
          <Container>
            <Row>
              <Col lg={8} sm={12} className="py-lg-5">
                <h3 className="journey_header py-3">Why Rent Us?</h3>
                <p className="whyRentFromUs-para">
                  - Skip the ownership hassles: Zoom past traffic on cutting-edge e-bikes without the commitment.<br/>
                  - Rent to your rhythm: Quarterly, yearly, or half-yearly - choose your EV adventure.<br/>
                  - Future on two wheels: Sleek design, powerful motors, and smart features.<br/>
                  - Convenience at your fingertips: Rent near you, enjoy free service, and relax with roadside assistance.<br/>
                  - Go green, ride clean: Explore the city emission-free.<br/>
                  - Perfect for every ride: Whether daily grind or weekend fun, we've got the e-bike for you.<br/>
                  - Scoo's got your back: We're here for whatever your needs are, wherever your ride takes you.<br/>

                </p>
                {/* <p className="whyRentFromUs-para">
                  Rent to your rhythm: Quarterly, yearly, or half-yearly - choose your EV adventure.
                </p>
                <p className="whyRentFromUs-para">
                  Future on two wheels: Sleek design, powerful motors, and smart features.
                </p> */}
              </Col>

              <Col lg={4} sm={12}>
                <div>
                  <img
                    src={ScooLuxeBike}
                    alt=" Scoo Luxe Rental in Bangalore"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default WhyRentFromUs;
