import React from "react";
import { Button, Card } from "react-bootstrap";
import "./latestPostBlogCard.css";

export default function LatestPostBlogCard({ img, title, time, views }) {
  return (
    <div className="d-flex flex-row  gap-2">
      <Card className="LatestPostBlogCardImgBox">
        <Card.Img
          variant="top"
          width="100%"
          height="100%"
          src={`${img}`}
          className="borderRadius_5px"
        />
      </Card>

      <div className="d-flex flex-column justify-content-center gap-1 align-items-start p-2 LatestPostBlogCardInfoBox">
        <Button className="CRAMPC_Btn">Featured Posts</Button>
        <div className="LatestPostBlogCard_Title mt-1">
         {title?.slice(0,55)}...
        </div>

        <div className="LatestPostBlogCard_Info d-flex flex-row flex-wrap gap-2 mt-1">
          <span>{time} days ago</span>
          <span>{views} views</span>
        </div>
      </div>
    </div>
  );
}
