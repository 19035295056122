import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../Assets/image/logo/logo.webp";
import golden_logo from "../../Assets/image/logo/golden-logo.webp";
import rentIcon from "../../Assets/image/header/Icon.svg";
import Button from "react-bootstrap/Button";
import "./header.css";
import { menuData } from "../../data/dataApi";
import ContactUsModal from "../CommonComponents/ContactUsModal/ContactUsModal";

const Header = () => {
  //  how to give nested ternary operater in react js?
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [activeMenu, setActiveMenu] = useState("Who we are");
  const [hideMenu, setHideMenu] = useState(false);
  console.log("hideMenu", hideMenu);

  const location = useLocation();
  const path = location.pathname;

  let [menuType, setMenuType] = useState("");
  // const burgerHandle = () => {
  //   setHideMenu(true);
  // };

  let clickMenuHandler = (menu, id) => {
    setActiveMenu(menu);
    // setHideMenu(false);
    // if (menu) {
    //   setHideMenu(false);
    // }

    localStorage.setItem("activeMenu", menu);
    setMenuType(menu?.toLowerCase()?.replace(/\s/g, ""));
  };

  useEffect(() => {
    const storedItem = localStorage.getItem("activeMenu");
    if (storedItem) {
      setActiveMenu(storedItem);
    }

    if (path?.slice(1) === "scooluxe") {
      setMenuType(path?.slice(1)?.toLowerCase()?.replace(/\s/g, ""));
    }
  }, []);

  return (
    <>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        className="navbar_Container "
      >
        <Container className="">
          <Navbar.Brand href="/" className="mb-1">
            {menuType === "scooluxe" ? (
              <img src={golden_logo} alt="no logo" />
            ) : (
              <img src={logo} alt="no logo" />
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className={"navbar_Wrapper "}
          >
            <Nav>
              {menuData.map((item) => {
                const { id, menu, url } = item;
                return (
                  <div key={id} className="link_wrapper">
                    {/* <Nav.Link href={url} className="menu_title me-" onClick={() => clickMenuHandler(menu)}>
                      {menu}
                    </Nav.Link> */}

                    {/* <Link
                      to={url}
                      onClick={() => clickMenuHandler(menu)}
                      className={`menu_title me- ${
                        menuType === "scooluxe" && "menu_title_golden"
                      }`}
                    >
                      {menu}
                    </Link> */}

                    <Nav.Link
                      href={url}
                      onClick={() => clickMenuHandler(menu, id)}
                      className={
                        activeMenu === menu ? "active_menu" : "menu_title"
                      }
                    >
                      {menu}
                    </Nav.Link>
                  </div>
                );
              })}
            </Nav>
            <Link
              to="https://scoomerchandise.myshopify.com/collections/scoo-rental "
              className="link_text"
              target="_blank"
            >
              <Button
                className={`${
                  menuType === "scooluxe"
                    ? "rent_btn_golden"
                    : "rent_btn rent_bottom"
                }`}
              >
                <img src={rentIcon} alt="no logo" className="rent_img" />
                <spna className=""> Rent now</spna>
              </Button>
            </Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <ContactUsModal
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      />
    </>
  );
};

export default Header;
