import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

export default function ContactUsModal({
  showContactUsModal,
  setShowContactUsModal,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const handleClose = () => setShowContactUsModal(false);
  let navigate = useNavigate();

  const form = useRef();
  let sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          navigate("/Thankyou");
          setShowContactUsModal(false);
        },
        (error) => {
          alert("Try again");
        }
      );
  };

  return (
    <>
      <Modal show={showContactUsModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-2">
          <Form ref={form} onSubmit={sendEmail}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="label_title">First name</Form.Label>
                  <Form.Control
                    type="text"
                    className="input_title"
                    placeholder="First name"
                    name="user_fname"
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="label_title">Last name</Form.Label>
                  <Form.Control
                    type="text"
                    className="input_title"
                    placeholder="Last name"
                    name="user_lname"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="label_title">Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="you@company.com"
                    className="input_title"
                    name="user_email"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="label_title">Phone number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="+91 98765 43210"
                    className="input_title"
                    name="user_phone"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="label_title">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    className="input_title"
                    rows={2}
                    name="user_message"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Button className="contactSubmit_btn" type="submit">
                  Send message
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="bg-danger border-0 text-white"
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
