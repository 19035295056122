import React from "react";
import CuratedReadsBlogCard from "./Cards/CuratedReadsBlogCard";
import LatestCommentsBlogCard from "./Cards/LatestCommentsBlogCard";
import LatestPostBlogCard from "./Cards/LatestPostBlogCard";
import MostPopularBlogCard from "./Cards/MostPopularBlogCard";
import "./curatedReadsAndMostPopularContainer.css";
import { blogs } from "../../../data/blogData";
import { blogSectionData } from "./../../../data/dataApi";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";

export default function CuratedReadsAndMostPopularContainer() {
  return (
    <>
      <Container fluid className="b2b_container p-lg-5 py-2">
        <section>
          <Container>
            <Row>
              <Col lg={7} className="">
                <Row className="mb-lg-3">
                  <Col md={12} className="p-0 m-0">
                    <div>
                      <h4 className="header_line d-flex">Curated Reads</h4>
                      <span>
                        <hr />
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  {blogSectionData.map((item) => {
                    const {
                      id,
                      icon,
                      btn,
                      blogtitle,
                      day,
                      views,
                      facebook,
                      instagram,
                      Linkedin,
                      Twitter,
                    } = item;
                    return (
                      <>
                        <Col lg={6} key={id} className="mb-3">
                          <Card className="blog_card">
                            <Card.Img variant="top" src={icon} />
                            <Card.Body>
                              <Button className="CRAMPC_Btn">
                                Featured Posts
                              </Button>
                              <Card.Title className="mt-2">
                                <div className="blog_Title blog_titlteBold mb-2">
                                  {blogtitle}
                                </div>
                                <div className="day_title">
                                  <sapn>{day}</sapn>
                                  <span className="dot_title">•</span>
                                  <sapn className="mx-2">{views}</sapn>
                                </div>
                                <hr></hr>
                                <div className="social_icon">
                                  <div>
                                    <img src={facebook} alt="facebook" />
                                  </div>
                                  <div>
                                    <img src={instagram} alt="instagram" />
                                  </div>
                                  <div>
                                    <img src={Linkedin} alt="Linkedin" />
                                  </div>
                                  <div>
                                    <img src={Twitter} alt="Twitter" />
                                  </div>
                                </div>
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    );
                  })}
                </Row>
                <Row className="mb-4">
                  <Col md={12} className="px-2 m-0">
                    <div>
                      <h4 className="header_line d-flex">Latest Post</h4>
                      <span>
                        <hr />
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {blogs?.slice(0, 4)?.map((ele, index) => {
                    return (
                      <>
                        <Col lg={12} className="mb-3">
                          <div key={index}>
                            <LatestPostBlogCard
                              img={ele?.img}
                              title={ele?.title}
                              views={ele?.views}
                              time={ele?.time}
                            />
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Col>

              <Col lg={5} className="px-lg-5">
                <Row className="mb-4 mx-4">
                  <Col md={12} className="p-0 m-0">
                    <div>
                      <h4 className="header_line d-flex">Most Popular</h4>
                      <span>
                        <hr />
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="px-lg-4">
                  {blogs?.slice(0, 6)?.map((ele, index) => {
                    return (
                      <>
                        <Col lg={12} className="mb-3  ">
                          <div key={index}>
                            <MostPopularBlogCard
                              img={ele?.img}
                              title={ele?.title}
                              views={ele?.views}
                              time={ele?.time}
                            />
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </Row>

                <Row className="mb-4  comment_Row">
                  <Col md={12} className="px-lg-5">
                    <div>
                      <h4 className="header_line d-flex">Latest Comments</h4>
                      <span>
                        <hr />
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="px-lg-4 mt-4">
                  {blogs?.slice(0, 4)?.map((ele, index) => {
                    return (
                      <>
                        <Col lg={12} className="mb-3">
                          <LatestCommentsBlogCard img="Images/Blogs/profile2.png" />
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
}
