import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { b2bData } from "../../data/dataApi";
import "./b2b.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// smooth responsive carousel   for barnd images in react js?

const B2b = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container fluid className="b2b_container p-lg-5 mb-3">
        <section>
          <Container>
            <Row className="mb-lg-4">
              <Col md={3} className="p-0 m-0">
                <div>
                  <h4 className="header_line d-flex">Our B2B Customers</h4>
                </div>
              </Col>
              <Col md={9} className="py-1 px-0 m-0 ">
                <span>
                  <hr />
                </span>
              </Col>
            </Row>
            <Row className="b2b_card">
              <Slider {...settings}>
                {b2bData.map((item) => {
                  const { id, img, title } = item;
                  return (
                    <>
                      <Col key={id} className="b2b_wrapper ">
                        <img
                          src={img}
                          alt="Electric Vehicle Rental in Bangalore"
                          className="brandImg"
                        />

                        <h1>{title}</h1>
                      </Col>
                    </>
                  );
                })}
              </Slider>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default B2b;
