import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import socoBike from "../../Assets/image/deliverEarn/socoBike.png";

import Content from "../../Assets/image/deliverEarn/Content.png";
import FaqSection from "../../Component/FaqSection/FaqSection";
const Requirements = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  return (
    <Container className="mb-4 marginTop">
      <Row>
        <Col lg={6} md={6} sm={12} className="journey_wrapper">
            <img
            src={Content}
            alt="socoBike"
            className="mt-4"
            // height={"100%"}
            style={{
              objectFit: "fill",
            }}
          />
        </Col>
        <Col lg={6} md={6} sm={12} className="journey_wrapper journey_padding">
          <h3 className="journey_header">Requirements for KYC</h3>
          <p>
            Scoo Campus is driven with an idea of a green commute made available
            for students. EV rental bikes are made available to ride within and
            outside the campus for students and faculties. Scoo Campus aims to
            provide electric bikes to all students so we can together achieve a
            green commute.
          </p>
          <div className="kyc_requirements_wrapper">
            <Card>Adhar Card</Card>
            <Card>Pan Card</Card>
            <Card>Driving Licence</Card>
            <Card>Bank Account</Card>
          </div>

          <div style={{ margin: "0px" }} className="mx-0">
            <Button
              className="rent_btn  contactUs_btn mt-4"
              onClick={() => setShowContactUsModal(true)}
            >
              <spna className=""> Contact with us</spna>
            </Button>
          </div>
        
        </Col>
      </Row>
      <Row className="marginTop">
        <FaqSection />
      </Row>
    </Container>
  );
};

export default Requirements;
