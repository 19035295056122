// export const clubcardData = [
//   {
//     id: 1,

//     head: "Free pickup & drop of e-bikes from your home location",
//     para: "Forget about the inconvenience of traveling to a rental station or worrying about how to transport the e-bike to your desired starting point.",
//   },
//   {
//     id: 2,

//     head: "Free pickup & drop of e-bikes from your home location",
//     para: "Forget about the inconvenience of traveling to a rental station or worrying about how to transport the e-bike to your desired starting point.",
//   },
//   {
//     id: 3,

//     head: "Free pickup & drop of e-bikes from your home location",
//     para: "Forget about the inconvenience of traveling to a rental station or worrying about how to transport the e-bike to your desired starting point.",
//   },
//   {
//     id: 4,

//     head: "Free pickup & drop of e-bikes from your home location",
//     para: "Forget about the inconvenience of traveling to a rental station or worrying about how to transport the e-bike to your desired starting point.",
//   },
//   {
//     id: 5,

//     head: "Free pickup & drop of e-bikes from your home location",
//     para: "Forget about the inconvenience of traveling to a rental station or worrying about how to transport the e-bike to your desired starting point.",
//   },
// ];

export const clubcardData = [
  {
    id: 1,

    head: "Free pickup & drop of e-bikes from your home location",
    para: "Forget about the inconvenience of traveling to a rental station or worrying about how to transport the e-bike to your desired starting point.",
  },
  {
    id: 2,
    head: "1 Membership - 3 subscribes",
    para: "Unlock the ultimate flexibility and convenience with our unique 1 :Membership - 3 Subscriptions plan.",
  },
  {
    id: 3,
    head: "Hassel free on service/maintenance concerns",
    para: " We take care of all service and maintenance concerns, ensuring that you can enjoy your rides without any hassle or inconvenience.",
  },
  {
    id: 4,
    head: "Hassel free on service/maintenance concerns",
    para: "We take care of all service and maintenance concerns, ensuring that you can enjoy your rides without any hassle or inconvenience.",
  },
  {
    id: 5,
    head: "Zero hidden costs",
    para: "With our membership program, we ensure that you won't encounter any unexpected surprises or hidden costs.",
  },
];
