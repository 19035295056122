import React, { useState } from "react";
import "./products.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import rentIcon from "../../../Assets/image/header/Icon.svg";
import down from "../../../Assets/image/plan/down.png";
import Button from "react-bootstrap/Button";
import icon from "../../../Assets/image/plan/btn.svg";
import OlaS1ProImage from "../../../Assets/image/Assets/image 16-1.png";
import AtherImage from "../../../Assets/image/Assets/image 17.png";
import { BiChevronDown, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import upIcon from "../../../Assets/image/Rentals/upIcon.png";
import arrow from "../../../Assets/image/Rentals/Right.png";
import {
  LuxProductSliderData,
  LuxProductCardData,
} from "../../../data/dataApi";
import scooty from "../../../Assets/image/Assets/image 16.png";
import { Link } from "react-router-dom";
import LeftArrow from "../../../Assets//image/Rentals/lux.png";
import rightArrow from "../../../Assets//image/Rentals/lux2.png";

import ArrowLight from "../../../Assets//image/Rentals/luxlight.png";
import ArrowDark from "../../../Assets//image/Rentals/luxdark.png";

const Products = () => {
  const [activeBtn, setActiveBtn] = useState(false);
  const [OlaantactiveBtn, setOlaantActiveBtn] = useState(false);

  let OlasliderRef = React.useRef();
  let sliderRef = React.useRef();
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isCardVisibleNum, setIsCardVisibleNum] = useState();
  const handleArrowClick = (isVisible) => {
    setIsCardVisible(isVisible);
  };

  const OlapreveHandler = () => {
    OlasliderRef?.current?.slickPrev();
    setOlaantActiveBtn(!OlaantactiveBtn);
  };

  const OlanextHandler = () => {
    OlasliderRef?.current?.slickNext();
    setOlaantActiveBtn(!OlaantactiveBtn);
  };

  const Olasettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const preveHandler = () => {
    setActiveBtn(!activeBtn);
    sliderRef?.current?.slickPrev();
  };

  const nextHandler = () => {
    setActiveBtn(!activeBtn);
    sliderRef?.current?.slickNext();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container fluid className="product_Wrapper p-lg-5">
      <section>
        <Container>
          <Row className="mb-lg-4 header_explore">
            <Col md={6} className="p-0 m-0">
              <div>
                <h4 className="header_line d-flex plan_head">
                Why get weighed down by ownership when you can glide into freedom with Scoo EV Rentals?<br/>
                Choose from our budget-friendly subscription plans, each designed to improve your lifestyle. 
                </h4>
              </div>
            </Col>
            <Col md={6} className="py-lg-5">
              <span>
                <hr className="lux_line" />
              </span>
            </Col>
          </Row>

          <Row className="mb-4">
            {LuxProductCardData.map((data) => {
              const {
                id,
                head,
                ruppee,
                title,
                name,
                month,
                btn,
                performance,
                LuxProductSliderData,
                LuxProductOlaSliderData,
                heading,
              } = data;
              return (
                <Col lg={6} md={6} className="p-3 m-0" key={id}>
                  <Card className="product_Card ">
                    <Row className="mb-4 product_rows">
                      <Col
                        md={12}
                        className=""
                        onClick={() => {
                          if (isCardVisibleNum === id) {
                            handleArrowClick(true);
                            setIsCardVisibleNum();
                          } else {
                            handleArrowClick(false);
                            setIsCardVisibleNum(id);
                          }
                        }}
                      >
                        <div className="rentnow_btn quarterly_btn">
                          <div className="rent_nowlux ">{head}</div>
                          <div>
                            <img
                              src={down}
                              style={{
                                display:
                                  isCardVisibleNum === id ? "none" : "block",
                              }}
                              alt="Scoo Luxe Rental in Bangalore"
                              className="icon mx-2"
                            />
                          </div>
                          <div>
                            <img
                              src={down}
                              onClick={() => {
                                if (isCardVisibleNum === id) {
                                  handleArrowClick(true);
                                  setIsCardVisibleNum();
                                } else {
                                  handleArrowClick(false);
                                  setIsCardVisibleNum(id);
                                }
                              }}
                              style={{
                                display:
                                  isCardVisibleNum === id ? "block" : "none",
                                transform: "rotate(-181deg)",
                              }}
                              alt="Scoo Luxe Rental in Bangalore"
                              className="icon_up mx-2"
                            />
                          </div>
                        </div>

                        <div>
                          {isCardVisibleNum == id && (
                            <Card className="dwn_Card ">
                              <div>
                                <ul className="px-4 py-2 mb-2">
                                  {performance?.map((ele) => {
                                    return (
                                      <li key={ele?.id}>
                                        <div className="year_wrapper">
                                          <div className="mb-1">
                                            {ele?.text}
                                          </div>
                                          <div>
                                            <img src={arrow} alt="" />
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </Card>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {heading === "Ols" ? (
                      <Row className="product_rows">
                        <Col lg={12} className="qutityScooty_img ">
                          <div className="selectPlan_Card d-flex ">
                            <div>
                              <img
                                src={
                                  OlaantactiveBtn === true
                                    ? ArrowDark
                                    : rightArrow
                                }
                                alt="about us"
                                className="arrow_title arrow_icon arrow_deactive"
                                onClick={OlanextHandler}
                              />
                            </div>

                            <div className="slider_container">
                              <Slider {...Olasettings} ref={OlasliderRef}>
                                {LuxProductOlaSliderData?.map((data) => {
                                  const { id, img } = data;
                                  return (
                                    <Col lg={4} key={id}>
                                      <img
                                        src={img}
                                        alt="Scoo Luxe Rental in Bangalore"
                                        className="product_slide "
                                      />
                                    </Col>
                                  );
                                })}
                              </Slider>
                            </div>
                            <div>
                              <img
                                src={
                                  OlaantactiveBtn === true
                                    ? ArrowLight
                                    : LeftArrow
                                }
                                alt="about us"
                                className="arrow_icon red_title arrow_active"
                                onClick={OlapreveHandler}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="product_rows">
                        <Col lg={12} className="qutityScooty_img ">
                          <div className="selectPlan_Card d-flex ">
                            <div>
                              <img
                                src={
                                  activeBtn === true ? ArrowDark : rightArrow
                                }
                                alt="about us"
                                className="arrow_title arrow_icon arrow_deactive"
                                onClick={nextHandler}
                              />
                            </div>

                            <div className="slider_container">
                              <Slider {...settings} ref={sliderRef}>
                                {LuxProductSliderData?.map((data) => {
                                  const { id, img } = data;
                                  return (
                                    <Col lg={4} key={id}>
                                      <img
                                        src={img}
                                        alt="Scoo Luxe Rental in Bangalore"
                                        className="product_slide "
                                      />
                                    </Col>
                                  );
                                })}
                              </Slider>
                            </div>
                            <div>
                              <img
                                src={
                                  activeBtn === true ? ArrowLight : LeftArrow
                                }
                                alt="about us"
                                className="arrow_icon red_title arrow_active"
                                onClick={preveHandler}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <div className="py-4">
                      <h5 className="ouarterly_Title pro_Title">{title}<br/>{name}</h5>
                      {/* <h5 className="ouarterly_Title pro_Title"></h5> */}
                      <h1 className="amount_Title mx-5">₹{ruppee}/-</h1>
                      <p className="month_title">{month}</p>
                    </div>
                    <div className="rent_btns ">
                      <Link
                        to="https://scoomerchandise.myshopify.com/products/bounce-infinity-e1-one-month-subscription"
                        className="link_text productRent_lnik"
                        target="_blank"
                      >
                        <Button className="productRent_btn ">
                          <img
                            src={rentIcon}
                            alt=" Scoo Luxe Rental in Bangalore"
                            className="rent_img "
                          />
                          <spna className=""> {btn}</spna>
                        </Button>
                      </Link>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </Container>
  );
};

export default Products;
