import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./contactBanner.css";

const ContactBanner = () => {
  return (
    <>
      <Container fluid className="contactBanner_bg p-lg-5 mt-5">
        <section>
          <Container className="mt-4 py-4">
            <Row className="mb-4 p-lg-5">
              <Col md={12} className="m-auto">
                <div className="">
                  <h5 className="support_Title contact_Title contactHead_title">Contact us</h5>
                  <h3 className="contactBanner_head mb-3">
                    Revolutionizing urban delivery with eco-
                    <br />
                    friendly electric scooter rentals for partners
                  </h3>
                  <p className="para contact_para">
                    Join our mission to transform urban delivery with
                    sustainable electric scooters. Our rental program for
                    delivery partners
                    <br /> provides a cost-effective and eco-friendly solution
                    for last-mile logistics. Experience the convenience and
                    speed of our <br />
                    scooters while reducing your carbon footprint and
                    contributing to a cleaner, healthier city.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default ContactBanner;
