import React, { useEffect, useState } from "react";
import "./herobanner.css";
import { Container, Row, Col } from "react-bootstrap";
import ScooLuxeBike from "../../../Assets/image/Assets/image 14-2.png";
import { Button } from "react-bootstrap";
import rentIcon from "../../../Assets/image/logo/Icon.png";
import { LuxSooterSliderData } from "../../../data/dataApi";
import { Link } from "react-router-dom";
const HeroBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % LuxSooterSliderData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [activeIndex, LuxSooterSliderData.length]);

  return (
    <Container fluid className="hero-banner-container ">
      <section className="heroBanner_wrapper">
        <Container className="mt-5 herobanner_center ">
          <Row className="d-flex ">
            <Col lg={6} className="banner_wrapper ">
              <div className="">
                <h5 className="support_Title luxe_header">
                  👍 Electric, Elegant, Effortless!
                </h5>
                <h3 className="mb-3">
                  <span className="banner_header luxe_title mb-2">
                  Unlock Bengaluru's secret streets
                  </span>
                  <br></br>
                  <span className="craft_banner luxe_premium">
                   with your private E-Bike
                  </span>
                </h3>
                <p className="banner_para luxe_para ">
                From breezy Ola S1s to sleek Bounce Infinity and powerful Bounce infinity E1, cruise through the city in comfort and class.<br/>
                Reserve your luxury e-scooter adventure today! 
                </p>
                <div className="d-flex  luxebtn_Wrapper">
                  <Button className="luxe_btn  d-flex">
                    <div>
                      <img
                        src={rentIcon}
                        alt="Scoo Luxe Rental in Bangalore"
                        className="rentluxe_img"
                      />
                    </div>

                    <div>
                      <Link
                        to="https://scoomerchandise.myshopify.com/products/bounce-infinity-e1-one-month-subscription"
                        className="link_lux"
                        target="_blank"
                      >
                        <spna className="">Rent now</spna>
                      </Link>
                    </div>
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={6} className="hero_img scooter-container">
              {LuxSooterSliderData.map((data) => {
                const { id, img } = data;
                return (
                  <div key={id}>
                    <img
                      src={img}
                      alt="ola scooter Rental in Bangalore"
                      className="scooter"
                    />
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  );
};

export default HeroBanner;
