import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Reviews from "../Reviews/Reviews";

const Hearfromriders = () => {
  return (
    <div className="marginTop">
      <Container>
        <Row className="mb-lg-4 header_explore  py-md-3 py-lg-2 mt-4">
          <Col lg={3} className="p-0 m-0">
            <h4 className="header_line ">Why become a Scoo Club Memeber</h4>
          </Col>
          <Col lg={9} className="py-1 p-0 m-0">
            <span>
              <hr />
            </span>
          </Col>
          <Reviews />
        </Row>
      </Container>
    </div>
  );
};

export default Hearfromriders;
