import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "../Journey/journey.css";
import Section from "./../../Assets/image/service/Section.png";
import Location from "../../Assets/image/service/Location.png";
import "./service.css";
const ScheduleaService = () => {
  return (
    <>
      <Container fluid className="joury_container p-lg-5 p-2 marginTop">
        <Container>
          <div className="scheduleaService-wrapper">
            <img
              width="100%"
              height="100%"
              src={Section}
              alt="socoGreenBike"
              style={{
                objectFit: "cover",
              }}
            />
            <div className="journey_wrapper journey_padding">
              <h3 className="soco_header">Schedule a Service </h3>
              <p className="soco_para">
                Secure the Exclusive Offer Today and Enjoy a 10% discount on
                your first service appointment with us!
              </p>
              <Form className="mt-4 pt-1">
                <Row className="mt-4">
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="label_title">
                        First name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="input_title"
                        placeholder="First name"
                        name="user_fname"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                      <Form.Label className="label_title">Phone No</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Phone Number"
                        name="phone_number"
                        required
                        className="input_title"

                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="label_title">Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="you@company.com"
                        className="input_title"
                        name="user_email"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="label_title">
                        Service type
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Service"
                        className="input_title"
                        name="Service"
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="label_title">Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        className="input_title"
                        rows={6}
                        name="user_message"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={12}>
                    <Button className="rent_btn" type="submit" size="lg">
                      Request Price Estimate{" "}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Container>
        <div className="location-cards-warpper">
          <div>
            <Card className="location-card">
              <img src={Location} alt="location" className="location" />
              <h3>Head office</h3>
              <span>Mon-Fri 9am to 6pm.</span>
              <p>
                2nd Floor, Supreme Overseas Exports Building Regus Krishna
                Rajendra Road 7th Block, Jayanagar Bengaluru, Karnataka 560070
                India.
              </p>
            </Card>
          </div>
          <div>
            <Card className="location-card">
              <img src={Location} alt="location" className="location" />
              <h3>Our Fleet</h3>
              <span>Mon-Sat 24/7</span>
              <p>
                Basement, Supreme Overseas Exports Building Regus Krishna
                Rajendra Road 7th Block, Jayanagar Bengaluru, Karnataka 560070
                India.
              </p>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ScheduleaService;
