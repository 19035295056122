import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import deadlinebg from "../../../Assets/image/Assets/greenbus.webp";
import "./subbanner.css";
import { ninjarandomImg } from "../../../data/dataApi";

const SubBanner = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  console.log("currentImageIndex", currentImageIndex);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === ninjarandomImg.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [ninjarandomImg]);
  return (
    <Container fluid className="">
      <Row className="">
        <Col lg={7} sm={12} className="center_Title SubBannerbg">
          <div className="letscooCard_left  ">
            <h3 className="subbanner-title">
              {" "}
              Join the Scoo EV Revolution now!
            </h3>

            <h4 className="subbanner-sub-title">
              No Deadlines, No work pressure, Get paid every month fixed salary
              along with attractive incentives !!! <br />
              our comfy and trendy e-bikes!
            </h4>
            <p className="subbanner-para">
              The popularity and prevalence of electric three-wheelers for
              last-mile logistics is on a constant rise. Assuring Zero fuelcosts
              & hassle free maintenance, Scoo EV provides best in class, quick
              charging all- electric 3wheelers with an average range of 110KM.
              You can earn effortlessly and save big on fuel and maintenance!
              Become a Scoo Ninja now and electrify your dreams!
            </p>
          </div>
        </Col>

        <Col lg={5} sm={12} className="p-0 m-0">
          <img src={deadlinebg} alt="no data" />
        </Col>
      </Row>
    </Container>
  );
};

export default SubBanner;
