import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "./AccordionSection";
import certificate from "../../Assets/image/certificate.png"

import "./Faq.css";
const FaqSection = () => {
  return (
    <>
      <Container fluid className="p-lg-5">
        <section>
          <Container>
            <Row className="mb-4">
              <Col md={5} className="faq_wrapper ">
                <div className="">
                  <h5 className="support_Title ">Support</h5>
                  <h3 className="faq_Title mb-3">FAQs</h3>
                  <p className="para">
                    Everything you need to know about the scooters and
                    <br /> billing. Can’t find the answer you’re looking for?
                    Please chat to our friendly team.
                  </p>
                </div>
              </Col>
              <Col md={7}>
                <Accordion />
              </Col>
            </Row>

            {/* <Row>
              <Col lg={12} className="mb-3">
                  <img src={certificate} alt=""/>
              </Col>
            </Row> */}
          </Container>
        </section>
      </Container>
    </>
  );
};

export default FaqSection;
