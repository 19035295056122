import React, { useEffect, useState, useRef } from "react";
import "./ContactMap.css";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const ContactMap = () => {
  let navigate = useNavigate();
  const form = useRef();
  let sendEmail = (e) => {
    console.log("e", e);
    e.preventDefault();
    emailjs
      .sendForm(
        "service_u8vfkes",
        "template_9lfhrld",
        form.current,
        "djp9BvYeL_Pldnk2G"
      )
      .then(
        (result) => {
          navigate("/Thankyou");
        },
        (error) => {
          alert("Try again");
        }
      );
  };

  return (
    <>
      <Container fluid>
        <Row className="mb-4 ">
          <Col md={7} className="">
            <Row>
              <Col md={8} className=" m-auto py-5">
                <h1 className="contacMap_head">Contact us</h1>
                <p className="para contactMap_para">
                  Our friendly team would love to hear from you.
                </p>
                <Form ref={form} onSubmit={sendEmail}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="label_title">
                          First name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="input_title"
                          placeholder=""
                          name="user_fname"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="label_title">
                          Last name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="input_title"
                          placeholder="Last name"
                          name="user_lname"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="label_title">Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder=""
                          className="input_title"
                          name="user_email"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="label_title">
                          Phone number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          className="input_title"
                          name="user_phone"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="label_title">Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          className="input_title"
                          rows={3}
                          name="user_message"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <div className="d-flex privecy_title">
                      <div>
                        <Form.Check type="checkbox" label="" />
                      </div>
                      <div>
                        You agree to our friendly{" "}
                        <Link className="privacy_link">privacy policy.</Link>
                      </div>
                    </div>
                  </Form.Group>
                  <Row>
                    <Col md={12}>
                      <Button className="contactSubmit_btn" type="submit">
                        Send message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="p-0">
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15556.596440777415!2d77.5573878395508!3d12.898131900000019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1547c4fc23af%3A0xfc5fcb881bf5c2d7!2sScoo%20EV%20Service%20Centre!5e0!3m2!1sen!2sin!4v1680784613667!5m2!1sen!2sin"
                width="100%"
                height="850"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactMap;
