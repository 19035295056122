import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import blub from "../../Assets/image/Mession/blub.webp";
import "./mession.css";
import { messionData } from "../../data/dataApi";
import Aos from "aos";
import "aos/dist/aos.css";
// i have useing aos animation from left but in section i have us map but i need one section from the right and other from the left how to do in react js functioanl component?

const Mession = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container fluid className="mession_container p-lg-5 ">
        <section>
          <Container>
            <Row>
              {messionData.map((item) => {
                const { id, header, para, animation } = item;
                return (
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className="px-3 messionHeader_Wrapper"
                    key={id}
                    data-aos={animation}
                  >
                    <Row className="mb-lg-4 ">
                      <Col lg={12} md={6} sm={12} className="p-lg-0 m-lg-0 ">
                        <div className="">
                          <h4 className="vision_line d-flex">
                            <img
                              src={blub}
                              alt="Electric Vehicle Rental in Bangalore"
                              className="mx-2"
                            />
                            {header}
                          </h4>
                        </div>
                      </Col>
                      {/* <Col md={9} className="py-1">
                    <span>
                      <hr />
                    </span>
                  </Col> */}
                    </Row>
                    <p className="">{para}</p>
                  </Col>
                );
              })}
              {/* <Col md={6} sm={6} className="px-3">
                <Row className="mb-lg-4">
                  <Col lg={12} className="p-0 m-0">
                    <div>
                      <h4 className="vision_line d-flex mession_title">
                        <img src={blub} alt="no logo" className="mx-2" />
                        Our Mission
                      </h4>
                    </div>
                  </Col>
                  <Col md={8} className="py-1">
                    <span>
                      <hr />
                    </span>
                  </Col>
                </Row>
                <p className="mession_title">
                  To capture the booming EV market with custom made rider rental
                  options with a blend of technical expertise and commercial
                  competent solution thereby developing a trusted customer
                  network.
                </p>
              </Col> */}
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default Mession;
