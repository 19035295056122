import React from "react";
import { Card } from "react-bootstrap";
import "./latestCommentsBlogCard.css";

export default function LatestCommentsBlogCard({ img }) {
  return (
    <div className="LatestCommentsBlogCardContainer d-flex flex-row  p-4 gap-2">
      <Card className="LatestCommentsBlogCardImgBox">
        <Card.Img variant="top" width="100%" height="100%" src={`${img}`} />
      </Card>
      <div className="d-flex flex-column LatestCommentsBlogCardInfoBox">
        <div className="LatestCommentsBlogCardInfo d-flex flex-row flex-wrap align-items-center gap-2 mt-1">
          <p className="p-0 m-0">Rama Singh</p>
          <span className="">•</span>
          <span className="p-0 m-0">16 Jun 2022</span>
        </div>
        <div className="LatestCommentsBlogCardDes mt-1">
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form
        </div>
      </div>
    </div>
  );
}
