import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rentIcon from "../../Assets/image/header/Icon.svg";
import Button from "react-bootstrap/Button";
import scoo from "../../Assets/image/Footer/scoo.webp";
import rocket from "../../Assets/image/Footer/roket.png";
import facebook from "../../Assets/image/Footer/facebook.png";
import insta from "../../Assets/image/Footer/Instagram.svg";
import youtube from "../../Assets/image/Footer/Youtube.svg";
import hand from "../../Assets/image/Footer/hand.png";
import git from "../../Assets/image/Footer/git.png";
// import hand from "../../Assets/image/Footer/hand.png";
import twitter from "../../Assets/image/Footer/twitter.png";
import linkdin from "../../Assets/image/Footer/in.png";
import globe from "../../Assets/image/Footer/globe.png";
import { menuData, privacyData } from "../../data/dataApi";
import ContactUsModal from "../CommonComponents/ContactUsModal/ContactUsModal";
import "./Footer.css";
import { Link } from "react-router-dom";

export const Footer = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Container fluid className="footer_bg ">
        <section>
          <Container className="">
            <Row className=" mb-lg-3 py-lg-5 py-3">
              <Col md={12} className="footer_wrapper">
                <div className="mb-4">
                  <h4 className="footer_heder mb-4">
                    Efficient delivery with electric scooters
                  </h4>
                  {/* <h5 className="footer_para">
                    Lorem ipsum dolor sit amet consectetur. Elementum iaculis
                    bibendum mattis ac.
                  </h5> */}
                  <div>
                    <Link
                      to="https://scoomerchandise.myshopify.com"
                      target="_blank"
                    >
                      <Button className="enquire_btn ">
                        <spna className="enquire_text">
                          <img
                            src={rocket}
                            alt="no logo"
                            className="mx-1 roket_img"
                          />
                          Rent Now
                        </spna>
                      </Button>
                    </Link>
                    <Button
                      className="rent_btn mx-3 contactUs_btn"
                      onClick={() => setShowContactUsModal(true)}
                    >
                      <spna className=""> Contact us</spna>
                    </Button>
                  </div>
                </div>
                <hr />
              </Col>
              <hr className="bottom_line"></hr>
            </Row>

            <Row className="">
              <Col md={9} className="">
                <div>
                  <p className="footer_para">
                    Revolutionizing urban transportation with eco-friendly
                    electric scooters
                  </p>
                  <hr className="bottom_line"></hr>

                  <div>
                    <ul className="d-flex footer_link">
                      {menuData.map((item) => {
                        const { id, menu, url } = item;
                        return (
                          <li key={id}>
                            <Link
                              to={url}
                              className="footer_list"
                              onClick={handleClick}
                            >
                              {menu}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div>
                    <ul className="d-flex footer_link">
                      {privacyData.map((item) => {
                        const { id, title, url } = item;
                        return (
                          <li key={id}>
                            <Link
                              to={url}
                              className="footer_list"
                              onClick={handleClick}
                            >
                              {title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={3} className="footer_revo">
                <img src={scoo} alt="" className="scoo_img" />
              </Col>
            </Row>
            <Row className="mt-4">
              <hr className="bottom_line"></hr>
              <Col md={6} className="">
                <div>
                  <div className="copyright">
                    © 2023 ScooEV. All rights reserved.
                  </div>
                </div>
              </Col>
              <Col md={6} className="footer_revo">
                <div>
                  <ul className="d-flex brand_links">
                    <li>
                      <Link to="https://instagram.com/scoo_ev?igshid=YmMyMTA2M2Y=">
                        <img src={insta} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.facebook.com/scooev"
                        target="_blank"
                      >
                        <img src={facebook} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/scoo-ev-rentals/"
                        target="_blank"
                      >
                        <img src={linkdin} alt="" />
                      </Link>
                    </li>

                    <li>
                      <Link to="https://youtube.com/@scooevrentals">
                        <img src={youtube} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://twitter.com/ScooEv" target="_blank">
                        <img src={twitter} alt="" />
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="">
                        <img src={globe} alt="" />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>

      <ContactUsModal
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      />
    </>
  );
};
