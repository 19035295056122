import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./para.css";

const ScooPara = () => {
  return (
    <>
      <Container fluid className="mb-3">
        <Row className="py-lg-2 px-5 py-md-3 ">
          <Col lg={12} md={12} sm={12} className="text-center scooPara_Wrapper">
            <p className="px-3">
              Scoo Preneur is an extended platform to explore more opportunities
              for customers to be a part of Scoo. Here, the focus is on
              expanding horizons through partnership/collaborative programs for
              those who aspire to join hands through Scoo’s journey. Scoo
              Preneur offers tailor made solution of EV rental subscriptions for
              universities, co-working space & communities where we consider
              that every customer is unique and so are their needs.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ScooPara;
