import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Rent.css";
import rentIcon from "../../Assets/image/header/Icon.svg";
import Button from "react-bootstrap/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RentSliderData } from "../../data/dataApi";
import ContactUsModal from "../CommonComponents/ContactUsModal/ContactUsModal";
import { Link } from "react-router-dom";

const RentBanner = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const RentsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container fluid className="RentContainer p-0 m-0">
        <Row className="p-0 m-0">
          <Col lg={12} md={12} className="p-0 m-0">
            <Slider {...RentsSlider}>
              {RentSliderData.map((data) => {
                const { id, img, para, btn } = data;
                return (
                  <Col lg={12} md={12} sm={12} key={id} className="rentwrapper">
                    <img src={img} alt="no logo" className="rentBanner" />
                    <div className="ranterBanner_wrap">
                      <h3 className="text-center rentBanner_head">{para}</h3>
                      <div className="rentIcon_wrap">
                        <Link
                          to="https://scoomerchandise.myshopify.com"
                          className="link_text"
                          target="_blank"
                        >
                          <Button className="rent_btn mx-3 d-flex">
                            <div>
                              <img
                                src={rentIcon}
                                alt="Electric Vehicle Rental in Bangalore"
                                className="rent_img mt-1 mx-1"
                              />
                            </div>

                            <div>
                              <spna className="">{btn}</spna>
                            </div>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
      <ContactUsModal
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      />
    </>
  );
};

export default RentBanner;
