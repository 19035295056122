export const blogs = [
  {
    blogOfTheDay: false,
    img: "Images/Blogs/blog1.png",
    title:
      "One Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse. Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse.",
    postDate: "05-04-2023",
    views: "35K",
    time: "20",
    autherName: "Rama Singh",
    autherImg: "Images/Blogs/profile.png",
  },
  {
    blogOfTheDay: true,
    img: "Images/Blogs/blogOfTheDay.png",
    title:
      "Lorem ipsum dolor sit amet consectetur. Et faucibus scelerisque nascetur consectetur duis dui nunc tristique. Nunc leo in egestas.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse. Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse.",
    postDate: "05-04-2023",
    views: "45K",
    time: "20",
    autherName: "Rama Singh",
    autherImg: "Images/Blogs/profile.png",
  },

  {
    blogOfTheDay: false,
    img: "Images/Blogs/blog2.png",
    title:
      "Three Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse. Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse.",
    postDate: "05-04-2023",
    views: "35K",
    time: "20",
    autherName: "Rama Singh",
    autherImg: "Images/Blogs/profile.png",
  },
  {
    blogOfTheDay: false,
    img: "Images/Blogs/blog2.png",
    title:
      "Four Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse. Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse.",
    postDate: "05-04-2023",
    views: "35K",
    time: "20",
    autherName: "Rama Singh",
    autherImg: "Images/Blogs/profile.png",
  },
  {
    blogOfTheDay: false,
    img: "Images/Blogs/blog2.png",
    title:
      "Five Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse. Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse.",
    postDate: "05-04-2023",
    views: "35K",
    time: "20",
    autherName: "Rama Singh",
    autherImg: "Images/Blogs/profile.png",
  },
  {
    blogOfTheDay: false,
    img: "Images/Blogs/blog1.png",
    title:
      "One Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse. Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse.",
    postDate: "05-04-2023",
    views: "35K",
    time: "20",
    autherName: "Rama Singh",
    autherImg: "Images/Blogs/profile.png",
  },
  {
    blogOfTheDay: true,
    img: "Images/Blogs/blogOfTheDay.png",
    title:
      "Two Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles. Lizards are a widespread group of squamate reptiles.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse. Lorem ipsum dolor sit amet consectetur. Tempor amet dictum lacinia pharetra. Dolor suspendisse sed nam sagittis convallis eget nunc senectus. Lacus convallis duis sed nulla lacus. Eget viverra molestie tempor dignissim vel lorem scelerisque viverra ultricies. Facilisis at pellentesque diam neque ultrices egestas in ullamcorper habitasse.",
    postDate: "05-04-2023",
    views: "45K",
    time: "20",
    autherName: "Rama Singh",
    autherImg: "Images/Blogs/profile.png",
  },
];
