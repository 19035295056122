import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import rentIcon from "../../../Assets/image/header/Icon.svg";
import "./greenBanner.css";
import ContactUsModal from "../../CommonComponents/ContactUsModal/ContactUsModal";
import { Link } from "react-router-dom";

const RentGreenBanner = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  return (
    <>
      <Container fluid className="p-lg-5  p-4 bg-white">
        <section>
          <Container className="greenBanner_bg">
            <Row className="">
              <Col md={12} className="m-auto rent_wrapper ">
                <div className="">
                  <h3 className="greenBanner_head text-center">
                    With Scoo EV you can easily save upto Rs 5,500/- per month
                    <br />
                    <span className="switch_title">Switch to Scoo EV</span>
                  </h3>
                </div>
                <div>
                  <Link
                    to="https://scoomerchandise.myshopify.com"
                    className="link_text"
                    target="_blank"
                  >
                    <Button className="enquire_btn rentGreenBtn">
                      <spna className="enquire_text">Rent Now</spna>
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>

      <ContactUsModal
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      />
    </>
  );
};

export default RentGreenBanner;
