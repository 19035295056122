import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import group from "../../Assets/image/Mession/group.webp";
import { VehiclesSliderData } from "../../data/dataApi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Vehicles.css";

const Vehicles = () => {
  const VehiclesSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container fluid className="vehichles_container p-lg-5 mb-2">
        <section>
          <Container>
            <Row>
              <Col lg={6} md={12} sm={12} className="px-3 py-sm-5 py-lg-0">
                <h3 className="header_line">
                  Petrol-powered vehicles are still the dominant force on the
                  roads, but they will continue to burn a hole in your pockets
                  for fuel-consumption, operation and service costs on the long
                  run
                </h3>
                <p>
                  With Scoo EV, you can rent the best on-road alternative for
                  the petrol-vehicle, the all-electric and ecological e-Scooters
                </p>

                <p>
                  Our e-scooters are cost-effective and ergonomically designed
                  for the Bangalore roads and the unpredictable traffic. With a
                  maximum range of 110 KMs and a loading capacity of 150KGs, our
                  e-scooters cost under 10 rupees to fully charge. Whether you
                  need an E-scooter for last-mile deliveries, daily commuting,
                  or a leisurely weekend ride, we have an economically priced,
                  eco-friendly E-bikes for every commuting need
                </p>
              </Col>
              <Col lg={6} md={12} sm={12}>
                <Slider {...VehiclesSlider}>
                  {VehiclesSliderData.map((data) => {
                    const { id, img } = data;
                    return (
                      <Col
                        md={6}
                        sm={6}
                        className="journey_wrapper px-3"
                        key={id}
                      >
                        <img
                          src={img}
                          alt="Electric Vehicle Rental in Bangalore"
                          className=""
                        />
                      </Col>
                    );
                  })}
                </Slider>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default Vehicles;
