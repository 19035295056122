import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import scooty from "../../../Assets/image/Assets/luxScooty.webp";
import rentIcon from "../../../Assets/image/logo/Icon.png";
import animationData from "../../../data/datalotti.json";
import Lottie from "react-lottie";

const ScoLuxBanner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Container fluid className="homebanner_container  py-4  mb-0 ">
        <Container className="mt-lg-5 containerGeenBanner">
          <Row className="d-flex">
            <Col lg={6} md={6} sm={12} className="banner_wrapper ">
              <div className="">
                <h5 className="support_Title luxe_header   mb-3">
                  👍 Luxury E-scooter rentals platform in Bengaluru
                </h5>
                <h3 className="">
                  <span className="unlock_title">
                    {" "}
                    Unlock the future <br></br>of commuting with
                  </span>{" "}
                  <br />
                  <span className="bold_premium">Rent your premium E-bike</span>
                  {/* <br></br>
                            <span className="craft_banner">{details}</span> */}
                </h3>
                <p className="banner_para paraHome">
                  From a leisurely Sunday cruise to visiting far-flung
                  destinations
                  <br /> We have the right kind of e-scooter for you!
                </p>
                <div className="d-flex p-lg-0 m-lg-0">
                  <Link to="/scooluxe" className="link_text" target="_blank">
                    <Button className="luxe_btn  d-flex">
                      <div>
                        <img
                          src={rentIcon}
                          alt="Electric Vehicle Rental in Bangalore"
                          className="rent_img mt-1 me-2"
                        />
                      </div>

                      <div>
                        <spna className="">Rent now</spna>
                      </div>
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="p-lg-5 scoluxImg">
              <img
                src={scooty}
                alt="Electric Vehicle Rental in Bangalore"
                className=""
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default ScoLuxBanner;
