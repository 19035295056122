import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Accessibility.css";
import {
  accessibilityListData,
  acessibilitySlider,
} from "../../../data/dataApi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../RentalBanner/Rental.css";

const Accessibility = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % accessibilityListData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [activeIndex, accessibilityListData.length]);

  return (
    <>
      <Container fluid className="p-lg-5 mb-2 py-4">
        <section>
          <Container>
            <Row>
              <Col lg={6} md={12} sm={12} className="px-3 ">
                <h3 className="acces_title">Three A’s of Scoo Rental</h3>
                <h3 className="access_header">
                  Accessibility, Adaptability & Affordability
                </h3>
                <p className="access_para">
                  Scoo EV is the first e-bike rental firm to accelerate the EV
                  two wheeler rental domain into a complete rider-centric module
                </p>
                <ul className="p-0 wrapper_Access">
                  {accessibilityListData.map((data) => {
                    const { id, head, para, count } = data;
                    return (
                      <>
                        <li
                          key={id}
                          className={id === activeIndex ? "active" : ""}
                        >
                          <div className="d-flex ">
                            <div
                              className={
                                id === activeIndex
                                  ? "number_list"
                                  : "number_list2"
                              }
                            >
                              <span>{count}</span>
                            </div>
                            <div className="mx-2">
                              <h2
                                className={
                                  id === activeIndex
                                    ? "list_head"
                                    : "list_head2"
                                }
                              >
                                {head}
                              </h2>
                              <p
                                className={
                                  id === activeIndex
                                    ? "access_para"
                                    : "access_para2"
                                }
                              >
                                {para}
                              </p>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </Col>
              <Col lg={6} md={12} sx={12} className="p-2">
                {accessibilityListData.map((data) => {
                  const { id, img } = data;
                  return (
                    <div className="racer_wrraper fadeinout" key={id}>
                      {id === activeIndex ? (
                        <img src={img} alt="Scoo Rental" />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default Accessibility;
