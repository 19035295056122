import React from "react";
import frameLogo from "../../../Assets/image/Assets/Frame.png";
import "./WhybecomeScooClubMemeber.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import Membershipc from "../../../Assets/image/deliverEarn/Membership-Packages.png";
import Nohidden from "../../../Assets/image/deliverEarn/No-hidden-cost.png";
import ScooMembership from "../../../Assets/image/deliverEarn/Scoo-Membership-Card.png";
import payout from "../../../Assets/image/deliverEarn/100-Payout.png";
import parttimefulltime from "../../../Assets/image/deliverEarn/Part-time-full.png";
import clock from "../../../Assets/image/deliverEarn/Round-the-clock-Working.png";

// import icon from "../../../Assets/image/plan/btn.png";

const contentData = [
  {
    title: "100% Payout",
    para: "Provide information on how easy it is to apply and join the delivery team, including any online application forms or contact details.",
    icon: payout,
  },
  {
    title: "No Hidden Cost",
    para: "Highlight the flexibility of the job, allowing individuals to work around their existing commitments and choose their own hours.",
    icon: Nohidden,
  },
  {
    title: "Scoo Membership Card",
    para: "Detail the earning potential, including competitive rates and opportunities for bonuses or incentives based on performance.",
    icon: ScooMembership,
  },
  {
    title: "Membership Packages",
    para: "Emphasize that no prior experience is required, making this opportunity accessible to a wide range of individuals.",
    icon: Membershipc,
  },
  {
    title: "Part time || Full time || Night Shift",
    para: "Discuss the satisfaction of delivering goods to customers and contributing to their convenience and satisfaction.",
    icon: parttimefulltime,
  },
  {
    title: "Round the clock Working",
    para: "Assure potential applicants of any safety measures in place to protect both delivery partners and customers.",
    icon: clock,
  },
];

const WhyBecomeScooNinja = () => {
  return (
    <Container fluid className="p-lg-5">
      <section>
        <Container>
          <Row className="mb-lg-4 header_explore  py-md-3 py-lg-2">
            <Col lg={3} className="p-0 m-0">
              <h4 className="header_line ">Why become a Scoo Club Memeber</h4>
            </Col>
            <Col lg={9} className="py-1 p-0 m-0">
              <span>
                <hr />
              </span>
            </Col>
          </Row>
          <Row className="">
            <Col lg={12} md={12} className="p-0 m-0 ">
              <Row>
                {contentData?.map((item, index) => (
                  <Col lg={4} className="mb-4  " key={index}>
                    <Card className="WHYSection_CardClub">
                      <div className="px-2 ">
                        <ul className="nyx_list mb-2">
                          <li className="mb-3 mt-2">
                            <div className="mb-1">
                              <img
                                src={item?.icon}
                                alt="no data"
                                className="icon_why"
                              />
                            </div>
                          </li>
                          <li>
                            <h3 className="card-content-title  bold_text">
                              {item?.title}
                            </h3>
                          </li>
                          <li>
                            <p className="card-content-para">{item?.para}</p>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  );
};

export default WhyBecomeScooNinja;
