import React from "react";
import Blog from "../Component/Blog/Blog";
import NesLetter from "../Component/NewsLetterSection/NesLetter";
import FAQs from "../Component/ScooLuxe/FAQs/FAQs";
import HeroBanner from "../Component/ScooLuxe/HeroBanner/HeroBanner";
import Products from "../Component/ScooLuxe/Products/Products";
import RentNow from "../Component/ScooLuxe/RentNow/RentNow";
import Testimonial from "../Component/ScooLuxe/Testimonial/Testimonial";
import WhyRentFromUs from "../Component/ScooLuxe/WhyRentFromUS/WhyRentFromUs";
import PreneurSection from "../Component/ScooLuxe/luxePreneur/Preneur";
import { LuxaccordianData, LuxaccordianPreneurData } from "../data/dataApi";
import { Helmet } from "react-helmet";

const ScooLuxe = () => {
  return (
    <div>
      <Helmet>
        <title>Scoo Luxe Rental in Bangalore | Scoo Luxe  - ScooEV</title>
        <meta
          name="description"
          content=" Scoo Luxe Rental in Bangalore, Scoo Luxe  offers you with top brand EV's that enhance your riding experience with affordable price & easy payment options."
        />
        <meta
          name="keywords"
          content="Scoo Luxe Rental in Bangalore, Electric Bike Rental in Bangalore, Electric Bikes on Rental Near Me, Ather  Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore, Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike. "
        />
      </Helmet>
      <HeroBanner />
      <WhyRentFromUs />
      <RentNow />
      <Products />

      <Testimonial />
      <FAQs faqdata={LuxaccordianData} />
      {/* <PreneurSection /> */}
      {/* <FAQs faqdata={LuxaccordianPreneurData} /> */}
      {/* <Blog /> */}
      {/* <NesLetter /> */}
    </div>
  );
};

export default ScooLuxe;
