import React, { useEffect, useState } from "react";
import testimonialImg from "../../../Assets/image/Assets/Image-2.png";
import "./testimonial.css";
import { Container, Row, Col } from "react-bootstrap";
import star from "../../../Assets/image/Reviews/Star.png";
import { LuxReviewsData } from "../../../data/dataApi";
const Testimonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % LuxReviewsData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [activeIndex, LuxReviewsData.length]);
  return (
    <>
      <Container fluid className="p-lg-5 mb-3">
        <section>
          <Container className="scoo-luxe-testimonial-content-wrapper">
            <Row>
              <Col lg={7} md={12} sm={12} className="p-5 reviews_wrapper">
                <div className="star_wrapper mb-3">
                  <img src={star} alt="no logo" className="" />
                  <img src={star} alt="no logo" className="" />
                  <img src={star} alt="no logo" className="" />
                  <img src={star} alt="no logo" className="" />
                  <img src={star} alt="no logo" className="" />
                </div>
                <div className="mb-4">
                  {LuxReviewsData.map((element) => {
                    const { id, heading, name } = element;
                    return (
                      <>
                        <div key={id}>
                          {id === activeIndex ? (
                            <>
                              <h3 className="header_line testo_font">
                                {heading}
                              </h3>
                              <p>— {name}</p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="testimonialDot_Wrap">
                  <ul className="d-flex p-0 m-auto">
                    {LuxReviewsData.map((data) => {
                      const { id, count } = data;
                      return (
                        <>
                          <li key={id}>
                            <div
                              className={
                                id === activeIndex
                                  ? "dottest_icon"
                                  : "dot2_icon"
                              }
                            >
                              {count}
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </Col>

              <Col lg={5} md={12} sm={6} className="review_Card p-0">
                {LuxReviewsData.map((data) => {
                  const { id, ReviewSlider } = data;
                  return (
                    <div key={id}>
                      {id === activeIndex ? (
                        <img
                          src={ReviewSlider}
                          alt="Scoo Luxe Rental in Bangalore"
                          className="review_image"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default Testimonial;
