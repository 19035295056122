import React from "react";
import "./blogBanner.css";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";

export default function BlogBanner() {
  return (
    <Container
      fluid
      className="BlogBanner_ContainerMain mt-5"
    >
      <section>
        <Container className="p-0">
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div className="BlogBanner_Info d-flex flex-column justify-content-center">
              <div className="BlogBanner_InfoTitle">
                <p className="p-0 m-0">
                  Assured monthly earnings with Scoo EV rentals.
                </p>
              </div>

              <div className="pl-2 d-flex flex-column gap-3">
                <div className="d-flex flex-row gap-3">
                  <div className="BlogBanner_TickIconBox d-flex justify-content-center align-items-center">
                    <img
                      src="Images/Blogs/tick.png"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <p className="m-0 p-0 BlogBanner_TickDes">
                    Lorem ipsum dolor sit amet consectetur. At iaculis.
                  </p>
                </div>

                <div className="d-flex flex-row gap-3">
                  <div className="BlogBanner_TickIconBox d-flex justify-content-center align-items-center">
                    <img
                      src="Images/Blogs/tick.png"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <p className="m-0 p-0 BlogBanner_TickDes">
                    Lorem ipsum dolor sit amet consectetur. Tortor pretium.
                  </p>
                </div>

                <div className="d-flex flex-row gap-3">
                  <div className="BlogBanner_TickIconBox d-flex justify-content-center align-items-center">
                    <img
                      src="Images/Blogs/tick.png"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <p className="m-0 p-0 BlogBanner_TickDes">
                    Lorem ipsum dolor sit amet consectetur. Nec est.
                  </p>
                </div>
              </div>

              <div className="d-flex gap-2 flex-row flex-wrap">
                <Button
                  variant="outlined"
                  className="BlogBanner_Button BlogBanner_Button1"
                >
                  Learn more
                </Button>
                <Button
                  variant="contained"
                  className="BlogBanner_Button BlogBanner_Button2"
                >
                  Get started
                </Button>
              </div>
            </div>
            <div className="BlogBanner_Img">
              <img
                src="Images/Blogs/banner-profile.png"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </Container>
      </section>
    </Container>
  );
}
