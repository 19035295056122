export const CancelRefundData = [
  {
    id: 1,

    describe: [
      {
        id: 1,
        des: " All bookings made through the Website / App / Phone shall be cancelled through the same. The User may also cancel bookings by calling our helpline number and any cancellation communicated in this manner shall be valid, subject to the User identifying himself/herself and providing adequate details regarding the booking. On veriﬁcation of the User’s identity and booking details, the user shall be intimidated regarding the conﬁrmation of his / her request to cancel the booking.   ",
      },
      {
        id: 2,
        des: " All bookings made through the Website / App / Phone shall be cancelled through the same. The User may also cancel bookings by calling our helpline number and any cancellation communicated in this manner shall be valid, subject to the User identifying himself/herself and providing adequate details regarding the booking. On veriﬁcation of the User’s identity and booking details, the user shall be intimidated regarding the conﬁrmation of his / her request to cancel the booking.  ",
      },
      {
        id: 3,
        des: " The user will be given a refund in the case when a booking is cancelled by the Provider due to the unavailability of vehicles. At times when the provider is unable to provide a vehicle for a booking due to reasons such as accident, damage, service, late handover by previous user, etc.; they will process a full refund of the entire amount of the booking back to the original method of payment. The refund will be processed within a maximum time frame of 8-15 working days.  ",
      },
      {
        id: 4,
        des: " Sigrid spectrum is not liable to reimburse any expenses borne by a user due to cancellation of any third party associated plans that are not connected with Sigrid spectrum or the services provided by us; due to any vehicle breakdown, accident, damage, or issues caused due to the vehicle or service  ",
      },
      {
        id: 5,
        des: "In case of any wrongful deductions from the provider’s end and/or where the user feels they have been charged wrongly for any ride, the user is required to raise a query through our website/app or helpline number. Sigrid spectrum reserves the right to process refunds based on due internal investigation and veriﬁcation of such claims. On successful investigation, such refunds will be processed within 8-15 working days.  ",
      },
      {
        id: 6,
        des: "In case of any wrongful deductions from the provider’s end and/or where the user feels they have been charged wrongly for any ride, the user is required to raise a query through our website/app or helpline number. Sigrid spectrum reserves the right to process refunds based on due internal investigation and veriﬁcation of such claims. On successful investigation, such refunds will be processed within 8-15 working days.  ",
      },
    ],
  },
];
