import React, { useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import rentIcon from "../../Assets/image/header/Icon.svg";
import bgimage from "../../Assets/image/service/service.png";
import Evservice from "./evservice"; // Import Evservice component

const Serviebanner = () => {
  const evServiceRef = useRef(null);

  const scrollToEvService = () => {
    evServiceRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="servicebanner_container">
        <Container>
          <Row className="d-flex ">
            <Col lg={5} md={12} sm={12} className="banner_wrapper">
              <div className="mt-lg-5 mt-md-5">
                <h3 className="">
                  <span className="header_banner">
                    It's time to go electric.
                  </span>
                </h3>
                <span className="header_span">
                  Glide with purity and purpose!
                </span>
                <p className="header_para mb-3">
                  Enjoy expert service for your electric vehicle. Our team
                  specializes in maximizing performance and reliability, so you
                  can drive with confidence.
                </p>
                <div className="d-flex p-lg-0 m-lg-0">
                  <Button
                    className="rent_btn d-flex"
                    onClick={scrollToEvService}
                  >
                    <div>
                      <img
                        src={rentIcon}
                        alt="Scoo Rental"
                        className="rent_img mt-1 me-2"
                      />
                    </div>
                    <div>
                      <span className="">Contact Us</span>
                    </div>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="servicebanner_container_mobile-wrapper">
        <div className="servicebanner_container_mobile">
          <div className="mt-lg-5 mt-md-5">
            <h3 className="">
              <span className="header_banner">It's time to go electric.</span>
            </h3>
            <span className="header_span">Glide with purity and purpose!</span>
            <p className="header_para mb-3">
              Enjoy expert service for your electric vehicle. Our team
              specializes in maximizing performance and reliability, so you can
              drive with confidence.
            </p>
            <div className="d-flex p-lg-0 m-lg-0">
              <Link
                to="https://scoomerchandise.myshopify.com"
                className="link_text"
                target="_blank"
              >
                <Button className="rent_btn d-flex">
                  <div>
                    <img
                      src={rentIcon}
                      alt="Scoo Rental"
                      className="rent_img mt-1 me-2"
                    />
                  </div>
                  <div>
                    <span className="">Rent now</span>
                  </div>
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <img src={bgimage} alt="bgimage" className="bgimage" />
        </div>
      </div>
      <Evservice ref={evServiceRef} /> {/* Add the Evservice component */}
    </>
  );
};

export default Serviebanner;
