import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./hiring.css";
import { Link } from "react-router-dom";

const ContactHiring = () => {
  return (
    <>
      <Container fluid className="py-lg-4">
        <section>
          <Container className="">
            <Row className=" mb-3 py-5">
              <Col md={12} className="footer_wrapper">
                <div className="mb-4">
                  <h4 className=" hering_head">We’re hiring!</h4>
                  <p className="hiring_para">
                    Our team is growing fast and we’re always looking for smart
                    people.
                  </p>
                  <div className="learwrapper_btn">
                    {/* <Button className="enquire_btn py-2 learn_more_title">
                      <spna className="enquire_text">Learn more</spna>
                    </Button> */}
                    <Link to="https://scooevrentals.blog/2024/01/18/we-are-hiring/">
                    <Button className="rent_btn mx-3 view_title">
                      <spna className=""> View open roles</spna>
                    </Button>
                    </Link>
                   
                  </div>
                </div>
                <hr />
              </Col>
              <hr className="bottom_line"></hr>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default ContactHiring;
