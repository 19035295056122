import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LuxaccordianData } from "../../../data/dataApi";
import plus from "../../../Assets/image/Accordian/plus.png";
import minus from "../../../Assets/image/Accordian/minus.png";
const FAQs = ({ faqdata }) => {
  const [selected, setSelected] = useState(0);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <>
      <Container fluid className="p-lg-5">
        <section>
          <Container>
            <Row className="mb-4">
              <Col md={5} className="faq_wrapper ">
                <div className="">
                  <h5 className="support_Title ">Support</h5>
                  <h3 className="faq_Title mb-3">FAQs</h3>
                  <p className="para">
                    Everything you need to know about the scooters and
                    <br /> billing. Can’t find the answer you’re looking for?
                    Please chat to our friendly team.
                  </p>
                </div>
              </Col>
              <Col md={7}>
                <ul className="accordiancard">
                  {faqdata?.map((item, i) => {
                    const {
                      id,
                      question,
                      answer,
                      list,
                      list2,
                      list3,
                      list4,
                      list5,
                      list6,
                    } = item;
                    return (
                      <>
                        <li key={id} className="mb-4" onClick={() => toggle(i)}>
                          <div className="description">
                            <div className="accodian_wrapper">
                              <div className="question_head">{question}</div>

                              <div>
                                {selected === i ? (
                                  <img
                                    src={minus}
                                    alt="no data"
                                    className="minus_icon"
                                  />
                                ) : (
                                  <img
                                    src={plus}
                                    alt="no data"
                                    className="minus_icon"
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                selected === i ? "content show" : "content"
                              }
                            >
                              <p className="answer_title">{answer}</p>
                              <p className="answer_title">{list}</p>
                              <p className="answer_title">{list2}</p>
                              <p className="answer_title">{list3}</p>
                              <p className="answer_title">{list4}</p>
                              <p className="answer_title">{list5}</p>
                              <p className="answer_title">{list6}</p>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default FAQs;
