import React from "react";
import "./scooPageInfoOne.css";
import { Container, Row, Col } from "react-bootstrap";
import { ScooPageInfoOneTimeDateBox } from "../../../data/scooPageData";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import play from "../../../Assets/image/club/_Play button.svg";
import playVideo1 from "../../../Assets/image/club/play-viddeo1.webp";
import playVideo2 from "../../../Assets/image/club/play-video2.webp";

export default function ScooPageInfoOne() {
  return (
    <>
      <Container fluid className="letscoo_bg p-lg-5 ">
        <section>
          <Container>
            <Row>
              <Col lg={6} sm={12}>
                <h3 className="journey_header scooPageInfo_head mb-3">
                  Scoo Campus Bike Rentals
                </h3>
                <p className="scooPageInfo_para mb-4">
                  Scoo Preneur is ideal for Campus usage (like colleges,
                  universities), which aims to provide electric bikes to all
                  students so we can together achieve a green commute. Scoo
                  Campus is driven with an idea of a green commute made
                  available for students. EV rental bikes are made available to
                  ride within and outside the campus for students and
                  faculties. 
                </p>

                <div className="letsoo_campus">
                  <div>
                    <img src={playVideo1} width="100%" height="100%" />
                  </div>
                  <img src={play} alt="" className="play-btn" />
                </div>
              </Col>

              <Col lg={6} sm={12} className="journey_wrapper journey_padding">
                <h3 className="journey_header scooPageInfo_head mb-3">
                  Scoo Community Bike Rentals
                </h3>
                <p className="scooPageInfo_para mb-4">
                  As a Scoo Preneur, you can provide e-bike rental service to
                  any customer within a Community (like a resort, retreat
                  centers, apartments) in that area/region. EV rental bikes are
                  made available to ride within and outside the community for
                  residents. This drives the idea of a green commute made
                  available for all.
                </p>

                <div className="letsoo_campus">
                  <div>
                    <img src={playVideo2} width="100%" height="100%" />
                  </div>
                  <img src={play} alt="" className="play-btn" />
                </div>

                <Link
                  to="https://scooev.com/Contact"
                  className="link_text"
                  target="_blank"
                >
                  <Button className="letScoo_btn1  d-flex">
                    <div>
                      <spna className="">JOIN US</spna>
                    </div>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>

      {/* <Container fluid className="letscoo_bg p-lg-5 ">
        <section>
          <Container>
            <Row>
              <Col lg={6} sm={12}>
                <div className="letsoo_campus">
                  <div>
                    <img
                      src="Images/ScooPage/scoo-Info1.png"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </Col>

              <Col lg={6} sm={12} className="journey_wrapper journey_padding">
                <h3 className="journey_header scooPageInfo_head mb-3">
                  Scoo Campus Bike Rentals
                </h3>
                <p className="mb-4">
                  Scoo Campus is driven with an idea of a green commute made
                  available for students. EV rental bikes are made available to
                  ride within and outside the campus for students and faculties.
                  Scoo Campus aims to provide electric bikes to all students so
                  we can together achieve a green commute
                </p>
                <div className="d-flex flex-row flex-wrap gap-3 letsScoo_Card">
                  {ScooPageInfoOneTimeDateBox?.map((ele, index) => {
                    return (
                      <div
                        className="ScooPageInfoOneTimeDateBox bg-white d-flex flex-column justify-content-center gap-2 p-3"
                        key={index}
                      >
                        <div className="ScooPageInfoOne_IconImg mb-2">
                          <img src={ele?.icon} width="100%" height="100%" />
                        </div>

                        <p className="p-0 m-0 letScoo_para">{ele?.text}</p>
                      </div>
                    );
                  })}
                </div>
                <Link
                  to="https://scoomerchandise.myshopify.com"
                  className="link_text"
                  target="_blank"
                >
                  <Button className="letScoo_btn  d-flex">
                    <div>
                      <spna className="">Rent now</spna>
                    </div>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </Container> */}
    </>
  );
}
