import React from "react";
import BannerImage from "./../../Assets/image/deliverEarn/BannerImage.gif";
import Rentnowicon from "./../../Assets/image/icons/rent-now-icon.svg";
import { Button } from "react-bootstrap";
import { Link } from "react-scroll";
import "./deliver&earn.css";

const DeliverEarnBanner = () => {
  return (
    <>
      <div>
        <img src={BannerImage} alt="Banner" className="banner-center-image" />
        <div className="right-side banner-container">
          <div className="w-100 d-flex justify-content-end">
            <div className="right-side-content">
              <h1>Our e-bikes are crafted</h1>
              <h2>to make delivery a breeze</h2>
              <div className="d-flex p-lg-0 m-lg-0">
                <Link
                  to="https://scoomerchandise.myshopify.com/collections/scoo-rental"
                  className="link_text right-side-action"
                  target="_blank"
                >
                  <Button className="rent_btn d-flex">
                    <div className="">
                      <img
                        src={Rentnowicon}
                        alt="Electric Vehicle Rental in Bangalore"
                        className="rent_img mt-1 me-2"
                      />
                    </div>

                    <div>
                      <span className="">Rent now</span>
                    </div>
                  </Button>
                </Link>
                <Link to="/Contact">
                  <Button className="enquire_btn mx-2">
                    <span className="enquire_text">Enquire now</span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-container-mobile">
        <div className="right-side">
          <h1>Our e-bikes are crafted</h1>
          <h2>to make delivery a breeze</h2>
          <div className="d-flex p-lg-0 m-lg-0">
            <Link
              to="https://scoomerchandise.myshopify.com/collections/scoo-rental"
              className="link_text right-side-action"
              target="_blank"
            >
              <Button className="rent_btn d-flex">
                <div className="">
                  <img
                    src={Rentnowicon}
                    alt="Electric Vehicle Rental in Bangalore"
                    className="rent_img mt-1 me-2"
                  />
                </div>

                <div>
                  <span className="">Rent now</span>
                </div>
              </Button>
            </Link>
            <Link to="/Contact">
              <Button className="enquire_btn mx-2">
                <span className="enquire_text">Enquire now</span>
              </Button>
            </Link>
          </div>
        </div>
        <div className="banner-image">
          <img src={BannerImage} alt="Banner" className="banner-center-image" />
        </div>
      </div>
    </>
  );
};

export default DeliverEarnBanner;
