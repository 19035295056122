import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import blog from "../../Assets/image/Blog/blog.png";
import { blognData } from "../../data/dataApi";

import "./blog.css";

const Blog = () => {
  return (
    <>
      <Container fluid className="b2b_container p-lg-5 py-2 blog_bg">
        <section>
          <Container className="py-3">
            <Row className="mb-4">
              <Col lg={12} className="p-0 m-0">
                <div className="d-flex">
                  <div>
                    <h4 className="header_line d-flex bold_head">Blogs</h4>
                  </div>
                  <div className="line_hr">
                    <span></span>
                  </div>
                </div>
              </Col>
              {/* <Col lg={11} className="py-1 px-0">
                <span>
                  <hr />
                </span>
              </Col> */}
            </Row>
            <Row>
              {blognData.map((item) => {
                const {
                  id,
                  icon,
                  btn,
                  blogtitle,
                  day,
                  views,
                  facebook,
                  instagram,
                  Linkedin,
                  Twitter,
                } = item;
                return (
                  <>
                    <Col md={4} key={id}>
                      <Card className="blog_card">
                        <Card.Img variant="top" src={icon} />
                        <Card.Body>
                          <Button className="offer_btn">{btn}</Button>
                          <Card.Title className="mt-2">
                            <div className="blog_Title mb-2">{blogtitle}</div>
                            <div className="day_title">
                              <sapn>{day}</sapn>
                              <span className="dot_title">•</span>
                              <sapn className="mx-2">{views}</sapn>
                            </div>
                            <hr></hr>
                            <div className="social_icon">
                              <div>
                                <Link
                                  to="https://www.facebook.com/scooev"
                                  target="_blank"
                                >
                                  <img src={facebook} alt="facebook" />
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to="https://www.instagram.com/scoo_ev/"
                                  target="_blank"
                                >
                                  <img src={instagram} alt="instagram" />
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to="https://www.linkedin.com/company/scoo-ev-rentals/"
                                  target="_blank"
                                >
                                  <img src={Linkedin} alt="Linkedin" />
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to="https://twitter.com/ScooEv"
                                  target="_blank"
                                >
                                  <img src={Twitter} alt="Twitter" />
                                </Link>
                              </div>
                            </div>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                );
              })}
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default Blog;
