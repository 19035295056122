import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./privacy.css";
import { privacyData } from "../Privacy/privaydata";
import { Helmet } from "react-helmet";

export const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>
        Electric Bike Rental in Bangalore | Privacy Policy - ScooEV
        </title>
        <meta
          name="description"
          content="This privacy policy is an electronic record in the form of an electronic contract formed under the information technology act, 2000."
        />
        <meta
          name="keywords"
          content="Electric Bike Rental in Bangalore, Electric Bikes on Rental Near Me, Ather  Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore,Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike. "
        />
      </Helmet>
      <Container className="policy_Container mt-5 py-5">
        <h1 className="py-3 privacy_head">Privacy Policy</h1>
        <Row>
          <Col lg={12}>
            {privacyData.map((item) => {
              const { id, heading, para, describe } = item;

              return (
                <div key={id}>
                  <p>{para}</p>
                  <div className="mb-4">
                    <h5 className="privacy_header">{heading}</h5>
                    {describe?.map((data) => {
                      const { id, des } = data;
                      return (
                        <div key={id}>
                          <p className="privacy_para">{des}</p>
                        </div>
                      );
                    })}

                    {/* {if(heading === "How your Personal Information is used:"){
                          privacyLit.map(){
                            <p></p>
                          }
                    }} */}
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
};
