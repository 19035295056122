import React from "react";
import { Card } from "react-bootstrap";
import "./mostPopularBlogCard.css";

export default function MostPopularBlogCard({ img, title, views, time }) {
  return (
    <div className="MostPopularBlogCardContainer d-flex flex-row justify-content-center align-items-center p-3">
      <div className="d-flex flex-column gap-2 MostPopularBlogCardInfoBox m-1">
        <div className="MostPopularBlogCardTitle">{title?.slice(0, 60)}...</div>
        <div className="MostPopularBlogCardInfo d-flex flex-row flex-wrap gap-2">
          <span>{time} days ago</span>
          <span>{views} views</span>
        </div>
      </div>
      <Card className="MostPopularBlogCardImgBox m-1">
        <Card.Img
          variant="top"
          width="100%"
          height="100%"
          src={`${img}`}
          className="borderRadius_5px"
        />
      </Card>
    </div>
  );
}
