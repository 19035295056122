import React from "react";
import { Button } from "react-bootstrap";
import heroImage from "../../../Assets/image/Assets/Rectangle 24.png";
import "./herobanner.css";
import rentIcon from "../../../Assets/image/header/Icon.svg";
import shape from "../../../Assets/image/Assets/Shape2.svg";
import ninja from "../../../Assets/image/Assets/ninja.webp";
import { Container, Row, Col } from "react-bootstrap";
import { ninjaSliderData } from "../../../data/dataApi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const HeroBanner = () => {
  const HeroNinjaBanner = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container
        fluid
        className="scooninja-hero-banner-container mt-lg-5 mt-md-5"
      >
        <Container className="container_ninja mt-lg-5 mt-md-5">
          <Row className="mt-lg-5 mt-md-5">
            <Slider {...HeroNinjaBanner} className="">
              {ninjaSliderData.map((data) => {
                const {
                  id,
                  head,

                  amount,

                  btn,

                  img,
                } = data;
                return (
                  <Row key={id} className="d-flex mt-lg-5 mt-md-5">
                    <Col lg={6} className=" center_Title">
                      <div className="">
                        <h1 className="scooninja-hero-title mb-3">{head}</h1>
                        <h3 className="">
                          <span className="scooninja-hero-subtitle">
                            ₹{amount}
                          </span>
                        </h3>

                        <div className="d-flex p-0 mt-5   mb-2">
                          <Button className=" ninja_btn d-flex">
                            <div>
                              <img
                                src={rentIcon}
                                alt="no logo"
                                className="rent_img mt-1 me-2"
                              />
                            </div>

                            <div>
                              <Link to="/Contact" className="text-white">
                                <spna className="">{btn}</spna>
                              </Link>
                            </div>
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="">
                      <img src={ninja} alt="ev bike Rental in Bangalore" className="mt-lg-5" />
                    </Col>
                  </Row>
                );
              })}
            </Slider>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default HeroBanner;
