import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./HomeeBikeBangalore.css";

const HomeeBikeBangalore = () => {
  return (
    <>
      <Container fluid className="homeebike_container p-lg-5 ">
        <section>
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12}>
              <h3 className="homeebike_header">Rent a e-Bike in Bangalore (Electric Scooter Rental) - Scoo Ev Rentals</h3>
                <p>
                    Bangalore, the bustling metropolis, is now navigating towards a greener future with the surge in electric scooter 
                    rentals. Among the pioneers in this eco-friendly Scoo Ev Rentals, offering not just a mode of transportation but a 
                    commitment to sustainability in every ride.
                </p>
                <p>
                    As the demand for sustainable commuting options grows, electric scooter rentals have become the go-to solution 
                    in Bangalore. Scoo Ev Rentals emerges as a key player, providing not only a convenient mode of travel but also 
                    contributing to a cleaner and greener city.
                </p>
              </Col>

              <Col
                lg={12}
                md={12}
                sm={12}
                className="homeebike_wrapper homeebike_padding"
              >
                <h3 className="homeebike_header">Scoo Ev Rentals: Leading the Green Revolution</h3>
                <p>
                    Scoo Ev Rentals stands out as a leader in the green mobility revolution. With a fleet of electric scooters 
                    designed for sustainable commuting, the company is making strides towards a cleaner and better city.
                </p>
                <p>
                    Renting an electric scooter from Scoo Ev is not just a smart environmental choice but also a cost-effective and 
                    convenient way to navigate the city. Users enjoy the freedom of personal transportation without the burdens of 
                    ownership.
                    <p>
                    </p>
                    The use of scoo ev electric scooters rental platform significantly reduces both air and noise pollution, making 
                    Scoo Ev Rentals a responsible choice for urban commuting. Choosing an electric scooter contributes to 
                    creating an environmentally friendly cityscape.
                    <p>
                    </p>
                    Scoo Ev's commitment to handle the end to end booking process is assured to be quick and hassle-free,
                    ensuring that users can secure their electric scooter with ease.
                    <p>
                    </p>
                    Scoo Ev caters to diverse user needs with a range of pricing plans. The company adopts a transparent pricing 
                    structure, eliminating hidden costs and providing users with a straightforward and honest rental experience.
                    <p>
                    </p>
                    Diversity is key in Scoo Ev fleet, featuring a variety of electric scooters tailored to different preferences and 
                    requirements. Each model comes with unique features and benefits, ensuring there's a perfect ride for every user.
                    <p>
                    </p>
                    Prioritizing rider safety, Scoo Ev integrates advanced safety features into its electric scooters. From responsive
                    braking systems to the provision of timley service assistance, Scoo Ev ensures a secure riding experience.
                    <p>
                    </p>
                    Renting an electric scooter with Scoo Ev is a breeze. Users go through a simple sign-up process, and within
                    minutes, they can be on their way to a sustainable and hassle-free commute.
                </p>
                
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default HomeeBikeBangalore;
