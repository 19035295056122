import React, { forwardRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../Journey/journey.css";
import eService from "./../../Assets/image/service/eService.png";
import ScooClinicMantra from "../../Assets/image/service/ScooClinicMantra.png";

const Evservice = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <Container fluid className="joury_container p-lg-5 p-2 marginTop">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <img
                width="100%"
                height="90%"
                src={eService}
                alt="socoGreenBike"
                style={{
                  objectFit: "contain",
                }}
              />
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="journey_wrapper journey_padding d-flex flex-column justify-content-center"
            >
              <h3 className="scoo_head-eservice">Scoo ev Services </h3>
              <p>Unplug Your Worries: Dive Into Electric Future with Scoo!</p>
              <p>
                We are transforming the after-sales service in the EV industry
                towards a more sustainable future. Our key focus is on making
                your electric vehicle ride in its dream runsᅳsleek, smooth, and
                efficient. We are committed to not just meeting but exceeding
                your service requirements and making every interaction leave you
                smiling.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="p-lg-5 p-2">
        <Container>
          <div className="scoo-clinic">
            <div className="journey_wrapper journey_padding">
              <Row className="mb-lg-4 header_explore py-md-3 py-lg-2">
                <Col lg={4} className="p-0 m-0">
                  <h4 className="header_line">Scoo Clinic Mantra</h4>
                </Col>
                <Col lg={8} className="py-1 p-0 m-0">
                  <span>
                    <hr />
                  </span>
                </Col>
              </Row>
              <p>
                We've come together to provide the best after-sales services to
                electric vehicle owners in the market. Our goal is to create a
                comprehensive service ecosystem that meets the demands of all
                electric vehicle owners in India. Our qualified technicians are
                dedicated to excellence and provide great customer care,
                bridging the current gap in EV after-sales services. Customer
                satisfaction is at the heart of everything we do, from routine
                maintenance to repairs.
              </p>
              <p>Scoo EV Clinic believes in two strong mantras:</p>
              <div className="scoo_clinic_m_card_wrapper">
                <Card>
                  <p>
                    "The joy of making others happy is the true essence of
                    happiness,"
                  </p>
                </Card>
                <Card>
                  <p>
                    "Providing exceptional service reaps plentiful rewards."
                  </p>
                </Card>
              </div>
              <p>
                At Scoo EV Clinic, we're more than just a service centre. We're
                a community that's passionate about the future of e-mobility. We
                offer expert care and transparent pricing to make our clients
                believe that owning an electric vehicle is easy, sustainable and
                cost effective.
              </p>
            </div>
            <div lg={6} md={6} sm={12}>
              <img
                width="100%"
                height="100%"
                src={ScooClinicMantra}
                alt="socoGreenBike"
                style={{
                  objectFit: "fill",
                }}
              />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
});

export default Evservice;
