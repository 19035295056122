import React from "react";
import "./scooPageIoT.css";
import { Container, Row, Col } from "react-bootstrap";
import { ScooPageIoTItems } from "../../../data/scooPageData";
import { Button } from "react-bootstrap";
import iotImg from "../../../Assets/image/Assets/Scooter-OlaS1Pro1.webp";

export default function ScooPageIoT({ bannerRef }) {
  return (
    <Container fluid className="scooTio_bg " ref={bannerRef}>
      <Row>
        <Col lg={7} sm={12} className="" id="below-section">
          <div className="letscooCard_left  ">
            <h3 className="journey_header mb-3">Scoo IoT</h3>
            <Row>
              {ScooPageIoTItems?.map((ele, index) => {
                return (
                  <Col
                    lg={index >= ScooPageIoTItems.length - 2 ? 6 : 4}
                    md={6}
                    className="mb-3 iot_Card"
                    key={index}
                  >
                    <div className="ScooPageIoTItem p-3 d-flex flex-column gap-2">
                      <div className="ScooPageIoT_IconImg">
                        <img
                          src={ele?.icon}
                          width="100%"
                          height="100%"
                          alt="data"
                        />
                      </div>

                      <p className="p-0 m-0">{ele?.title}</p>
                      <div className="p-0 m-0 scoocard_description">
                        {ele?.description}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>

        <Col lg={5} sm={12} className="">
          <div className="letsoo_campus">
            <div>
              <img src={iotImg} className="iotImg w-100" />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
