import React from "react";
import { Button, Card } from "react-bootstrap";
import "./blogCardOne.css";
import "../BlogsPage.css";

export default function BlogCardOne({ img, title, time, views }) {
  return (
    <Card className="BlogsPageBorderRadius20px position-relative overflow-hidden">
      <Card.Img
        variant="top"
        src={`${img}`}
        className="BlogCardOneImgContainer"
      />
      <div className="BlogCardOne_Info d-flex flex-column">
        <div className="d-inline-block">
        <Button className="BlogCardBtnTransparent">Featured Posts</Button>
        </div>
        <div className="BlogCardOneTitle">{title?.slice(0, 80)}...</div>

        <div className="d-flex flex-row flex-wrap gap-3 align-items-center BlogCardOneDes">
          <span>{time} mins ago</span>
          <span>{views} views</span>
        </div>
      </div>
    </Card>
  );
}
