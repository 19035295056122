import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import statueofliberty from "../../Assets/image/deliverEarn/statue-of-liberty.png";
import hyd from "../../Assets/image/deliverEarn/hyd.png";
import bangalore from "../../Assets/image/deliverEarn/bangalore.png";
import Chinnai from "../../Assets/image/deliverEarn/Chinnai.png";

const SpotUs = () => {
  return (
    <Container>
      <Row className="mb-lg-4 header_explore  py-md-3 py-lg-2 mt-4">
        <Col lg={3} className="p-0 m-0">
          <h4 className="header_line ">You'll spot us with Scoo’s at</h4>
        </Col>
        <Col lg={9} className="py-1 p-0 m-0">
          <span>
            <hr />
          </span>
        </Col>
      </Row>
      <div className="spotus-cards-wrapper">
        <Card className="spotus-cards">
          <img src={bangalore} className="icon-image" alt="bangalore" />
          <h1>Bengaluru</h1>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur. Diam hendrerit pulvinar
            molestie nam.
          </p> */}
        </Card>
        <Card className="spotus-cards">
          <img src={hyd} className="icon-image" alt="bangalore" />
          <h1>Mysore</h1>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur. Diam hendrerit pulvinar
            molestie nam.
          </p> */}
        </Card>

        <Card className="spotus-cards">
          <img src={Chinnai} className="icon-image" alt="bangalore" />
          <h1>Mangalore</h1>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur. Diam hendrerit pulvinar
            molestie nam.
          </p> */}
        </Card>
      </div>
    </Container>
  );
};

export default SpotUs;
