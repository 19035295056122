import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HomeBannerData } from "../../data/dataApi";
import "./Banner.css";
import ContactUsModal from "../CommonComponents/ContactUsModal/ContactUsModal";
import { Link } from "react-router-dom";

import scooty from "../../Assets/image/Assets/Rectangle 25.png";

import HeroBanner from "../ScooLuxe/HeroBanner/HeroBanner";
import SubBanner from "../ScooNinja/SubBanner/SubBanner";
import LottiBanner from "../../Component/Banner/HomeBanner/LottiBanner";
import ScoLuxBanner from "../../Component/Banner/HomeBanner/ScoLuxBanner";
import RentalHomeBanner from "../../Component/Banner/HomeBanner/RentalHomeBanner";

// media queries for tablets phone  in css?

const Banner = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const HomeBanner = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="lottiBanner_section mb-3 ">
        <Slider {...HomeBanner}>
          <div>
            <LottiBanner />
          </div>
          <div>
            <ScoLuxBanner />
          </div>

          {/* <div>
            <RentalHomeBanner />
          </div> */}
        </Slider>
      </section>
    </>
  );
};

export default Banner;
