import React from "react";
import ClubBanner from "../Component/ScooClub/ClubBanner";
import ClubCard from "../Component/ScooClub/ClubCard";
import ClubMembership from "../Component/ScooClub/ClubMembership";
import FAQs from "../Component/ScooLuxe/FAQs/FAQs";
import { clubaccordianData } from "../data/dataApi";
import ClubWrapper from "../Component/ScooClub/ClubWrapper";
import { Helmet } from "react-helmet";


const ScooClub = () => {
  return (
    <div>
       <Helmet>
        <title>EV rental membership and subscription | Scoo EV</title>
        <meta
          name="description"
          content=" Scoo Club provides you a platform to enroll as a member of this Elite Club. Through this membership you get to enjoy the benefit of having luxury EV bikes delivered to your doorstep."
        />
        <meta
          name="keywords"
          content="Electric Bike Rental in Bangalore, Electric Bikes on Rental Near Me, Ather  Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike . "
        />
      </Helmet>
      <ClubBanner />
      <ClubWrapper />
      <ClubMembership />
      <FAQs faqdata={clubaccordianData} />
    </div>
  );
};

export default ScooClub;
