import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../Journey/journey.css";
import socoGreenBike from "../../Assets/image/deliverEarn/socoGreenBike.png";
import ChevronRight from "../../Assets/image/deliverEarn/ChevronRight.png";
const EnjoyBenifit = () => {
  return (
    <>
      <Container fluid className="joury_container p-lg-5 p-2 marginTop">
        <section>
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <img
                  width="100%"
                  height="90%"
                  src={socoGreenBike}
                  alt="socoGreenBike"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                className="journey_wrapper journey_padding"
              >
                <h3 className="journey_header">
                  Enjoy Benefits of a Scoo Club Member
                </h3>
                <p>
                  Join the Scoo Club now to enjoy exclusive incentives and
                  rewards. With 100% job assurance, transparent payment methods,
                  and assured full payouts, you can be confident that your
                  efforts will be rewarded.
                </p>
                <p>
                  As a Scoo Club member, you'll get the official Scoo membership
                  card, which activates lots of benefits. This card not only
                  provides monthly rider rewards, but it also allows for quick
                  collaboration with leading third-party logistics suppliers,
                  which is only available to our cardholders.
                </p>
                <p>
                  Enjoy the freedom to choose your work schedule - whether
                  part-time, full-time, or night shifts - and earn maximum
                  rewards while working at your own pace. Scoo Club ensures that
                  you receive your entire money on time, because we don’t charge
                  any hidden expenses and we operate around the clock.
                </p>
                {/* <Button className="download-btn">
                  <spna className="">Download App now</spna>
                  <img src={ChevronRight} alt="no logo" className="rent_img" />
                </Button> */}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default EnjoyBenifit;
