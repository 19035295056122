import React, { useState } from "react";
import adhaarCardLogo from "../../../Assets/image/Assets/adhaar_logo.png";
import phone from "../../../Assets/image/Assets/phone.png";
import bankAccountLogo from "../../../Assets/image/Assets/bankaccount_logo.png";
import licenceLogo from "../../../Assets/image/Assets/driving_licence_logo.png";
import pancardLogo from "../../../Assets/image/Assets/pancard_logo.png";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ninjaKycData, ninjaphoneData } from "../../../data/dataApi";

import "./kyc.css";
import { Link } from "react-router-dom";
const KYC = () => {
  const [border, setBorder] = useState("");

  const kycBorder = () => {};

  return (
    <>
      <Container fluid className="p-lg-5 mb-3">
        <section>
          <Container>
            <Row className="mb-lg-4 header_explore">
              <Col md={4} className="p-0 m-0">
                <h4 className="header_line p-0 m-0">
                  Begin your Scoo Ninja journey now! Documents required for KYC
                </h4>
              </Col>
              <Col md={8} className="py-2 m-0 px-0">
                <span>
                  <hr />
                </span>
              </Col>
            </Row>

            <Row className="mb-5">
              {ninjaKycData.map((data) => {
                const { id, img, head } = data;
                return (
                  <Col lg={3} md={4} sm={12} key={id} className="m-0 p-0">
                    <Card className="kyc_Card center_Title py-3 me-3 ">
                      <div className="d-flex">
                        <div>
                          <img src={img} alt="logo" className="kyc_img" />
                        </div>
                        <div className="kyc_Title mx-3 py-3">{head}</div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            <Row className="question_explore  py-4 ">
              <Col lg={5} md={4} sm={12} className="p-2 m-0 ">
                <div>
                  <h4 className="question_title d-flex mx-5">
                    Still got some questions to discuss? Call us!
                  </h4>
                </div>
              </Col>
              <Col lg={7} md={8} sm={12} className="py-1">
                <Row className="questionRow_wrapper">
                  {ninjaphoneData.map((data) => {
                    const { id, img, number } = data;
                    return (
                      <Col
                        lg={4}
                        md={6}
                        sm={12}
                        className="phone_container mb-3"
                        key={id}
                      >
                        <Link to={`tel:${number}`}>
                          <div className="d-flex m-auto phone_wrapper">
                            <div>
                              <img
                                src={img}
                                alt="no data"
                                className="phone_Title"
                              />
                            </div>

                            <div className="d-flex phone_number mx-2">
                              {number}
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default KYC;
