import React from "react";
import DeliverEarnBanner from "./Deliver&earnBanner";
import "./deliver&earn.css";
import EnjoyBenifit from "./EnjoyBenifit";
import WhyBecomeScooNinja from "./WhybecomeScooClubMemeber/WhybecomeScooClubMemeber";
import SliderContainer from "./WhybecomeScooClubMemeber/slider/slider";
import Hearfromriders from "./Hearfromriders";
import Requirements from "./Requirements";
import SpotUs from "./spotus";

const DeliverEarn = () => {
  return (
    <div className="deliverEarn-container">
      <DeliverEarnBanner />
      <SpotUs/>
      <EnjoyBenifit />
      <WhyBecomeScooNinja />
      {/* <SliderContainer /> */}
      <Hearfromriders />
      <Requirements />
    </div>
  );
};

export default DeliverEarn;
