import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import scooty from "../../Assets/image/Assets/white.png";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Container>
        <Row>
          <Col lg={12} className="py-5 thankyou_center">
            <div>
              <img
                src={scooty}
                alt="Electric Vehicle Rental in Bangalore"
                className="tankyou_img"
              />
            </div>

            <div className="mb-2 ">
              <h4 class="thank_header ">Thanks For Contacting Us!</h4>
              <h4 class="thank_header ">
                We Will Get In Touch With You Shortly.
              </h4>
            </div>

            <div>
              <Link to="/" target="_blank">
                <Button className="enquire_btn ">
                  <spna className="enquire_text">
                    <FiArrowLeft />
                    <span className="me-1"> Go Back</span>
                  </spna>
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Thankyou;
