import React from "react";
import Header from "./Component/Header/Header";
import { Link, Route, Routes } from "react-router-dom";
import Home from "../src/Pages/Home";
import "./App.css";
import { Footer } from "./Component/Footer/Footer";
import ScooRentals from "./Pages/ScooRentals";
import Contact from "./Pages/Contact";

import ScooLuxe from "./Pages/ScooLuxe";
import ScooNinja from "./Pages/ScooNinja";
import Blogs from "./Pages/Blogs/Blogs";
import ScooPage from "./Pages/ScooPage/ScooPage";
import ScooIoT from "./Component/ScooPage/ScooPageIoT/ScooPageIoT";
import whatsapp from "./Assets/image/Accordian/whatsapp.svg";
import { Privacy } from "./Component/Footer/Privacy/Privacy";
import TermCondition from "./Component/Footer/TermCondition/TermCondition";
import CancelRefund from "./Component/Footer/CancelRefund/CancelRefund";
import Thankyou from "./Component/Thankyou/Thankyou";
import ScooClub from "./Pages/ScooClub";
import DeliverEarn from "./Component/Deliver&earn/Deliver&earn";
import ServicePage from "./Component/service/service-page";

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/ScooRentals" element={<ScooRentals />}></Route>
        <Route path="/Contact" element={<Contact />}></Route>
        <Route path="/scooluxe" element={<ScooLuxe />}></Route>
        <Route path="/scooninja" element={<ScooNinja />}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/scoo" element={<ScooPage />}></Route>
        <Route path="/ScooIoT" element={<ScooIoT />}></Route>
        <Route path="/privacyPolicy" element={<Privacy />}></Route>
        <Route path="/TermCondition" element={<TermCondition />}></Route>
        <Route path="/CancelRefund" element={<CancelRefund />}></Route>
        <Route path="/Thankyou" element={<Thankyou />}></Route>
        <Route path="/scooClub" element={<ScooClub />}></Route>
        <Route path="/deliver-earn" element={<DeliverEarn />}></Route>
        <Route path="/service" element={<ServicePage />}></Route>
      </Routes>
      <Footer />
      <Link
        to="https://wa.me/message/JUN7R2GJAUNCG1"
        target="_blank"
        className="whatsapp-icon"
      >
        <img src={whatsapp} alt="" />
      </Link>
    </>
  );
};

export default App;
