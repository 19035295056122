import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CancelRefundData } from "../CancelRefund/CancelRefunddata";
import { Helmet } from "react-helmet";

const CancelRefund = () => {
  return (
    <>
      <Helmet>
        <title>Cancellation & Refund - ScooEV</title>
        <meta
          name="description"
          content="All bookings made through the Website / App / Phone shall be canceled through the same. ScooEV offer cancellation & refund as per our policy. "
        />
        <meta
          name="keywords"
          content="Electric Bike Rental in Bangalore, Electric Bikes on Rental Near Me, Ather  Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore,Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike. "
        />
      </Helmet>
      <Container className="policy_Container mt-5 py-5">
        <h1 className="py-3 privacy_head">Cancellation & Refund : </h1>
        <Row>
          <Col lg={12}>
            {CancelRefundData.map((item) => {
              const { id, describe } = item;

              return (
                <div key={id}>
                  <div className="mb-4">
                    {describe?.map((data) => {
                      const { id, des } = data;
                      return (
                        <div key={id}>
                          <p className="privacy_para">{des}</p>
                        </div>
                      );
                    })}

                    {/* {if(heading === "How your Personal Information is used:"){
                      privacyLit.map(){
                        <p></p>
                      }
                }} */}
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CancelRefund;
