import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import redsooty from "../../../Assets/image/Engines/redsooty.webp";
import line from "../../../Assets/image/Engines/Line.png";
import line2 from "../../../Assets/image/Engines/Line2.png";
import petrol from "../../../Assets/image/Engines/petrol.png";
import Vector from "../../../Assets/image/Engines/Vector.png";
import greenscooty from "../../../Assets/image/Engines/greenSooty.webp";
import { petrolcardData, petrolcardData2 } from "../../../data/dataApi";

import "./User.css";

const User = () => {
  return (
    <>
      <Container fluid className=" p-lg-5 mb-2">
        <section>
          <Container>
            <Row>
              <Col lg={6} md={12} sm={12} className=" mb-3">
                <Row className="mb-4 userHeder_wrapper py-3 px-3">
                  <Col md={5} className="">
                    <div>
                      <h4 className=" d-flex  petral_head">
                        Petrol-Engine users
                      </h4>
                    </div>
                  </Col>
                  <Col md={7} className="p-0 m-0">
                    <span>
                      <hr className="line_title" />
                    </span>
                  </Col>
                </Row>

                <Row className="mb-4 py-1 px-3">
                  <Col md={5} className="p-3">
                    <div>
                      <img src={redsooty} alt="Scoo Rental" />
                    </div>
                  </Col>
                  <Col md={7} className=" center_wrapper">
                    <div className="d-flex">
                      <div className="line_img ">
                        <img src={line} alt="Scoo Rental" />
                      </div>

                      <div>
                        <div className="monthly_titlte">Monthly Savings</div>
                        <h3 className="montht_amout">₹19,500/-</h3>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="px-3 ">
                  {petrolcardData.map((item) => {
                    const { id, img, para, amt, text } = item;
                    return (
                      <>
                        <Col md={6} className="p-1 " key={id}>
                          <Card className="petrol_Card p-2">
                            <div>
                              <ul className="nyx_list mb-2">
                                <li>
                                  <div className="mb-1">
                                    <img
                                      src={img}
                                      alt="Scoo Rental"
                                      className="icon"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <p className="petrol_para">
                                    {para} <b>{amt}</b>
                                    <span>{text}</span>
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </Card>
                        </Col>
                      </>
                    );
                  })}
                </Row>
                <Row className="px-3 ">
                  <Col md={12} className="p-1 ">
                    <Card className="petrol_Card p-2">
                      <div>
                        <ul className="nyx_list mb-2">
                          <li>
                            <div className="mb-1">
                              <img
                                src={petrol}
                                alt="Scoo Rental"
                                className="icon"
                              />
                            </div>
                          </li>
                          <li>
                            <p className="petrol_para">
                              Last-mile delivery partner monthly savings -
                              <b>Rs.19, 500/-</b>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col lg={6} md={12} sm={12} className="user_bg">
                <Row className="mb-3 user_wrapper py-3 px-3">
                  <Col md={4} className="">
                    <div>
                      <h4 className=" d-flex  petral_head text-white">
                        Scoo EV users
                      </h4>
                    </div>
                  </Col>
                  <Col md={8} className="p-0 m-0">
                    <span>
                      <hr className="line_title" />
                    </span>
                  </Col>
                </Row>

                <Row className="mb-4 py-3 px-3">
                  <Col md={5} className="p-3">
                    <div>
                      <img src={greenscooty} alt="Scoo Rental" />
                    </div>
                  </Col>
                  <Col md={7} className=" center_wrapper">
                    <div className="line2_img ">
                      <img src={line2} alt="Scoo Rental" />
                    </div>

                    <div>
                      <div className="monthly_titlte text_change">
                        Monthly Savings
                      </div>
                      <h3 className="monthly_amout">₹30,000/-👍</h3>
                    </div>
                  </Col>
                </Row>

                <Row className="px-3">
                  {petrolcardData2.map((item) => {
                    const { id, img, para, amt, text } = item;
                    return (
                      <>
                        <Col md={6} className="p-1 " key={id}>
                          <Card className="petrolgreen_Card p-2">
                            <div>
                              <ul className="nyx_list mb-2">
                                <li>
                                  <div className="mb-1">
                                    <img
                                      src={img}
                                      alt="Scoo Rental"
                                      className="icon"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <p className="petrol_para">
                                    {para} <b>{amt}</b>
                                    <span>{text}</span>
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </Card>
                        </Col>
                      </>
                    );
                  })}
                </Row>
                <Row className="px-3">
                  <Col md={12} className="p-1 ">
                    <Card className="petrol_Card p-2">
                      <div>
                        <ul className="nyx_list mb-2">
                          <li>
                            <div className="mb-1">
                              <img
                                src={Vector}
                                alt="Scoo Rental"
                                className="icon"
                              />
                            </div>
                          </li>
                          <li>
                            <p className="petrol_para">
                              Last-mile delivery partner monthly savings
                              <b>- Rs 30000/-</b>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default User;
