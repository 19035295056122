import React from "react";
import scooninjas from "../../../Assets/image/Assets/Scoo Ninja.png";
import frameLogo from "../../../Assets/image/Assets/Frame.png";
import "./whybecomescooninja.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import rentIcon from "../../../Assets/image/header/Icon.svg";
import Button from "react-bootstrap/Button";
// import icon from "../../../Assets/image/plan/btn.png";

const contentData = [
  {
    title: "Zero maintenance costs",
    para: "Quick charge your EV in 4 hours and leave the maintenance to us!",
  },
  {
    title: "Savings and more",
    para: "No petrol expenses, free service, and roadside assistance for every e-bike provided.",
  },
  {
    title: "Attractive incentives",
    para: "Work extra to earn extra! Payments and incentives are credited every week!",
  },
  {
    title: "Accidental insurance",
    para: "Scoo Ninjas are insured with accident insurance for roadside inconveniences.",
  },
  {
    title: "Scoo Ninja paid leave policy",
    para: "Every Scoo ninja rider gets one paid leave!",
  },
  {
    title: "Grow with Scoo",
    para: "Work with us for one year and you will be eligible to become the Team leader at Scoo Ninja!",
  },
];

const WhyBecomeScooNinja = () => {
  return (
    <Container fluid className="p-lg-5">
      <section>
        <Container>
          <Row className="mb-lg-4 header_explore  py-md-3 py-lg-2">
            <Col lg={3} className="p-0 m-0">
              <h4 className="header_line ">Why become a Scoo Ninja?</h4>
            </Col>
            <Col lg={9} className="py-1 p-0 m-0">
              <span>
                <hr />
              </span>
            </Col>
          </Row>

          <Row className="">
            {/* <Col md={4} className="p-0 m-0  center_Title">
              <img
                src={scooninjas}
                alt="no_logo"
                className="WhyBecomeScooNinja_Img p-2"
              />
            </Col> */}
            <Col lg={12} md={12} className="p-0 m-0 ">
              <Row>
                {contentData?.map((item, index) => (
                  <Col lg={4} className="mb-4  " key={index}>
                    <Card className="WHYSection_Card">
                      <div className="px-2 ">
                        <ul className="nyx_list mb-2">
                          <li className="mb-3 mt-2">
                            <div className="mb-1">
                              <img
                                src={frameLogo}
                                alt="no data"
                                className="icon_why"
                              />
                            </div>
                          </li>
                          <li>
                            <h3 className="card-content-title  bold_text">
                              {item?.title}
                            </h3>
                          </li>
                          <li>
                            <p className="card-content-para">{item?.para}</p>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  );
};

export default WhyBecomeScooNinja;
