import React, { useEffect, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { Col, Container, Row } from "react-bootstrap";

const CarouselFile = (props) => {
  const clubData = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(4);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(clubData);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);
  return (
    <Container className="">
      <Row>
        <Col lg={12}>
        <div
              style={{ width: props.width, height: props.height, margin: props.margin }}
              className={props.className}
            >
                 <Carousel
            slides={cards}
            goToSlide={goToSlide}
            offsetRadius={offsetRadius}
            showNavigation={showArrows}
            animationConfig={config.gentle}
            interval={6}
          />
                </div>
         
        </Col>
      </Row>
    </Container>
  );
};

export default CarouselFile;
