import React from "react";
import Blog from "../../Component/Blog/Blog";
import BlogBanner from "../../Component/BlogsPage/BlogBanner/BlogBanner";
import BlogCardContainer from "../../Component/BlogsPage/BlogCardContainer/BlogCardContainer";
import BlogOfTheDay from "../../Component/BlogsPage/BlogOfTheDay/BlogOfTheDay";
import CuratedReadsAndMostPopularContainer from "../../Component/BlogsPage/CuratedReadsAndMostPopularContainer/CuratedReadsAndMostPopularContainer";
import RelatedLinks from "../../Component/BlogsPage/RelatedLinks/RelatedLinks";
import RelatedPosts from "../../Component/BlogsPage/RelatedPosts/RelatedPosts";
import SearchBlog from "../../Component/BlogsPage/SearchBlog/SearchBlog";
import SignUpBlog from "../../Component/BlogsPage/SignUpBlog/SignUpBlog";
import "./blogs.css";

export default function Blogs() {
  return (
    <>
      <div className="BlogsContainer">
        <SearchBlog />
        <BlogOfTheDay />
        <BlogBanner />
        <BlogCardContainer />
        <CuratedReadsAndMostPopularContainer />
        {/* <RelatedPosts /> */}
        <Blog />
        {/* <SignUpBlog /> */}
        <RelatedLinks />
      </div>
    </>
  );
}
