import React from "react";
import { Container } from "react-bootstrap";
import "./relatedLinks.css";

export default function RelatedLinks() {
  return (
    <Container fluid className="mt-5">
      <section>
        <Container className="p-0">
          <div className="RelatedLinks_Title">Related Links</div>
          <hr />
          <div className="d-flex flex-row flex-wrap align-items-center justify-content-between RelatedLinksBox my-3">
            <span>Cloud Transformation</span>
            <span>Network Operations</span>
            <span>Centre Security</span>
            <span>Operations Centre</span>
            <span>Offshore Delivery</span>
            <span>Cloud Transformation</span>
            <span>Network Operations</span>
            <span>Centre Security</span>
            <span>Offshore Delivery</span>
            <span>Cloud Transformation</span>
            <span>Network Operations</span>
            <span>Centre Security</span>
            <span>Operations Centre</span>
            <span>Offshore Delivery</span>
            <span>Cloud Transformation</span>
            <span>Network Operations</span>
            <span>Centre Security</span>
            <span>Cloud Transformation</span>
            <span>Network Operations</span>
            <span>Centre Security</span>
            <span>Operations Centre</span>
            <span>Offshore Delivery</span>
            <span>Cloud Transformation</span>
            <span>Network Operations</span>
            <span>Centre Security</span>
            <span>Offshore Delivery</span>
            <span>Offshore Delivery</span>
          </div>
        </Container>
      </section>
    </Container>
  );
}
