import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import star from "../../Assets/image/Reviews/Star.png";
import { ReviewsData } from "../../data/dataApi";
import "./Revies.css";

const Reviews = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % ReviewsData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [activeIndex, ReviewsData.length]);

  return (
    <>
      <Container fluid className=" p-lg-5">
        <section>
          <Container className="Reviews_Container p">
            <Row>
              <Col md={7} sm={12} className="p-5 reviews_wrapper">
                <div className="star_wrapper mb-3">
                  <img src={star} alt="no logo" className="" />
                  <img src={star} alt="no logo" className="" />
                  <img src={star} alt="no logo" className="" />
                  <img src={star} alt="no logo" className="" />
                  <img src={star} alt="no logo" className="" />
                </div>
                <div className="mb-4">
                  {ReviewsData.map((element) => {
                    const { id, heading, brandLogo, name } = element;
                    return (
                      <>
                        <div key={id}>
                          {id === activeIndex ? (
                            <>
                              <h3 className="header_line">{heading}</h3>
                              <p>— {name}</p>

                              <div>
                                <img
                                  src={brandLogo}
                                  alt="Electric Vehicle Rental in Bangalore"
                                  className="revies_brand"
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>

                <div>
                  <ul className="d-flex p-0 mt-3">
                    {ReviewsData.map((data) => {
                      const { id, count } = data;
                      return (
                        <>
                          <li key={id}>
                            <div
                              className={
                                id === activeIndex ? "dot_icon" : "dot2_icon"
                              }
                            >
                              {count}
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </Col>

              <Col md={5} sm={6} className="review_Card  p-0 m-0">
                {ReviewsData.map((data) => {
                  const { id, ReviewSlider } = data;
                  return (
                    <div key={id}>
                      {id === activeIndex ? (
                        <img
                          src={ReviewSlider}
                          alt="about us"
                          className="review_image"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default Reviews;
