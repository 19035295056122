import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import scooty from "../../../Assets/image/Assets/sconyx.webp";
import animationData from "../../../data/datalotti.json";
import Lottie from "react-lottie";
import rentIcon from "../../../Assets/image/header/Icon.svg";

const LottiBanner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Container fluid className="lottiBanner_light  mb-0 ">
        <Container className="mt-lg-5 containerGeenBanner">
          <Row className="d-flex">
            <Col lg={6} md={7} sm={12} className="banner_wrapper ">
              <div className="">
                <h5 className=" banner_headbg lotti_headmax mb-3">
                  👍 Most reliable and eco-friendly E-scooter rentals platform
                  in Bengaluru
                </h5>
                <h3 className="">
                  <span className="banner_header">
                    Our e-bikes are{" "}
                    <span className="craft_banner">
                      crafted to make delivery a breeze
                    </span>
                  </span>
                  {/* <br></br>
                            <span className="craft_banner">{details}</span> */}
                </h3>
                <p className="banner_para">
                  “Ensuring comfort and convenience for all types of commuters,
                  while making errands greener"
                </p>
                <div className="d-flex p-lg-0 m-lg-0">
                  <Link
                    to="https://scoomerchandise.myshopify.com/collections/scoo-rental  "
                    className="link_text"
                    target="_blank"
                  >
                    <Button className="rent_btn  d-flex">
                      <div>
                        <img
                          src={rentIcon}
                          alt="Electric Vehicle Rental in Bangalore"
                          className="rent_img mt-1 me-2"
                        />
                      </div>

                      <div>
                        <spna className="">Rent now</spna>
                      </div>
                    </Button>
                  </Link>
                  <Link to="/Contact">
                    <Button className="enquire_btn mx-2">
                      <spna className="enquire_text">Enquire now</spna>
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6} md={5} sm={12} className="lotti_wrapper   p-0 m-0 ">
              <div className="lotti_img">
                <Lottie
                  options={defaultOptions}
                  height="100%"
                  width="100%"
                  className="lotticircle"
                />
                <img
                  src={scooty}
                  alt="Electric Vehicle Rental in Bangalore"
                  className="lotti_scooter"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default LottiBanner;
