import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import arrowIcon from "../../Assets/image/Journey/Shape.png";

import "./journey.css";

const Journey = () => {
  return (
    <>
      <Container fluid className="joury_container p-lg-5 ">
        <section>
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/BCL1JgGzlsM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                {/* <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/pp7AYnYPZR8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe> */}
                {/* <div className="journy_wrapper">
                  <div className="arrowIcon_outer">
                    <img src={arrowIcon} alt="no data" className="arrowIcon" />
                    
                  </div>
                  
                </div> */}
              </Col>

              <Col
                lg={6}
                md={6}
                sm={12}
                className="journey_wrapper journey_padding"
              >
                <h3 className="journey_header">Our Journey</h3>
                <p>
                  Scoo EV began its journey in 2021, within the first year in
                  the EV rental market, our quality products and service has
                  retained over 1000+ happy customers commuting each day in our
                  efficient and robust E-bikes
                </p>
                <p>
                  Our Electric bikes have covered over 5,00,000 hassle-free
                  Kilometres, Saving over 15,000 Kilograms of CO2 Emission since
                  its Incorporation, playing our hand in preventing Air
                  pollution, Global Warming and Ozone Layer Depletion. Our aim
                  is to revolutionize the daily commuting experience with 100%
                  electric and emission-free Electric Vehicles for every need,
                  from last-mile deliveries to daily commuters and leisurely
                  fun-loving E-bike enthusiasts in Bengaluru. Scoo EV is the
                  trusted EV rental partner for the A-list logistics brands such
                  as Dunzo, Flipkart, Zomato, Shadowfax and Swiggy. Our
                  Customers have continued to make deliveries uninterruptedly
                  with work-ethic and trust
                </p>
                {/* <p>
                  With each passing day, we lengthen our strides towards a
                  greener environment and a brighter future for all of humanity.
                  Our name is self-evident, backed up by our alliance with the
                  best logistics brand in the rental market. Scoo EV now
                  continues to extend our steady customer-service, road-side
                  assistance and the best infrastructure for electric vehicle
                  commuters in Bengaluru.
                </p> */}
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default Journey;
