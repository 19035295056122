import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Sparepartsaccessories from "../../Assets/image/service/Sparepartsaccessories.png";
import Batteryrepair from "../../Assets/image/service/Batteryrepair.png";
import MotorRepair from "../../Assets/image/service/MotorRepair.png";
import Chargerrepair from "../../Assets/image/service/Chargerrepair.png";
import Generalrepairs from "../../Assets/image/service/Generalrepairs.png";
import PeriodicMaintainance from "../../Assets/image/service/PeriodicScheduleMaintainance.png";

const OfferedServices = () => {
  return (
    <div className="offerd-servies">
      <Container
        style={{
          background: "white",
          padding: "2rem",
          color: "black",
        }}
      >
        <Row>
          <Col lg={4} md={6} sm={12}>
            <iframe
              width="100%"
              height="100%"
              src={"https://www.youtube.com/embed/_OlLyiHqk5o?si=xME09JOH_lvu80d3"}
              title="youtube-video"
            />
          </Col>
          <Col
            lg={8}
            md={6}
            sm={12}
            className="journey_wrapper journey_padding"
          >
            <h3 className="offered-hed">Offered Services</h3>
            <p>
              At Scoo EV, we specialize in repairing electric scooter (EV)
              models to ensure they are running smoothly and efficiently. Our
              experienced technicians are dedicated to providing top-notch
              service to keep your EV scooter in optimal condition. Below are
              some of the repair services we offer:
            </p>
            <div className="offered-services-card-wrapper">
              <Card className="offered-services-card">
                <div>
                  <img src={Batteryrepair} alt="Batteryrepair" />
                </div>
                <span>Battery Repair</span>
              </Card>
              <Card className="offered-services-card">
                <div>
                  <img src={MotorRepair} alt="MotorRepair" />
                </div>
                <span>Motor Repair</span>
              </Card>
              <Card className="offered-services-card">
                <div>
                  <img src={Chargerrepair} alt="Chargerrepair" />
                </div>
                <span>Charger Repair</span>
              </Card>
              <Card className="offered-services-card">
                <div>
                  <img src={Generalrepairs} alt="Generalrepairs" />
                </div>
                <span>General Repairs</span>
              </Card>
              <Card className="offered-services-card">
                <div>
                  <img
                    src={Sparepartsaccessories}
                    alt="Sparepartsaccessories"
                  />
                </div>
                <span>Spare parts & accessories</span>
              </Card>
              <Card className="offered-services-card">
                <div>
                  <img src={PeriodicMaintainance} alt="Sparepartsaccessories" />
                </div>
                <span>Periodic Schedule Maintainance</span>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OfferedServices;
