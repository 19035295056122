import React from "react";
import Serviebanner from "./servie-banner";
import "./service.css";
import Evservice from "./evservice";
import OfferedServices from "./OfferedServices";
import SoocoEvclub from "./SoocoEvclub";
import OurClintes from "./ourClintes";
import ScheduleaService from "./ScheduleaService";
import FAQs from "../FaqSection/FaqSection";
// 

const ServicePage = () => {
  return (
    <div>
      <Serviebanner />
      <Evservice />
      <OfferedServices />
      <SoocoEvclub />
      <OurClintes />
      <ScheduleaService />
      <FAQs />
    </div>
  );
};

export default ServicePage;
