import React from "react";
import { Button, Container } from "react-bootstrap";
import "./blogCardContainer.css";
import BlogCardOne from "./BlogCardOne";
import { blogs } from "../../../data/blogData";

export default function BlogCardContainer() {
  return (
    <Container fluid className="mt-5">
      <section>
        <Container className="p-0">
            <div className="d-flex flex-row flex-wrap gap-2 my-3">
              <Button className="BlogCardContainerBtn BlogCardContainerBtnGreen">
                Featured Posts
              </Button>
              <Button className="BlogCardContainerBtn BlogCardContainerBtnWhite">
                Benefits
              </Button>
              <Button className="BlogCardContainerBtn BlogCardContainerBtnWhite">
                Offers
              </Button>
              <Button className="BlogCardContainerBtn BlogCardContainerBtnWhite">
                EV Rentals
              </Button>
              <Button className="BlogCardContainerBtn BlogCardContainerBtnWhite">
                Jobs Opportunities
              </Button>
            </div>

            <div className="BlogCardContainer_HR" />

            <div className="BlogCardBox mt-4 gap-4">
              {blogs?.map((ele, index) => {
                return (
                  <div className="BlogCardBox_Div" key={index}>
                    <BlogCardOne
                      img={ele?.img}
                      title={ele?.title}
                      views={ele?.views}
                      time={ele?.time}
                    />
                  </div>
                );
              })}
            </div>
        </Container>
      </section>
    </Container>
  );
}
