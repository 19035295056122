export const ScooPageInfoOneTimeDateBox = [
  {
    icon: "Images/ScooPage/timeIcon.png",
    text: "₹49/- per hour",
  },
  {
    icon: "Images/ScooPage/timeIcon.png",
    text: "₹399/- per day",
  },
  {
    icon: "Images/ScooPage/dateIcon.png",
    text: "₹899/- per weekend",
  },
];

export const ScooPageIoTItems = [
  {
    icon: "Images/ScooPage/item1.png",
    title: "Usage Tracking",
    description:
      "Track - distance travelled, range, charging stats and down time",
  },
  {
    icon: "Images/ScooPage/item2.png",
    title: "Anti-Theft",
    description:
      "The location is being recorded every time the vehicle is in use",
  },
  {
    icon: "Images/ScooPage/item3.png",
    title: "Geo Fencing",
    description:
      "Feature to avoid the vehicle to move away from the specified marked area",
  },

  {
    icon: "Images/ScooPage/item4.png",
    title: "Low Battery Indicator",
    description: "Alerts are triggered when the battery level is low",
  },
  // {
  //   icon: "Images/ScooPage/item5.png",
  //   title: "Gps Tracking",
  //   description: "Vehicle movement tracking feature is provided",
  // },
  {
    icon: "Images/ScooPage/map.png",
    title: "Gps Tracking",
    description: "Vehicle movement tracking feature is provided",
  },
];

export const ScooPartnershipPlans = [
  {
    id: 1,
    title: "Pro Plan",
    descriptionBefNum: "Get",
    num: "₹2,412/-",
    descriptionAftNum: "per vehicle",
    benefits: [
      {
        icon: "Images/ScooPage/scooPageTick.png",
        num: "₹20 Lakh",
        description: "One time investment",
      },
      {
        icon: "Images/ScooPage/scooPageTick.png",
        num: "₹36.5 Lakh",
        description: "returns after 5.5 years",
      },
      {
        icon: "Images/ScooPage/scooPageTick.png",
        num: "18%",
        description: "returns per year",
      },
    ],
  },
  {
    id: 2,
    title: "Regular Plan",
    descriptionBefNum: "Get",
    num: "₹1,750/-",
    descriptionAftNum: "per vehicle",
    benefits: [
      {
        icon: "Images/ScooPage/scooPageTick.png",
        num: "₹20 Lakh",
        description: "One time investment",
      },
      {
        icon: "Images/ScooPage/scooPageTick.png",
        num: "₹28.8 Lakh",
        description: "returns after 5.5 years",
      },
      {
        icon: "Images/ScooPage/scooPageTick.png",
        num: "8%",
        description: "returns per year",
      },
    ],
  },
];
