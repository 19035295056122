import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ContactAddressData } from "../../../data/dataApi";
import "./ContactAddress.css";

const ContactAddress = () => {
  return (
    <>
      <Container fluid className=" p-lg-3 mb-2">
        <section>
          <Container>
            <Row className="d-flex  justify-content-center">
              {ContactAddressData.map((item) => {
                const { id, img, head, time, para, number, text } = item;
                return (
                  <>
                    <Col md={6} className="p-3 " key={id}>
                      <Card className="ContactAddress_Card py-3 px-4">
                        <div>
                          <ul className="nyx_list mb-0">
                            <li className="mb-5">
                              <img
                                src={img}
                                alt="data no"
                                className="icon"
                              ></img>
                            </li>
                            <li>
                              <h4 className="contactAddres_head">{head}</h4>
                            </li>
                            <li>
                              <h3 className="contactAddres_time">{time}</h3>
                            </li>

                            <li className="height_title">
                              <p className="contactAddres_para mb-0">{para}</p>
                              {number && (
                                <div className='contact_number2 mt-3 text-center'>
                                <p style={{color:'black', fontSize:'24px'}}> {text} </p>
                                <p style={{ fontSize:'24px'}}> {number} </p>
                                </div>
                          )}
                            </li>
                          </ul>
                          
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })}
             

            </Row>

            {/* <Row>
              <Col lg={12}>
                <div className="text-center">
                  <h4 className=" hering_head">Contact Number</h4>
                  <h4 className=" hering_head"> +91 77958 33836  , +91 77958 33840</h4>
                  <p className="hiring_para">Head office numbers will no longer be active ,Effective March 1, 2024</p>

                </div>
              
              </Col>
            </Row> */}
          </Container>
        </section>
      </Container>
    </>
  );
};

export default ContactAddress;
