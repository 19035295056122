export const termData = [
  {
    id: 1,
    para: "The website and applications operated and Services (defined below) provided by Sigrid Spectrum Consultants (“hereinafter referred to as the “Company” and defined below) are governed by these Terms of Use (hereinafter referred to as “Terms”). These Terms also establish the rights and duties of a User (defined below). These Terms are electronic documents and do not require any signatures (physical or digital) and by clicking on the acceptance button or by selecting the relevant checkbox, you shall be bound by these Terms. By using the website and/or application, the User signifies that they have read, understood and agreed to be bound by these Terms. The Company reserves the right to change these Terms at any time without notice. The User’s continued use of the website and/or application shall be construed as their acceptance of the revised Terms. The Company and the User shall hereinafter be individually referred to as “Party” and collectively referred to as “Parties”. ",
  },
  {
    id: 2,
    heading: "DEFINITIONS",
    describe: [
      {
        id: 1,
        des: "Applicable Laws: shall mean and include all statutes, enactments, acts of legislature or parliament, ordinances, rules, bye-laws, regulations, notiﬁcations, guidelines, policies, directions, directives and orders, requirement or other governmental restrictions or any similar form of a decision of, or determination by, or any interpretation, policy or administration of, any government, statutory authority, tribunal, board, court having jurisdiction over the matter in question in India.",
      },
      {
        id: 2,
        des: " Boundaries: shall strictly mean the areas and/or geographical boundaries decided by the Company, beyond which, the Users shall not allowed to operate the Vehicle.",
      },
      {
        id: 3,
        des: " Charge(s): shall mean the fees to be paid by the User including any refundable Security Deposit (defined herein below) towards availing the Services, as may be determined by the Company in its sole discretion, and the prices may vary from time to time.",
      },
      {
        id: 4,
        des: "Company: shall mean Sigrid Spectrum Consultants Pvt. Ltd. a company incorporated in India and having its registered oﬃce at No 2236, 23rd cross Banashankari 2nd stage, Bangalore rural, Karnataka 560070. ",
      },
      {
        id: 5,
        des: "  Confidential Information: shall mean and include all written, verbal, electronic, and/ or tangible information related to the Company’s business or affairs which is by its nature confidential, whether or not designated as confidential by the Company, communicated or made available in whatever form and medium to the Users for the purpose of or in connection with these Terms, including provisions of these Terms. ",
      },
      {
        id: 6,
        des: "  Confidential Information: shall mean and include all written, verbal, electronic, and/ or tangible information related to the Company’s business or affairs which is by its nature confidential, whether or not designated as confidential by the Company, communicated or made available in whatever form and medium to the Users for the purpose of or in connection with these Terms, including provisions of these Terms. ",
      },
      {
        id: 7,
        des: " Confidential Information: shall mean and include all written, verbal, electronic, and/ or tangible information related to the Company’s business or affairs which is by its nature confidential, whether or not designated as confidential by the Company, communicated or made available in whatever form and medium to the Users for the purpose of or in connection with these Terms, including provisions of these Terms.  ",
      },
      {
        id: 8,
        des: "  Confidential Information: shall mean and include all written, verbal, electronic, and/ or tangible information related to the Company’s business or affairs which is by its nature confidential, whether or not designated as confidential by the Company, communicated or made available in whatever form and medium to the Users for the purpose of or in connection with these Terms, including provisions of these Terms. ",
      },
      {
        id: 9,
        des: " Vehicle: shall mean any type of two wheeled vehicle or electric scooter that is part of a shared mobility system. ",
      },
    ],
  },
  {
    id: 3,
    heading: " INTERPRETATIONS ",
    describe: [
      {
        id: 1,
        des: " The words “include”, “including”, “inter alia” and “in particular” shall be construed as being by way of illustration or emphasis only and shall not be construed as, nor shall they take effect as, limiting the generality of any preceding words. ",
      },
      {
        id: 2,
        des: "The terms ‘hereof’, ’herein’, ’hereby’, ’hereto’ and derivative or similar words, unless followed by a reference to a specific provision of these Terms refer to these Terms in their entirety.  ",
      },
      {
        id: 3,
        des: "The headings given hereunder in these Terms are for convenience and do not affect the interpretation of these Terms.",
      },
      {
        id: 4,
        des: "Unless the contrary is expressly stated, no clause in these Terms limits the extent or application of another clause.",
      },
    ],
  },
  {
    id: 4,
    heading: "TERMS OF USE  ",
    describe: [
      {
        id: 1,
        des: "General Terms:  ",
      },
      {
        id: 2,
        des: "The Company shall permit the User to use the Vehicle in accordance with the present Terms and conditions herein. These Terms shall come into force upon access of the website and/or application and cease to apply upon return of Vehicle(s) rented and completion of payment for the same (if the User also avails Services of the Company).  ",
      },
      {
        id: 3,
        des: "The minimum and maximum period of time a User can book a Vehicle shall be in accordance with the same displayed on the website and/or application, which may be subject to change by the Company. The User shall operate only those Vehicles which are included in the User’s driver’s license class of vehicles.  ",
      },
      {
        id: 4,
        des: "The User shall be permitted to utilize the Services of the Company only upon uploading a digital copy of their valid driver’s license. Such driver’s license shall be stored in the Company’s database and deleted upon termination and/or deletion of the account. The User shall not upload a fake and/or forged driver’s license. The Company reserves the right to undertake all actions against the User in the event of such fake and/or forged driver’s license being submitted to it.  ",
      },
      {
        id: 5,
        des: "The User shall undertake to verify and as practicably as possible, test the condition of the Vehicle prior to the commencement of the rental period. The User shall use the Vehicle only after being fully satisﬁed about the condition of the Vehicle. The User hereby understands and accepts that Company, its associates and/or its affiliates shall not be liable for any loss of life or property due to the condition of the Vehicle or due to the condition of any associated accessories with it. ",
      },
      {
        id: 6,
        des: " The User shall, under no circumstances undertake or attempt to undertake the repair, maintenance and/or servicing of the Vehicles in their own capacity or by an unauthorized third party, unless expressly agreed to otherwise by the Company. ",
      },
      {
        id: 7,
        des: "The User shall not tamper with, hide or remove from the Vehicle, any logos, stickers or any other identifiers of the Company or third parties, regardless of such identifiers being proprietary or not. ",
      },
      {
        id: 8,
        des: "The User shall undertake to check the level of charging in the electric Vehicle and ensure that it is adequate before initiating the operation of the electric Vehicle. ",
      },
      {
        id: 9,
        des: "The Users shall undertake full responsibility for their personal belongings. The Company shall not be liable nor be held responsible for any loss or damage to such belongings under any circumstances. ",
      },
      {
        id: 10,
        des: "  The Users shall not use the Vehicle for commercial or political purposes, including but not limited to strikes, campaigns, rallies, protests or any other such activities comprising of a political or commercial agenda. ",
      },
      {
        id: 11,
        des: " The Users shall not use the Vehicle for participating in any motor racing events or any other competitions of similar nature, regardless of such events or competitions being legal and permissible under any Law of India.",
      },
      {
        id: 12,
        des: " The User shall comply with all Applicable Laws in the jurisdiction(s) in which the User is using the application and/or the Services of the Company, and in which the User’s operation of the Vehicle originates, terminates, and traverses.",
      },
      {
        id: 13,
        des: " The User shall ensure the safety of all equipment provided with the Vehicle, including but not limited to helmets, GPS navigation system, and any other attached or removable accessories provided by the Company. In the event the User misplaces or damages any equipment, the User shall be charged for the same at the discretion of the Company.",
      },
      {
        id: 14,
        des: "The User herein understands it shall not and shall ensure that the Vehicle is not parked in a no-parking zone or an unsupervised area. ",
      },
    ],
  },
  {
    id: 5,
    heading: "Operation of Vehicle(s):  ",
    describe: [
      {
        id: 1,
        des: "The User shall use and operate the Vehicle safely and prudently, such that all Applicable Laws and recommended road safety practices are abided by.   ",
      },
      {
        id: 2,
        des: "The User shall strictly operate the Vehicle within the Boundaries and shall also not operate the Vehicle in areas, access to which have not been granted to the general public.",
      },
      {
        id: 3,
        des: "The User shall be the sole renter and sole operator of the Vehicle. In no circumstances shall the User offer a third party the possession of or opportunity to operate the Vehicle, unless permitted by the Company in writing. ",
      },
      {
        id: 4,
        des: "The User shall not exceed the maximum weight limit assigned to the Vehicle, which is deﬁned for each type of Vehicle or 200 (two hundred) Kilograms, whichever is less. The User thus accepts that the Vehicle is intended for non-commercial or personal use including transportation of light goods only.  ",
      },
      {
        id: 5,
        des: "The User shall diligently abide by the speed limitation, traﬃc rules or directions of the police or other enforcement agencies. In the event of any violation of traffic rules including but not limited to over speeding, wrong parking, jumping a signal, riding with multiple pillions, breaking a red light at a crossing and toll violations, riding without a helmet, parking illegally, driving under the influence of alcohol, etc., the User shall pay all the ﬁnes / penalties and undertake all liability, costs and/or expenses incurred by such violation. The User herein shall also indemnify and hold harmless the Company for any and all costs or expense the Company may incur as a result of any breach of traffic rules or directions by the User as aforementioned. ",
      },
      {
        id: 6,
        des: "The User shall not retroﬁt or modify the Vehicles with any components that are not approved in writing by the Company. This includes but is not limited to tires, tubes, batteries and other functional components of the Vehicle or color, handlebar or any other aesthetic features of the Vehicle.  ",
      },
      {
        id: 7,
        des: "The User shall not take the Vehicle off-roads, or on roads that are not marked as normal driving roads on a road map and/or areas where the general public have not been granted access, except when the User has the requisite permission to enter such areas.  ",
      },
      {
        id: 8,
        des: "The User shall not ride with more than one person as a pillion.   ",
      },
      {
        id: 9,
        des: "The User shall not use the Vehicle for the purpose of transporting any dangerous materials/objects, including but not limited to weapons, knives, ﬁrearms, incendiary devices, chemicals or any other objects so deemed by a reasonable person of prudence as dangerous with potential to cause physical harm to or intimidate or otherwise harass others, including other Users or members of the public, whether or not the user intends to use such objects or substances to cause harm to or intimidate or otherwise harass.   ",
      },
      {
        id: 10,
        des: "The User shall refrain from operating the Vehicle in such manner which may result in excessive wear and tear, outside the scope of normal wear and tear as a consequence of use in tandem with these Terms. In the event the Company discovers such excessive use by the User, the Company shall be entitled to penalize and to recover from the User appropriate compensation towards repairs and/or services arising out of such excessive wear and tear.    ",
      },
    ],
  },
  {
    id: 4,
    heading: "SECURITY DEPOSIT ",
    describe: [
      {
        id: 1,
        des: "The User hereby understands that they shall be required to pay a Security Deposit prior to the booking of a Vehicle, which shall be refunded to the User, subject to deductions after adjusting the deposit against any negative balance or dues. ",
      },
      {
        id: 2,
        des: "The User hereby understands that they shall not be entitled to any interest on the Security Deposit whatsoever, even in the event of a delay in refund of the same .",
      },
      {
        id: 3,
        des: "Subject to the terms contained under this clause, the Company reserves the sole right to utilize the Security Deposit in any manner as it deems fit and the User shall not be entitled to object to any such usage of the same.   ",
      },
      {
        id: 4,
        des: "The User, upon completion of the trip or ride or booking as per the applicable rental period or return of the Vehicle in accordance with these Terms and subject to any pending payments, shall be refunded the Security Deposit within (Kindly insert the time period), upon receiving a request to that effect from the User.  ",
      },
      {
        id: 5,
        des: " In the event the User seeks to object a deduction in the Security Deposit, the User shall raise a query with the Company’s helpline, website, or application regarding the same.",
      },
      {
        id: 6,
        des: " Notwithstanding anything contained in these Terms, in the event the cost incurred by the Company arising out of the breach of these Terms and/or Laws by the User exceeds the Security Deposit amount, the Company shall be entitled and the User shall be obliged to pay such balance amount as may be necessary. ",
      },
    ],
  },
  {
    id: 4,
    heading: "RETURN OF VEHICLE(S) ",
    describe: [
      {
        id: 1,
        des: "The Parties hereby agree that the Vehicle shall be returned by the User, along with all the accessories at the date and time mentioned at the time of booking without any damage or modifications to the Vehicle. Failure to do so would attract a penalty based on the delay/damage. ",
      },
      {
        id: 2,
        des: "The Vehicle shall be returned to an authorized location of the Company. The authorized location may or may not be the same as pickup location as the Company may allow a User to pick up the Vehicle from the location of the earlier User of the vehicle.  ",
      },
      {
        id: 3,
        des: "In the event the Vehicle is not returned to an authorized location of the Company, the User shall be required to pay the cost of returning the Vehicle or the transportation fee incurred in transporting the Vehicle to such location. The User hereby understands that in certain cases, the pickup location may be out of service, permanently or temporarily, in which case, the User shall undertake to transport the Vehicle to the authorized location of the Company’s oﬃce address, whichever is nearest and convenient to the User. ",
      },
    ],
  },

  {
    id: 5,
    heading: "CANCELLATION OF BOOKING AND REFUND OF PAYMENT  ",
    describe: [
      {
        id: 1,
        des: " The User hereby understands that they shall not be entitled to raise a query for a refund in the event the booking is cancelled or modiﬁed by the User without the prior consent of the Company.",
      },
      {
        id: 2,
        des: "The User shall hereby cancel a booking using the same mode used to make the booking. The User shall be permitted to cancel bookings by calling the Company’s helpline number and such cancellation shall be deemed valid, subject to the User identifying themselves and providing adequate details regarding the booking. The Company shall, after verifying the User’s identity and booking details, cancel the booking and intimate the User of the same",
      },
      {
        id: 3,
        des: "The User shall be given a refund in the event a booking is cancelled by the Company due to unavailability of Vehicles. In the event the Company is unable to provide a Vehicle to the User due to events not within the reasonable control of the Company, which shall include but not be limited to such as accidents, damage to the Vehicle, servicing of the Vehicles, late handover by the previous User etc., the Company shall process a full refund of the entire amount of the booking through the original method of payment, which shall be processed within 8 to 15 working days. ",
      },

      {
        id: 4,
        des: "In the event of an actual or possible wrongful deduction and/or wrongful charge by the Company, the User shall undertake to raise a query through the Company’s website / application or helpline number. The Company shall, upon receiving such query, conduct internal investigation and verifications and if found bona fide, shall process the refund within 8 to 15 days.",
      },
      {
        id: 5,
        des: "In the event of a breakdown of, accident and/or damage to a Vehicle or any other event resulting in the termination of the Service, the Company shall not liable to reimburse any expenses borne by a User towards the cancellation of any third party expenses/events/commitments not associated to the Company or its Services. ",
      },
    ],
  },
  {
    id: 6,
    heading: "DAMAGE, THEFT OR LOSS OF VEHICLE ",
    describe: [
      {
        id: 1,
        des: " In the event of theft of the Vehicle or any component/part thereof, the User shall report the same to the Company immediately and ﬁle a complaint at the nearest police station. The User shall be held responsible for any discrepancy or misinformation, if any, in the ﬁling of the complaint and appropriate action may be taken by the concerned authorities, legal or otherwise. ",
      },
      {
        id: 2,
        des: " In the event of any damage to the Vehicle due to reasons attributable to the User or, subject to the permission of the Company under clause 3.2.3, by any third party to which User had granted access to operate the vehicle, the Company shall be entitled to charge an amount equal to the summation of the cost of repair of the Vehicle, the loss of revenue caused due to such damages, the transportation charges for the Vehicle and any other additional cost that may be incurred by the Company.",
      },
      {
        id: 3,
        des: "The User hereby understands that the Company shall not be responsible in any manner whatsoever in the event of any damage to the Vehicle, or injury to the User and/or pillion rider as a consequence of an accident or collision due to personal negligence or negligence of the User or negligence of any third party or any other reason not directly attributable to the Company. On the occurrence of such event, the User undertakes to fully cooperate with the Company and legal authorities and to such extent, file a first information report at the appropriate police station or provide the Company with a no-objection certificate. The User shall also not object to the Company retaining the original driver’s license of the User for insurance claiming purposes, if required, which will be returned after a minimum of 30 (thirty) days or such period as may be reasonably required, subject to fulfillment of insurance procedures and other requirements of the Company. ",
      },

      {
        id: 4,
        des: " In the event of any damage to, theft of or loss of the Vehicle or any component thereof, while being in the custody of the User, the User shall undertake the responsibility of such damage, theft, loss of the Vehicle. Any expenses incurred for repairing or replacing the Vehicle for such damages, theft, or loss shall be borne solely by the User, after duly informing and consulting with the Company.",
      },
      {
        id: 5,
        des: " The User shall liable for any and all costs incurred in the repair, recovery, and loss of any Vehicle or part thereof, resulting from any of the foregoing, up to the current damage fee, as set forth in the Annexure of this agreement, unless such costs are; (i) not resultant of actions and/or inactions attributable to the User, (ii) is directly resultant of actions and/or inactions of a third party, (iii) are reimbursed by the Vehicle’s insurer or (iv) the insurer of the other Vehicle is involved in the accident. ",
      },
      {
        id: 6,
        des: "Any and all summons or notices issued by police, courts of law and other authorities in relation to incidents, accidents and/or other acts concerning the Vehicle occurring during the possession and custody of the said Vehicle by the User, shall be intimated to the Company promptly and no later than one hour of such incident. The User shall also report in writing, the entire sequence of events in detail regarding such incident. In the event of an incident involving property damage to the person or property of any third party arising out of the operation of a Vehicle by a User, such User must ﬁle an oﬃcial police complaint and cooperate with the Company in obtaining the following information:",
      },
      {
        id: 7,
        des: "Date, time, place of accident and circumstances of the incident describing the immediate surrounding environment and Vehicle positions prior to the incident; ",
      },
      {
        id: 8,
        des: ". Information of the vehicles, including but not limited to their license plate numbers, their model number and year of purchase, their identiﬁcation number (serial number), and the insurance certiﬁcate number (with name, address and phone number of the insurance agent);",
      },
      {
        id: 9,
        des: "Contact information of the persons involved in the accident, including but not limited to their names, addresses, driver's license numbers and if the driver is not the owner, same information of the owner;",
      },
      {
        id: 10,
        des: "Contact information of witnesses, including but not limited to the name, addresses, and phone numbers.   ",
      },
      {
        id: 11,
        des: " The User shall cooperate with the Company and any other claims adjustment service in order to ascertain information pertaining to any incident/accident involving the Company’s Vehicle during the course of its use by the User. The User shall cooperate fully with the Company in the investigation and defense of any claims or lawsuit by or against the Company. The User's accounts may be suspended until such investigation has been concluded.",
      },
      {
        id: 12,
        des: " In the event, the Company determines that the loss incurred by the Company as a result of an accident of the Vehicle will exceed the insurance coverage of such Vehicle, the User shall be liable to pay the balance amount. In the event that the insurance policy is annulled as a consequence of the User’s negligence, including but not limited to rash driving, driving under the influence or any other such negligent conduct, the User shall undertake to pay the showroom repair cost of such Vehicle.",
      },
      {
        id: 13,
        des: "In the event of any accidents/damages, the liability with regard to any out-of-court settlement initiated or accomplished by the User with third parties without the prior written consent of the Company will be solely borne by such User. In the event of any damage to the Vehicle during the course of its use by the User, the Company shall be entitled to recover from the User an amount equal to the cost of repairing the Vehicle, to the extent that is not covered by the Vehicle’s insurers and additionally, the loss of revenue, if any to the Company, as a result thereof. ",
      },
      {
        id: 14,
        des: "The User herein understands that liability protection shall solely be afforded against any claims of third party bodily injury, death and/or property occurring in accidents where all vehicles involved are insured. Such liability protection shall exclude damage or loss of the third party’s vehicle and any claim made by the User or the User’s immediate family members who live with the member or are travelling with the member at the time of the incident. The liability protection shall only be afforded to such Users who operate the Vehicle in accordance with the permitted use as set forth in these Terms. ",
      },
      {
        id: 15,
        des: "The Parties herein understand that these Terms are intended for the beneﬁt of the User and the Company. No other party, including but not limited to third-party beneﬁciaries or other third parties, may claim rights hereunder. The Company shall not be liable to any third party for indirect, incidental, special or consequential damages arising from or related to these Terms or use of Vehicle and Services.  ",
      },
    ],
  },
  {
    id: 7,
    heading: "ELECTRIC VEHICLE(S) ",
    describe: [
      {
        id: 1,
        des: "The User hereby understands that electric Vehicles are subject to certain risks and in light of the same, the User hereby assumes all risk of personal injuries, damage, or failure of the Vehicle or system and all other losses or damages to themselves and others and to any property arising out of or as a result of the use of the Vehicle. ",
      },
      {
        id: 2,
        des: "The User agrees and acknowledges that electric Vehicles may not be available at all times as the same requires periodic charging of batteries in order to operate. In light of the same, the User hereby understands that the availability of electric Vehicles shall be subject to several factors, including but not limited to charge in the batteries, general operability condition of the Vehicle and prior bookings by other Users. ",
      },
      {
        id: 3,
        des: "The User hereby understands that the level of battery percentage remaining in the electric Vehicle shall decrease with use (over both time and distance), and such decrease in battery level shall result in a decrease of speed and other operational capabilities of the same, possibly resulting in a complete halt of all operational capabilities. ",
      },

      {
        id: 4,
        des: " The User hereby understands that a specific level of battery percentage in the electric Vehicle at the commencement of the rental period is not guaranteed. The User hereby agrees to not raise any complaints or demand any refund against the same.",
      },
      {
        id: 5,
        des: "The User hereby understands that the Company shall not guarantee the distance and/or time that the User may operate any electric Vehicle before it drains the battery completely. The User understands that the electric Vehicle may run out of charging or battery percentage and cease to operate at any time during the rental of the electric vehicle, including before reaching the desired destination of the User. In such an event, the User agrees not to raise any complaint or demands a refund from the Company against the same. ",
      },
      {
        id: 6,
        des: "The User hereby understands that the rate of loss of battery percentage during the use of the electric Vehicle is not guaranteed and will vary based on such Vehicle, road conditions, weather conditions and/or other factors affecting the operational capabilities of the same.",
      },
      {
        id: 7,
        des: "The User hereby understands that regardless of dispossessing the Vehicle, unless the rental period is ended following the instructions in the application, the rental period shall be deemed to be continuing. In case the User is not able to end the trip or the booking as per the rental period, the service center must be contacted immediately.",
      },
      {
        id: 8,
        des: "Any person who has provided the Company with false information or whose representations are determined to be false will be prohibited to avail any further Service from the Company and shall be barred from availing any Services from the Company in the future. The Company also reserves the right to initiate and undertake appropriate legal action against such User to the fullest extent of the law. ",
      },
    ],
  },
  {
    id: 8,
    heading: " LONG TERM RENTAL OF VEHICLE(S)  ",
    describe: [
      {
        id: 1,
        des: "In addition to all the Terms contained herein, all clauses under Clause 9 shall specifically be applicable to long term rentals, which shall include but not be limited to daily, weekly and monthly rentals.",
      },
      {
        id: 2,
        des: "The User agrees and acknowledges that electric Vehicles may not be available at all times as the same requires periodic charging of batteries in order to operate. In light of the same, the User hereby understands that the availability of electric Vehicles shall be subject to several factors, including but not limited to charge in the batteries, general operability condition of the Vehicle and prior bookings by other Users. ",
      },
      {
        id: 3,
        des: "The User hereby understands that the level of battery percentage remaining in the electric Vehicle shall decrease with use (over both time and distance), and such decrease in battery level shall result in a decrease of speed and other operational capabilities of the same, possibly resulting in a complete halt of all operational capabilities. ",
      },

      {
        id: 4,
        des: " The User hereby understands that a specific level of battery percentage in the electric Vehicle at the commencement of the rental period is not guaranteed. The User hereby agrees to not raise any complaints or demand any refund against the same.",
      },
      {
        id: 5,
        des: "The User hereby understands that the Company shall not guarantee the distance and/or time that the User may operate any electric Vehicle before it drains the battery completely. The User understands that the electric Vehicle may run out of charging or battery percentage and cease to operate at any time during the rental of the electric vehicle, including before reaching the desired destination of the User. In such an event, the User agrees not to raise any complaint or demands a refund from the Company against the same. ",
      },
      {
        id: 6,
        des: "The User hereby understands that the rate of loss of battery percentage during the use of the electric Vehicle is not guaranteed and will vary based on such Vehicle, road conditions, weather conditions and/or other factors affecting the operational capabilities of the same.",
      },
      {
        id: 7,
        des: "The User hereby understands that regardless of dispossessing the Vehicle, unless the rental period is ended following the instructions in the application, the rental period shall be deemed to be continuing. In case the User is not able to end the trip or the booking as per the rental period, the service center must be contacted immediately.",
      },
    ],
  },
  {
    id: 9,
    heading: "Booking and reservation:",
    describe: [
      {
        id: 1,
        des: "Subject to the User’s agreement to the Terms contained herein, the price list and other relevant conditions as set forth, the User may make reservations by specifying in advance the class of the Vehicle, the commencement date and time of rental, the Vehicle pick up location or the period of rental, which shall be a minimum of 30 days for monthly rentals, the Vehicle drop off location, and other rental conditions Renting Conditions. The reservation request of the User shall be deemed to be valid upon the Company receiving all details mentioned herein these Terms and payment of applicable Security Deposit amount prior to the booking.",
      },
      {
        id: 2,
        des: "The Company reserves the right to remotely immobilize the Vehicle or cancel the rental plan, in the event that the User is discovered to be or has attempted to breach any of these Terms or if there is a delay in the payment by the User’s end. The Vehicle may be restarted at the sole discretion of the Company only after the pending payment is received or the User assures the Company that there would not be any further breach of these Terms in the future. In the event the Company does not receive the pending payment, the Company shall be entitled to recover the same from the Security Deposit. The Company shall also be entitled to recover any costs from the aforementioned Security Deposit, towards costs incurred in transporting the Vehicle to the authorized locations of the Company. ",
      },
      {
        id: 3,
        des: "The Company shall, upon receipt of the reservation from the User, endeavor to comply, with such reservation request to the extent that the Vehicle is available for such rental within the Vehicles in possession of the Company.  ",
      },

      {
        id: 4,
        des: "In the event the reservation request of a specific Vehicle is not fulfilled due to the occurrence of an accident, theft, non-return, a recall, or natural disaster or any other event, which is not attributable to the Parties, the reservation shall be deemed to have been cancelled and the Company shall return to the User the reservation charges (if received any).",
      },
    ],
  },
  {
    id: 10,
    heading: "Rental charges and other levies:  ",
    describe: [
      {
        id: 1,
        des: " Rental Charges will be applicable on a monthly basis. The User agrees to book the Vehicle for a period of minimum 30 (thirty) days in case of availing the monthly plan.. ",
      },
      {
        id: 2,
        des: "The Vehicle shall be returned to an authorized location of the Company. The authorized location may or may not be the same as pickup location as the Company may allow a User to pick up the Vehicle from the location of the earlier User of the vehicle.  ",
      },
      {
        id: 3,
        des: "In the event the Vehicle is not returned to an authorized location of the Company, the User shall be required to pay the cost of returning the Vehicle or the transportation fee incurred in transporting the Vehicle to such location. The User hereby understands that in certain cases, the pickup location may be out of service, permanently or temporarily, in which case, the User shall undertake to transport the Vehicle to the authorized location of the Company’s oﬃce address, whichever is nearest and convenient to the User. ",
      },
    ],
  },

  {
    id: 5,
    heading: "CANCELLATION OF BOOKING AND REFUND OF PAYMENT  ",
    describe: [
      {
        id: 1,
        des: " The User hereby understands that they shall not be entitled to raise a query for a refund in the event the booking is cancelled or modiﬁed by the User without the prior consent of the Company.",
      },
      {
        id: 2,
        des: "The User shall hereby cancel a booking using the same mode used to make the booking. The User shall be permitted to cancel bookings by calling the Company’s helpline number and such cancellation shall be deemed valid, subject to the User identifying themselves and providing adequate details regarding the booking. The Company shall, after verifying the User’s identity and booking details, cancel the booking and intimate the User of the same",
      },
      {
        id: 3,
        des: "The User shall be given a refund in the event a booking is cancelled by the Company due to unavailability of Vehicles. In the event the Company is unable to provide a Vehicle to the User due to events not within the reasonable control of the Company, which shall include but not be limited to such as accidents, damage to the Vehicle, servicing of the Vehicles, late handover by the previous User etc., the Company shall process a full refund of the entire amount of the booking through the original method of payment, which shall be processed within 8 to 15 working days. ",
      },

      {
        id: 4,
        des: "In the event of an actual or possible wrongful deduction and/or wrongful charge by the Company, the User shall undertake to raise a query through the Company’s website / application or helpline number. The Company shall, upon receiving such query, conduct internal investigation and verifications and if found bona fide, shall process the refund within 8 to 15 days.",
      },
      {
        id: 5,
        des: "In the event of a breakdown of, accident and/or damage to a Vehicle or any other event resulting in the termination of the Service, the Company shall not liable to reimburse any expenses borne by a User towards the cancellation of any third party expenses/events/commitments not associated to the Company or its Services. ",
      },
    ],
  },
  {
    id: 6,
    heading: "DAMAGE, THEFT OR LOSS OF VEHICLE ",
    describe: [
      {
        id: 1,
        des: " In the event of theft of the Vehicle or any component/part thereof, the User shall report the same to the Company immediately and ﬁle a complaint at the nearest police station. The User shall be held responsible for any discrepancy or misinformation, if any, in the ﬁling of the complaint and appropriate action may be taken by the concerned authorities, legal or otherwise. ",
      },
      {
        id: 2,
        des: " In the event of any damage to the Vehicle due to reasons attributable to the User or, subject to the permission of the Company under clause 3.2.3, by any third party to which User had granted access to operate the vehicle, the Company shall be entitled to charge an amount equal to the summation of the cost of repair of the Vehicle, the loss of revenue caused due to such damages, the transportation charges for the Vehicle and any other additional cost that may be incurred by the Company.",
      },
      {
        id: 3,
        des: "The User hereby understands that the Company shall not be responsible in any manner whatsoever in the event of any damage to the Vehicle, or injury to the User and/or pillion rider as a consequence of an accident or collision due to personal negligence or negligence of the User or negligence of any third party or any other reason not directly attributable to the Company. On the occurrence of such event, the User undertakes to fully cooperate with the Company and legal authorities and to such extent, file a first information report at the appropriate police station or provide the Company with a no-objection certificate. The User shall also not object to the Company retaining the original driver’s license of the User for insurance claiming purposes, if required, which will be returned after a minimum of 30 (thirty) days or such period as may be reasonably required, subject to fulfillment of insurance procedures and other requirements of the Company. ",
      },

      {
        id: 4,
        des: " In the event of any damage to, theft of or loss of the Vehicle or any component thereof, while being in the custody of the User, the User shall undertake the responsibility of such damage, theft, loss of the Vehicle. Any expenses incurred for repairing or replacing the Vehicle for such damages, theft, or loss shall be borne solely by the User, after duly informing and consulting with the Company.",
      },
      {
        id: 5,
        des: " The User shall liable for any and all costs incurred in the repair, recovery, and loss of any Vehicle or part thereof, resulting from any of the foregoing, up to the current damage fee, as set forth in the Annexure of this agreement, unless such costs are; (i) not resultant of actions and/or inactions attributable to the User, (ii) is directly resultant of actions and/or inactions of a third party, (iii) are reimbursed by the Vehicle’s insurer or (iv) the insurer of the other Vehicle is involved in the accident. ",
      },
      {
        id: 6,
        des: "Any and all summons or notices issued by police, courts of law and other authorities in relation to incidents, accidents and/or other acts concerning the Vehicle occurring during the possession and custody of the said Vehicle by the User, shall be intimated to the Company promptly and no later than one hour of such incident. The User shall also report in writing, the entire sequence of events in detail regarding such incident. In the event of an incident involving property damage to the person or property of any third party arising out of the operation of a Vehicle by a User, such User must ﬁle an oﬃcial police complaint and cooperate with the Company in obtaining the following information:",
      },
      {
        id: 7,
        des: "Date, time, place of accident and circumstances of the incident describing the immediate surrounding environment and Vehicle positions prior to the incident; ",
      },
      {
        id: 8,
        des: ". Information of the vehicles, including but not limited to their license plate numbers, their model number and year of purchase, their identiﬁcation number (serial number), and the insurance certiﬁcate number (with name, address and phone number of the insurance agent);",
      },
      {
        id: 9,
        des: "Contact information of the persons involved in the accident, including but not limited to their names, addresses, driver's license numbers and if the driver is not the owner, same information of the owner;",
      },
      {
        id: 10,
        des: "Contact information of witnesses, including but not limited to the name, addresses, and phone numbers.   ",
      },
      {
        id: 11,
        des: " The User shall cooperate with the Company and any other claims adjustment service in order to ascertain information pertaining to any incident/accident involving the Company’s Vehicle during the course of its use by the User. The User shall cooperate fully with the Company in the investigation and defense of any claims or lawsuit by or against the Company. The User's accounts may be suspended until such investigation has been concluded.",
      },
      {
        id: 12,
        des: " In the event, the Company determines that the loss incurred by the Company as a result of an accident of the Vehicle will exceed the insurance coverage of such Vehicle, the User shall be liable to pay the balance amount. In the event that the insurance policy is annulled as a consequence of the User’s negligence, including but not limited to rash driving, driving under the influence or any other such negligent conduct, the User shall undertake to pay the showroom repair cost of such Vehicle.",
      },
      {
        id: 13,
        des: "In the event of any accidents/damages, the liability with regard to any out-of-court settlement initiated or accomplished by the User with third parties without the prior written consent of the Company will be solely borne by such User. In the event of any damage to the Vehicle during the course of its use by the User, the Company shall be entitled to recover from the User an amount equal to the cost of repairing the Vehicle, to the extent that is not covered by the Vehicle’s insurers and additionally, the loss of revenue, if any to the Company, as a result thereof. ",
      },
      {
        id: 14,
        des: "The User herein understands that liability protection shall solely be afforded against any claims of third party bodily injury, death and/or property occurring in accidents where all vehicles involved are insured. Such liability protection shall exclude damage or loss of the third party’s vehicle and any claim made by the User or the User’s immediate family members who live with the member or are travelling with the member at the time of the incident. The liability protection shall only be afforded to such Users who operate the Vehicle in accordance with the permitted use as set forth in these Terms. ",
      },
      {
        id: 15,
        des: "The Parties herein understand that these Terms are intended for the beneﬁt of the User and the Company. No other party, including but not limited to third-party beneﬁciaries or other third parties, may claim rights hereunder. The Company shall not be liable to any third party for indirect, incidental, special or consequential damages arising from or related to these Terms or use of Vehicle and Services.  ",
      },
    ],
  },
  {
    id: 7,
    heading: "ELECTRIC VEHICLE(S) ",
    describe: [
      {
        id: 1,
        des: "The User hereby understands that electric Vehicles are subject to certain risks and in light of the same, the User hereby assumes all risk of personal injuries, damage, or failure of the Vehicle or system and all other losses or damages to themselves and others and to any property arising out of or as a result of the use of the Vehicle. ",
      },
      {
        id: 2,
        des: "The User agrees and acknowledges that electric Vehicles may not be available at all times as the same requires periodic charging of batteries in order to operate. In light of the same, the User hereby understands that the availability of electric Vehicles shall be subject to several factors, including but not limited to charge in the batteries, general operability condition of the Vehicle and prior bookings by other Users. ",
      },
      {
        id: 3,
        des: "The User hereby understands that the level of battery percentage remaining in the electric Vehicle shall decrease with use (over both time and distance), and such decrease in battery level shall result in a decrease of speed and other operational capabilities of the same, possibly resulting in a complete halt of all operational capabilities. ",
      },

      {
        id: 4,
        des: " The User hereby understands that a specific level of battery percentage in the electric Vehicle at the commencement of the rental period is not guaranteed. The User hereby agrees to not raise any complaints or demand any refund against the same.",
      },
      {
        id: 5,
        des: "The User hereby understands that the Company shall not guarantee the distance and/or time that the User may operate any electric Vehicle before it drains the battery completely. The User understands that the electric Vehicle may run out of charging or battery percentage and cease to operate at any time during the rental of the electric vehicle, including before reaching the desired destination of the User. In such an event, the User agrees not to raise any complaint or demands a refund from the Company against the same. ",
      },
      {
        id: 6,
        des: "The User hereby understands that the rate of loss of battery percentage during the use of the electric Vehicle is not guaranteed and will vary based on such Vehicle, road conditions, weather conditions and/or other factors affecting the operational capabilities of the same.",
      },
      {
        id: 7,
        des: "The User hereby understands that regardless of dispossessing the Vehicle, unless the rental period is ended following the instructions in the application, the rental period shall be deemed to be continuing. In case the User is not able to end the trip or the booking as per the rental period, the service center must be contacted immediately.",
      },
      {
        id: 8,
        des: "Any person who has provided the Company with false information or whose representations are determined to be false will be prohibited to avail any further Service from the Company and shall be barred from availing any Services from the Company in the future. The Company also reserves the right to initiate and undertake appropriate legal action against such User to the fullest extent of the law. ",
      },
    ],
  },
  {
    id: 8,
    heading: " LONG TERM RENTAL OF VEHICLE(S)  ",
    describe: [
      {
        id: 1,
        des: "In addition to all the Terms contained herein, all clauses under Clause 9 shall specifically be applicable to long term rentals, which shall include but not be limited to daily, weekly and monthly rentals.",
      },
      {
        id: 2,
        des: "Subject to availability, the User may at their own discretion insure the ride to the extent of acquiring third party insurance and/or pillion rider insurance, by opting for and paying a nominal insurance fee towards the same prior to the booking of a Vehicle. Such insurance cost shall be added to the total price of rental Charges of the booking",
      },
      {
        id: 3,
        des: "The User hereby understands that the rental Charge is the price for the use of the Vehicle under the agreed upon Rental Conditions and these Terms, which the Company reserves the right and sole discretion to modify at any given point of time, to be applicable on new reservation requests. Any additional payments, including but not limited to any charges, levies, GST or any other forms of statutory or non-statutory payments shall be in addition to the rental Charge, ",
      },

      {
        id: 4,
        des: "The User hereby agrees to pay the rental Charges in advance against the booking of the Vehicle. The advance payment shall be non-refundable, in case the User terminates the monthly rental plan before the minimum period of 30 days. In case of renewal of any rental plan, the User agrees to make the payment as per the new rental in advance. ",
      },
      {
        id: 5,
        des: "In the event the User is in possession of the Vehicle for a period exceeding the duration agreed upon in the Rental Conditions, the User shall be charged for such use and possession on a pro-rata basis for such period. ",
      },
      {
        id: 6,
        des: " In the event that the User seeks to renew the rental agreement, the User may do so by paying in advance the rental Charges for the next month. ",
      },
    ],
  },
  {
    id: 11,
    heading: "Documents to be provided: ",
    describe: [
      {
        id: 1,
        des: "The User shall deposit their valid driver’s license and a government issued Aadhar Card upon collecting the Vehicle from the Company. The User hereby understands that the procured documents shall remain in the possession of the Company until the Vehicle is returned by the User in accordance with and in a physical condition as detailed under these Terms.  ",
      },
      {
        id: 2,
        des: "In the event, the Company discovers that the User has defaulted or attempted to default any of these Terms, including but not limited to, damage to the Vehicle, unauthorised extension of rental period and other such terms, the Company shall be entitled to use a portion or the entirety of the Security Deposit towards adjusting such costs, expenses and/or liabilities incurred by it, as a consequence of the User’s default.",
      },
      {
        id: 3,
        des: "In the event the User is unable to produce and deposit the documents specified in this clause during the collection of the designated Vehicle, the Company shall be entitled to cancel the rental contract and in any such event, the User shall have no right to claim a refund of the monies paid towards booking of the Vehicle. ",
      },
    ],
  },
  {
    id: 12,
    heading: "INTELLECTUAL PROPERTY RIGHTS   ",
    describe: [
      {
        id: 1,
        des: " The User hereby acknowledges that the Company shall own all the Intellectual Property rights subsisting or used in connection with the Company, its website, application and/or Services, including but not limited to any patents, trademarks, logos, domain names or copyright as well as any derivative works (hereinafter referred to as “Intellectual Property”).  ",
      },
      {
        id: 2,
        des: " The User hereby understands that third-party trademarks may appear on the website and/or application and all rights therein are reserved to the respective owners of such trademarks.",
      },
      {
        id: 3,
        des: "The User hereby agrees to not post, publish, copy, replicate or reproduce any content, material, name including the logo and/or any Intellectual Property of the Company through paper or in any electronic form. ",
      },
      {
        id: 4,
        des: "The User herein shall not apply and/or contest the ownership of such Intellectual Property owned by the Company. Nothing contained in these Terms shall be construed to affect a transfer, license or assignment of the aforementioned Intellectual Properties or rights contained therein from one Party to the other. ",
      },
      {
        id: 5,
        des: "The User hereby understands that the Company has merely granted permission to access and use the website and/or application and such right does not amount to a right or entitlement over any Intellectual Property rights. ",
      },
      {
        id: 6,
        des: " The User hereby agrees not to market, sell, replicate, reverse engineer, decompile or attempt to do any of the aforementioned acts to any of the Intellectual Property of the Company. The User also understands that they are prohibited from accessing, searching or downloading the third-party content on the website and/or application through the use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by the Company or other generally available third party web browsers. ",
      },
      {
        id: 7,
        des: " The User hereby understands and accepts that any breach of the terms contained in this clause shall be considered as a material breach of these Terms and the Company shall have the right to terminate this engagement with immediate effect or seek such other legal remedies available under the law.",
      },
      {
        id: 8,
        des: " The User’s obligations detailed under this clause shall survive the termination of their engagement with the Company.",
      },
    ],
  },
  {
    id: 13,
    heading: "RESTRICTIONS   ",
    describe: [
      {
        id: 1,
        des: "The User hereby understands and agrees to comply with the following restrictions on use of the website, application and/or Services of the Company:   ",
      },
      {
        id: 2,
        des: "The User shall refrain from using this website and/or application in any way that impacts User access to the website and/or application. ",
      },
      {
        id: 3,
        des: "The User shall refrain from using the Website and/or application in contrary to Applicable Laws and regulations, or in a way that causes, or may cause, harm to the website and/or application, or to any person or business entity; ",
      },
      {
        id: 4,
        des: "The User shall refrain from engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to the website and/or application, or while using the website and/or application; ",
      },
      {
        id: 5,
        des: "The User shall refrain from using the website and/or application to engage in any advertising or marketing;  ",
      },
      {
        id: 6,
        des: " The User shall not stimulate or attempt to stimulate or duplicate any of the appearance, features or functionality of the website and/or application.  ",
      },
      {
        id: 7,
        des: " The User shall refrain from impersonating any other person/entity. ",
      },
      {
        id: 8,
        des: " The User shall refrain from using any of the Company's information or content for the benefit of any competing business. ",
      },
      {
        id: 9,
        des: "The User shall refrain from altering or attempting to alter the features, functions or functionality of the website and/or application in any manner. ",
      },
      {
        id: 10,
        des: " The User shall refrain from altering, reverse engineering, decompiling or otherwise disseminate any of the software forming a part of the website and/or application.   ",
      },
      {
        id: 11,
        des: " The User shall refrain from selling, sublicensing and/or otherwise commercializing any material hosted on the website and/or application of the Company. ",
      },
      {
        id: 12,
        des: " The User shall refrain from publicly performing and/or showing any material hosted on the website and/or application of the Company;  ",
      },
      {
        id: 13,
        des: " The User shall refrain from using the website and/or application in any way that is, or may be, damaging to such website, application and/or the good repute of the Company and its affiliates;",
      },
      {
        id: 14,
        des: "The User shall not alter or attempt to alter or remove any of the proprietary markings or content from the website and/or application including but not limited to any Marks, information, other proprietary marks, Intellectual Property rights or any content present on the website and/or application. ",
      },
      {
        id: 15,
        des: " The User hereby understands that certain areas of the website and/or application are restricted from access to the User by the Company. The Company reserves the sole right and discretion to further restrict access to the User and the User hereby agrees to not access or attempt to access such restricted sections of the website and/or application.",
      },
      {
        id: 16,
        des: " In the event the Company discovers that the User has undertaken or attempted to undertake any of the actions under this clause, the Company shall be entitled to terminate any ongoing relationship with the User and reserves the right to undertake appropriate legal action against the User.",
      },
    ],
  },
  {
    id: 14,
    heading: "INDEMNITY AND LIMITATION OF LIABILITY ",
    describe: [
      {
        id: 1,
        des: " In no event shall the Company, nor any of its oﬃcers, directors and employees, be liable to the User for anything arising out of or in any way connected with the User’s use of the website, application and/or Services, whether such liability is under contract, tort or otherwise, and the Company , including its oﬃcers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of the website, application and/or Services. ",
      },
      {
        id: 2,
        des: "The Parties hereby agree that all risks associated with the User, Vehicle and operation of the same during the custody and possession of the Vehicle with the User, shall be the sole responsibility of the User. In the event where the Vehicle is operated by the User in a manner and fashion that is in violation of any Applicable Laws or these Terms, the User shall hereby assume absolute liability and indemnify the Company, its associates and affiliates to the full extent, against any all statutory and other liabilities incurred by them.",
      },
      {
        id: 3,
        des: "In the event the User violates any Applicable Law, including but not limited to traffic laws, the Company shall not be responsible to legally and/or financially aid the User in any loss or damage to the Vehicle, and the User shall personally be liable for all the loss or damages caused.  ",
      },
      {
        id: 4,
        des: "The User hereby agrees to indemnify to the fullest extent the Company from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms. ",
      },
      {
        id: 5,
        des: " The Company shall not be liable to refund any amount against the unused hours in the rental period, in the event the User meets with an accident. The Company shall also not be liable to incur any costs expendable by the User arising out of such accident. ",
      },
      {
        id: 6,
        des: "  In the event the Company is made liable under these Terms, such liability shall not exceed the total fees paid by the User in relation to the Services.  ",
      },
    ],
  },
  {
    id: 15,
    heading: "PRIVACY POLICY  ",
    describe: [
      {
        id: 1,
        des: " The User hereby understands that in the pursuit of providing its Services to its best possible ability, the Company stores and utilizes certain information of the User. ",
      },
      {
        id: 2,
        des: " In light of the same, the User is required to read and understand the privacy policy before accessing the website and/or application to understand the data practices including without limitation, collection, recording, storing, disclosing and purging of your data by the Company for the purpose of rendering the Services.",
      },
      {
        id: 3,
        des: "Kindly find linked our privacy policy and cookie policyfor your convenience. The User hereby understands that by accepting these Terms, it shall deem automatic acceptance and agreement to the privacy and cookie policies by the User.  ",
      },
    ],
  },

  {
    id: 16,
    heading: "THIRD PARTY SERVICES ",
    describe: [
      {
        id: 1,
        des: " The User hereby understands that the website and/or application of the Company, from time to time, may contain some links to third party websites and/or services that are not owned or controlled by the Company. Notwithstanding anything contained hereinabove, the User specifically agrees that they shall be bound by the terms and conditions and privacy policy of such third-parties and the Company shall in no manner whatsoever be held liable for these policies or any loss that you may incur as a result of such policies implemented by such third-parties. ",
      },
    ],
  },

  {
    id: 17,
    heading: "REPRESENTATIONS & WARRANTIES ",
    describe: [
      {
        id: 1,
        des: " The User hereby understands that the right to commission, manage or operate, the Vehicle exclusively belongs to the Company and that the User is not entitled to transfer the rights and obligations accepted by them by acceptance of these Terms. The User hereby warrants not undertake any action claiming ownership over the Vehicles or transfer the rights and obligations accepted by them by accepting these Terms.  ",
      },
      {
        id: 2,
        des: " The User hereby understands that by using any of the applications, website or the Company’s Services, the User expressly represents and warrants that he is legally entitled to enter into these Terms. The User also understands and agrees not to avail the Services of the Company, in the event the User accesses or attempts to avail the Services from a jurisdiction that restricts the use of the Service because of age, or restricts the ability to enter into agreements such as these Terms.  ",
      },
      {
        id: 3,
        des: " The User hereby represents, warrants and covenants that all information provided by it is true and correct and that no misinterpretations or misleading statements have been made. The User further represents, warrants and covenants that it satisﬁes the eligibility criteria set out in these Terms has all requisite competence, power and authority to accept these Terms and the conditions herein and to perform its obligations hereunder and it will perform its obligations and fulﬁl its responsibilities under these Terms in a manner that complies with Applicable Laws. ",
      },
      {
        id: 4,
        des: "  Notwithstanding anything contained in these Terms, the Services and application are not available to minors (persons under the age of 18) under any circumstances. By using the application or the Service, the User represents and warrants that he/she has the right, authority and capacity to enter into these Terms and to abide by the terms and conditions herein.",
      },
      {
        id: 5,
        des: "  The User hereby warrants to operate, utilize and possess the Vehicle in accordance with the Applicable Laws and in accordance with these Terms.",
      },
      {
        id: 6,
        des: "The User warrants to undertake the responsibility to maintain the Vehicle to the same standard in which the Vehicle is supplied to the User and shall also undertake to comply with all the additional care measures recommended by the Company. ",
      },
      {
        id: 7,
        des: " The User’s participation in using the Services, website and/or the application is for the User's sole, personal use. The User undertakes to not authorize others to use their User account with the Company and shall not assign or otherwise transfer their User account to any other person or entity. ",
      },
      {
        id: 8,
        des: " The User hereby agrees to comply with all Applicable Laws in the jurisdiction(s) in which the Users are using the application, website and/or Services, and in which the User’s ride/ trip originates, terminates, and traverses. ",
      },
      {
        id: 9,
        des: " The User hereby warrants that they have the requisite experience and skill in operating the Vehicle and are capable of judging conditions in the occurrence of heavy rains, storms, strikes and such other conditions that may arise. In light of the same, the User shall assume all risk and liability arising from such judgment and decision to operate the Vehicle. ",
      },
    ],
  },
  {
    id: 18,
    heading: "TERMINATION ",
    describe: [
      {
        id: 1,
        des: "The Company reserves the right to terminate the User’s access to any of the Services provided and/or stop/control the User’s ability to use the Services effective immediately if found that User is in breach of any of these Terms.  ",
      },
      {
        id: 2,
        des: " The provisions of these Terms, which by their nature and content, must survive the completion, rescission, termination or expiration of this Terms in order to achieve the fundamental purposes of this Terms shall so survive and continue to bind the Parties for a period of (Subscription period availed) after the termination of these Terms.  ",
      },
    ],
  },
  {
    id: 19,
    heading: "MISCELLANEOUS",
    describe: [
      {
        id: 1,
        des: "MISCELLANEOUS  ",
      },
      {
        id: 2,
        des: "  Nothing contained in these Terms shall be construed to imply a relationship between the Parties in the nature of a joint venture, partnership or any other form of business affiliation of similar nature. The User herein is a mere customer availing Services from the Company against a consideration.  ",
      },
      {
        id: 3,
        des: "Ownership:  ",
      },
      {
        id: 4,
        des: " The Parties hereby agree that the Company shall exclusively commission, manage, operate and maintain the Vehicles and also file for and claim any and all insurance in case of any damage, including but not limited to theft or destruction of the Vehicle.   ",
      },
    ],
  },
];
