import React from "react";
import B2b from "../Component/B2B/B2b";
import Banner from "../Component/Banner/Banner";
import Blog from "../Component/Blog/Blog";
import FaqSection from "../Component/FaqSection/FaqSection";
import Journey from "../Component/Journey/Journey";
import Mession from "../Component/Messionversion/Mession";
import NesLetter from "../Component/NewsLetterSection/NesLetter";
import Order from "../Component/OrderSection/Order";
import PlanSection from "../Component/PlanSection/PlanSection";
import RentBanner from "../Component/RentBannerSection/RentBanner";
import Reviews from "../Component/Reviews/Reviews";
import ScooWork from "../Component/ScooWork/ScooWork";
import Vehicles from "../Component/Vehicles/Vehicles";
import HomeeBikeBangalore from "../Component/HomeeBikeBangalore/HomeeBikeBangalore";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Electric Vehicle Rental in Bangalore | EV Rentals - ScooEV
        </title>
        <meta
          name="description"
          content="ScooEV offers everyone hassle free electric vehicle rental in Bangalore, E-Bike, EV rentals in Bangalore, self-drive electric car rental in Bangalore Enquiry Now."
        />
        <meta
          name="keywords"
          content="Electric Bike Rental in Bangalore, E-Bike Rental in Bengaluru, Electric Bikes on Rental Near Me, Ather Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore,Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me. "
        />
      </Helmet>
      <Banner />
      <Journey />
      <Mession />
      <Vehicles />
      <RentBanner />
      <PlanSection />
      <B2b />
      <Reviews />

      <FaqSection />
      <HomeeBikeBangalore/>
      {/* <Blog /> */}
      {/* <NesLetter /> */}

      {/* <ScooWork /> */}
      {/* <Order /> */}
    </>
  );
};

export default Home;
