import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./club.css";
import { Link } from "react-router-dom";
import rentIcon from "../../Assets/image/header/Icon.svg";

const ClubBanner = () => {
  return (
    <>
      <Container fluid className="letScoo_bg mt-5">
        <Row className="mt-5">
          <Col lg={12} md={12} sm={12} className="ScooPageBanner_Info ">
            <div className="Scoo-club">
              <h3 className="scoo-club-heading">
                Scoo Club is an Elite EV Rental Membership
              </h3>
              <h6>
                Scoo Club provides you a platform to enroll as a member of this
                Elite Club.
              </h6>
              <p>
                Through this membership you get to enjoy the benefit of having
                luxury EV bikes delivered to your doorstep.
              </p>
              <Link
                to="https://scooev.com/Contact"
                className="link_text1 mb-5"
                target="_blank"
              >
                <Button className="club-btn">
                  <img src={rentIcon} alt="no logo" className="rent_img" />
                  <spna className=""> Contact us now!</spna>
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClubBanner;
