import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import rentIcon from "../../Assets/image/header/Icon.svg";
import Button from "react-bootstrap/Button";
import icon from "../../Assets/image/plan/btn.svg";
import "./plan.css";
import { Link } from "react-router-dom";

const PlanSection = () => {
  return (
    <>
      <Container fluid className="plan_container p-lg-5">
        <section>
          <Container>
            <Row className="mb-lg-5 header_explore">
              <Col lg={7} className="p-0 m-0">
                <div>
                  <h4 className=" d-flex plan_head">
                    Scoo EVs subscription plans are tailor-made to fit your
                    specific preference, needs and budget. Choose from our
                    customer-centric subscription plans to rent the best
                    e-scooters in the market
                  </h4>
                </div>
              </Col>
              <Col lg={5} className="planbefore_line">
                <span>
                  <hr />
                </span>
              </Col>
            </Row>

            <Row className="mb-4 plancard_row">
              <Col lg={3} md={5} className="">
                <Card className="plan_Card ">
                  <div className="ourtery_Wrapper">
                    <h5 className="ouarterly_Title">Quarterly</h5>
                    <h1 className="amount_Title">₹5900</h1>
                    <p className="month_title">per month</p>
                    <h5 className="ouarterly_Title">NYX /Similar bike</h5>
                  </div>
                  <div className="rent_btns">
                    <Button className="rent_btn mx-3">
                      <img
                        src={rentIcon}
                        alt="Electric Vehicle Rental in Bangalore"
                        className="rent_img "
                      />
                      <Link
                        to="https://scooev.com/ScooRentals\"
                        className="link_text"
                        target="_blank"
                      >
                        <spna className=""> Rent now</spna>
                      </Link>
                    </Button>
                  </div>
                </Card>
              </Col>

              <Col lg={3} md={5} className="">
                <Card className="plan_Card">
                  <div className="ourtery_Wrapper">
                    <h5 className="ouarterly_Title">Half Yearly</h5>
                    <h1 className="amount_Title">₹5700/-</h1>
                    <p className="month_title">per month</p>
                    <h5 className="ouarterly_Title">NYX /Similar bike</h5>
                  </div>
                  <div className="rent_btns">
                    <Button className="rent_btn mx-3">
                      <img
                        src={rentIcon}
                        alt="Electric Vehicle Rental in Bangalore"
                        className="rent_img "
                      />
                      <Link
                        to="https://scooev.com/ScooRentals\"
                        className="link_text"
                        target="_blank"
                      >
                        <spna className=""> Rent now</spna>
                      </Link>
                    </Button>
                  </div>
                </Card>
              </Col>

              <Col lg={3} md={5} className="  populare_card">
                <div className="popular_btn">
                  <div>Most popular</div>
                </div>
                <Card className="plan_Card">
                  <div className="ourtery_Wrapper">
                    <h5 className="ouarterly_Title">Yearly</h5>
                    <h1 className="amount_Title">₹5500/-</h1>
                    <p className="month_title">per month</p>
                    <h5 className="ouarterly_Title">NYX /Similar bike</h5>
                  </div>
                  <div className="rent_btns">
                    <Button className="rent_btn mx-3">
                      <img
                        src={rentIcon}
                        alt="Electric Vehicle Rental in Bangalore"
                        className="rent_img "
                      />
                      <Link
                        to="https://scooev.com/ScooRentals\"
                        className="link_text"
                        target="_blank"
                      >
                        <spna className=""> Rent now</spna>
                      </Link>
                    </Button>
                  </div>
                </Card>
              </Col>

              <Col lg={3} md={5} className="luxe_wrapper">
                <div className="scoo_btn ">
                  <div>
                    <img
                      src={icon}
                      alt="Electric Vehicle Rental in Bangalore"
                      className=""
                    />
                  </div>
                </div>

                <Card className="plan_Card">
                  <div className="ourtery_Wrapper">
                    <h5 className="ouarterly_Title">Scoo Luxe</h5>
                    <h1 className="amount_Title">₹5800/-</h1>
                    <p className="month_title">per month</p>
                    <h5 className="ouarterly_Title">OLA/Similar bike</h5>
                  </div>
                  <div className="rent_btns">
                    <Button className="rent_btn mx-3">
                      <img
                        src={rentIcon}
                        alt="Electric Vehicle Rental in Bangalore"
                        className="rent_img "
                      />
                      <Link
                        to="/scooluxe"
                        className="link_text"
                        target="_blank"
                      >
                        <spna className=""> Rent now</spna>
                      </Link>
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default PlanSection;
