import React from "react";
import Carousel from "./Carousel";
import { Card, Col, Container, Row } from "react-bootstrap";
import clubhero from "../../Assets/image/club/clubHero.webp";
import check from "../../Assets/image/club/Check icon.svg";

const ClubWrapper = () => {
  let cards = [
    {
      key: 1,
      content: (
        
        <Card className="club-bg mb-5 club_card ">
          <img src={clubhero} alt="" className="hero-img" />
          <Card.Body>
            <div className="card-text-part px-3">
              <img src={check} alt="" className="check-img" />

              <h6 className="card-head">
                Free pickup & drop of e-bikes from your home location
              </h6>

              <p className="card-para">
                Forget about the inconvenience of traveling to a rental station
                or worrying about how to transport the e-bike to your desired
                starting point.
              </p>
            </div>
          </Card.Body>
        </Card>
      ),
    },
    {
      key: 2,
      content: (
        <Card className="club-bg mb-5 club_card ">
          <img src={clubhero} alt="" className="hero-img" />
          <Card.Body>
            <div className="card-text-part px-3">
              <img src={check} alt="" className="check-img" />

              <h6 className="card-head">
              1 Membership - 3 subscribes
              </h6>

              <p className="card-para">
              Unlock the ultimate flexibility and convenience with our unique 1 :Membership - 3 Subscriptions plan.
              </p>
            </div>
          </Card.Body>
        </Card>
      ),
    },
    {
      key: 3,
      content: (
        <Card className="club-bg mb-5 club_card ">
          <img src={clubhero} alt="" className="hero-img" />
          <Card.Body>
            <div className="card-text-part px-3">
              <img src={check} alt="" className="check-img" />

              <h6 className="card-head">
              Hassel free on service/maintenance concerns
              </h6>

              <p className="card-para">
              Hassel free on service/maintenance concerns",
    para: "We take care of all service and maintenance concerns, ensuring that you can enjoy your rides without any hassle or inconvenience.
              </p>
            </div>
          </Card.Body>
        </Card>
      ),
    },
    {
      key: 4,
      content: (
        <Card className="club-bg mb-5 club_card ">
          <img src={clubhero} alt="" className="hero-img" />
          <Card.Body>
            <div className="card-text-part px-3">
              <img src={check} alt="" className="check-img" />

              <h6 className="card-head">
              Hassel free on service/maintenance concerns
              </h6>

              <p className="card-para">
              Hassel free on service/maintenance concerns",
    para: "We take care of all service and maintenance concerns, ensuring that you can enjoy your rides without any hassle or inconvenience.
              </p>
            </div>
          </Card.Body>
        </Card>
      ),
    },
    {
      key: 5,
      content: (
        <Card className="club-bg mb-5 club_card ">
          <img src={clubhero} alt="" className="hero-img" />
          <Card.Body>
            <div className="card-text-part px-3">
              <img src={check} alt="" className="check-img" />

              <h6 className="card-head">
              Zero hidden costs
              </h6>

              <p className="card-para">
              With our membership program, we ensure that you won't encounter any unexpected surprises or hidden costs.
              </p>
            </div>
          </Card.Body>
        </Card>
      ),
    },
  ];
  return (
    <>
    <Container className="py-5">
        <Row>
            <Col lg={12}>
            <Carousel
          cards={cards}
          height="300px"
          width="100%"
          margin="0 auto"
          offset={100}
          showArrows={false}
        />
            </Col>
        
        </Row>
    
    </Container>
     
    </>
  );
};

export default ClubWrapper;
