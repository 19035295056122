import React from "react";
import B2b from "../Component/B2B/B2b";
import Accessibility from "../Component/RentalsCompoents/AccessibilitySection/Accessibility";
import RentalBanner from "../Component/RentalsCompoents/RentalBanner/RentalBanner";
import RentGreenBanner from "../Component/RentalsCompoents/RentGreenBanner/RentGreenBanner";
import StorySection from "../Component/RentalsCompoents/StorySection/StorySection";
import User from "../Component/RentalsCompoents/UserSection/User";
import FaqSection from "../Component/FaqSection/FaqSection";
import { Helmet } from "react-helmet";

const ScooRentals = () => {
  return (
    <>
      <Helmet>
        <title>EV-Bike Rental in Bengaluru | Scoo Rentals – ScooEV</title>
        <meta
          name="description"
          content="ScooEV Is the leading the way in rider-centric E-Bike rental in Bengaluru, our scooters are customizable based on your needs & usage Rent Now! "
        />
        <meta
          name="keywords"
          content="Electric Bike Rental in Bangalore, E-Bike Rental in Bengaluru, Electric Bikes on Rental Near Me, Ather Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore , Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike  "
        />
      </Helmet>
      <RentalBanner />
      <Accessibility />
      <User />
      <RentGreenBanner />
      <B2b />
      <StorySection />
      {/* <FaqSection /> */}
    </>
  );
};

export default ScooRentals;
