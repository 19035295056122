import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import story from "../../../Assets/image/Story/story.webp";
import right from "../../../Assets/image/Story/right.png";
import left from "../../../Assets/image/Story/left.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HomeBannerData, StorySlider } from "../../../data/dataApi";

import "./story.css";

const StorySection = () => {
  let sliderRef = React.useRef();
  const HomeBanner = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container fluid className="p-lg-5 mb-lg-2 ">
        <section className="storyCard_section">
          <Container>
            <Row className="mb-lg-4">
              <Col md={3} className="p-0 m-0">
                <div>
                  <h4 className="header_line d-flex justify-content-center">
                    Customer success stories
                  </h4>
                </div>
              </Col>
              <Col md={9} className="py-1">
                <span>
                  <hr />
                </span>
              </Col>
            </Row>
          </Container>

          <Container className="story_Card overflow-hidden ">
            <Row className="overflow-hidden mx-2 mt-lg-5">
              <Col md={12} sm={6} className="text-center">
                <h1 className="story_head py-0">Story of Mukesh</h1>
              </Col>
            </Row>
            <Slider {...HomeBanner} ref={sliderRef}>
              {StorySlider.map((data) => {
                const { id, btn } = data;
                return (
                  <Row className="py-3 d-flex" key={id}>
                    <Col lg={6} md={12} sm={6} className="">
                      <img src={story} alt="no logo" className="story_img" />
                    </Col>
                    <Col lg={6} md={12} sm={12} className="">
                      <p className="access_para">{btn}</p>
                      <p className="access_para">
                        Mukesh was only 18 years old when he left his hometown
                        of Guwahati. He moved to Bangalore to earn money and
                        provide for his mother and two younger sisters. Mukesh
                        became the primary source of income for his family when
                        his father passed away. He had to give up his studies
                        for the survival of his family. Life in Bangalore was
                        not easy for him, he struggled to find a job, so he
                        decided to work as a cleaner in a hotel. But, his income
                        wasn’t sufficient for himself, let alone provide for his
                        family. That’s when Mukesh noticed that his friends were
                        able to make a good amount of money by working as food
                        delivery partners. Mukesh knew with his current income
                        he couldn’t afford a new two-wheeler, so he decided to
                        save enough money to rent a scooter
                      </p>
                      <p className="access_para">
                        Scoo EV was one of the first names to come up as they
                        are popular electric rental partners in Bangalore. Most
                        other bike rental brands in Bangalore were expensive and
                        didn’t have the affordability and convenience of payment
                        options that Scoo EV had. With Scoo, Mukesh could skip
                        all vehicle maintenance charges, as they offered free
                        service and roadside assistance. Mukesh has been a Scoo
                        EV user for 6 months now and his monthly earnings are
                        over Rs.35,000. Thanks to the cost-effective features of
                        Scoo EV, Mukesh is now able to provide education for his
                        sisters, medical care for his mother, and save enough
                        for his livelihood
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </Slider>
          </Container>
          {/* <Container className="mt-4 p-0 mb-3">
            <Row>
              <Col md={12}>
                <div className="d-flex">
                  <div className="mx-3">
                    <img
                      src={left}
                      alt="no logo"
                      className="icon_arrows"
                      onClick={() => sliderRef?.current?.slickNext()}
                    />
                  </div>
                  <div>
                    <img
                      src={right}
                      alt="no logo"
                      className="icon_arrows"
                      onClick={() => sliderRef?.current?.slickPrev()}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container> */}
        </section>
      </Container>
    </>
  );
};

export default StorySection;
