import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { termData } from "../TermCondition/termData";
import { Helmet } from "react-helmet";
const TermCondition = () => {
  return (
    <>
      <Helmet>
        <title>ScooEV Terms and Condition | EV Rentals  - ScooEV</title>
        <meta
          name="description"
          content="The Company shall permit the User to use the Vehicle in accordance with the present Terms and conditions herein."
        />
        <meta
          name="keywords"
          content="Scoo Partnership, Electric Bike Rental in Bangalore, Electric Bikes on Rental Near Me, Ather  Rental in Bangalore, Electric Scooter Rental in Bangalore, Electric Vehicle on Rent in Bangalore, Electric Vehicle Rental in Bangalore, Electric Vehicle Rental Near Me, Electric Vehicles For Rent in Bangalore, EV Rentals in Bangalore, Luxury Electric Cars for Hire In Bangalore, Scoo EV Rentals in Bangalore, Self Drive Electric Car Rental in Bangalore,Ev rent , Ev bike rent near me , Electric bike rental monthly , ev bike rental , Electric scooter rental near me , electric scooter rental in bangalore , ola scooter rental  ola s1 rent near me , ola bike rental , ola scooter rent , ather bike rent , ather bike rental near me ,Ev rental bike. "
        />
      </Helmet>
      <Container className="policy_Container mt-5 py-5">
        <h1 className="py-3 privacy_head">Terms & Conditions </h1>
        <Row>
          <Col lg={12}>
            {termData.map((item) => {
              const { id, heading, para, describe } = item;

              return (
                <div key={id}>
                  <p>{para}</p>
                  <div className="mb-4">
                    <h5 className="privacy_header">{heading}</h5>
                    {describe?.map((data) => {
                      const { id, des } = data;
                      return (
                        <div key={id}>
                          <p className="privacy_para">{des}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TermCondition;
