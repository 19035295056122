import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./blogOfTheDay.css";
import "../BlogsPage.css";
import { blogs } from "../../../data/blogData";

export default function BlogOfTheDay() {
  return (
    <Container fluid className="BlogOfTheDayContainer">
      <section>
        <Container className="p-0 d-flex flex-column gap-5 ">
          {blogs.slice(0, 2).map((ele, index) => {
            return (
              ele?.blogOfTheDay == true && (
                <div
                  className="d-flex flex-row flex-wrap gap-2 justify-content-between"
                  key={index}
                >
                  <div className="BlogOfTheDay_Img">
                    <img src={ele?.img} width="100%" height="100%" />
                  </div>
                  <div className="BlogOfTheDay_Info d-flex flex-column justify-content-center">
                    <div className="d-inline-block">
                      <Button className="BlogOfTheDay_Btn">
                        Blog of the day
                      </Button>
                    </div>

                    <p className="BlogOfTheDayTitle p-0 m-0">{ele?.title}</p>
                    <p className="BlogOfTheDayDescription p-0 m-0">
                      {ele?.description?.slice(0, 280) + "..."}
                      <p className="BlogOfTheDayReadmore mt-1 p-0">
                        Read more...
                      </p>
                    </p>

                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
                      <div className="BlogOfTheDayProfileBox d-flex flex-row flex-wrap justify-content-center align-items-center">
                        <div className="BlogOfTheDayProfileImg">
                          <img src={ele?.autherImg} />
                        </div>
                        <div>
                          <h6 className="p-0 my-1 BlogOfTheDayProfileInfoName">
                            {ele?.autherName}
                          </h6>
                          <h6 className="p-0 my-1 BlogOfTheDayProfileInfoDate">
                            {ele?.postDate}
                          </h6>
                        </div>
                      </div>

                      <div className="BlogOfTheDay_Hr" />

                      <div className="BlogsPage_ProfileSocialBox d-flex flex-row flex-wrap gap-2 justify-content-center align-items-center">
                        <div>
                          <img
                            src="Images/Blogs/Facebook.svg"
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div>
                          <img
                            src="Images/Blogs/Instagram.svg"
                            width="100%"
                            height="100%"
                          />
                        </div>

                        <div>
                          <img
                            src="Images/Blogs/Linkedin.svg"
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div>
                          <img
                            src="Images/Blogs/Twitter.svg"
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </Container>
      </section>
    </Container>
  );
}
