import "./rentalcard.css";
import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import rentIcon from "../../../Assets/image/header/Icon.svg";
import down from "../../../Assets/image/Rentals/rentUpIcon.png";
import Button from "react-bootstrap/Button";
import icon from "../../../Assets/image/Rentals/rentUpIcon.png";
import OlaS1ProImage from "../../../Assets/image/Assets/image 16-1.png";
import AtherImage from "../../../Assets/image/Assets/image 17.png";
import { BiChevronDown, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import upIcon from "../../../Assets/image/Rentals/upIcon.png";
import arrow from "../../../Assets/image/Rentals/Right.png";

import greenIcon from "../../../Assets/image/Rentals/green.png";
import lightIcon from "../../../Assets/image/Rentals/light.png";

import quntumLigtIcon from "../../../Assets/image/Rentals/quntumLigt.png";
import quntumGreenIcon from "../../../Assets/image/Rentals/quntumGreen.png";

import NYXgreen from "../../../Assets/image/Rentals/NYXgreen.png";

import leftArrow from "../../../Assets/image/Rentals/arrow/lightLeft.svg";

import {
  LuxProductSliderData,
  RentProductCardData,
  RangeData,
  RangeData2,
} from "../../../data/dataApi";
import scooty from "../../../Assets/image/Assets/image 16.png";
import { Link } from "react-router-dom";
import LeftArrow from "../../../Assets//image/Rentals/rent_arrow3.png";
import rightArrow from "../../../Assets//image/Rentals/rent_arrow4.png";

const RentalCard = () => {
  const [activeBtn, setActiveBtn] = useState(false);

  const [QuantactiveBtn, setQuantActiveBtn] = useState(false);

  let sliderRef = React.useRef();

  let QuantumSliderRef = React.useRef();

  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isCardVisibleNum, setIsCardVisibleNum] = useState();
  const handleArrowClick = (isVisible) => {
    setIsCardVisible(isVisible);
  };

  const QuantumPreveHandler = () => {
    QuantumSliderRef?.current?.slickNext();
    setQuantActiveBtn(!QuantactiveBtn);
  };

  const QuantumNextHandler = () => {
    QuantumSliderRef?.current?.slickNext();
    setQuantActiveBtn(!QuantactiveBtn);
  };

  const preveHandler = () => {
    setActiveBtn(!activeBtn);
    sliderRef?.current?.slickPrev();
  };

  const nextHandler = () => {
    setActiveBtn(!activeBtn);
    sliderRef?.current?.slickNext();
  };

  const Quantumsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        Quantumsettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        Quantumsettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        Quantumsettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container fluid className="product_Wrapper p-lg-0">
        <section>
          <Container>
            <Row className="mb-4">
              {RentProductCardData.map((data) => {
                const {
                  id,
                  head,
                  ruppee,
                  title,
                  month,
                  btn,
                  performance,
                  RentProductSliderData,
                  RentQuantumSliderData,
                } = data;
                return (
                  <Col lg={6} md={6} className="" key={id}>
                    <Card className="product_Card1 ">
                      <Row className="mb-4 product_rows">
                        <Col
                          md={12}
                          className=""
                          onClick={() => {
                            if (isCardVisibleNum === id) {
                              handleArrowClick(true);
                              setIsCardVisibleNum();
                            } else {
                              handleArrowClick(false);
                              setIsCardVisibleNum(id);
                            }
                          }}
                        >
                          <div className="rentnow_btn quarterly_btn1">
                            <div className="rent_nowlux1 ">{head}</div>
                            <div>
                              <img
                                src={down}
                                style={{
                                  display:
                                    isCardVisibleNum === id ? "none" : "block",
                                }}
                                alt="Scoo Luxe Rental in Bangalore"
                                className="icon mx-2"
                              />
                            </div>
                            <div>
                              <img
                                src={down}
                                onClick={() => {
                                  if (isCardVisibleNum === id) {
                                    handleArrowClick(true);
                                    setIsCardVisibleNum();
                                  } else {
                                    handleArrowClick(false);
                                    setIsCardVisibleNum(id);
                                  }
                                }}
                                style={{
                                  display:
                                    isCardVisibleNum === id ? "block" : "none",
                                  transform: "rotate(-181deg)",
                                }}
                                alt="Scoo Luxe Rental in Bangalore"
                                className="icon_up mx-2"
                              />
                            </div>
                          </div>

                          <div>
                            {isCardVisibleNum == id && (
                              <Card className="dwn_Card ">
                                <div>
                                  <ul className="px-4 py-2 mb-2">
                                    {performance?.map((ele) => {
                                      return (
                                        <Link to={ele.url} target="_blank">
                                          <li key={ele?.id} className="">
                                            <div className="year_wrapper">
                                              <div className="mb-1">
                                                {ele?.text}
                                              </div>
                                              <div>
                                                <img src={arrow} alt="" />
                                              </div>
                                            </div>
                                          </li>
                                        </Link>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </Card>
                            )}
                          </div>
                        </Col>
                      </Row>
                      {title === "Quantum" ? (
                        <Row className="product_rows">
                          <Col lg={12} className="qutityScooty_img ">
                            <div className="selectPlan_Card d-flex ">
                              <div>
                                <img
                                  src={
                                    QuantactiveBtn === true
                                      ? NYXgreen
                                      : quntumLigtIcon
                                  }
                                  alt="about us"
                                  className="arrow_title arrow_icon arrow_deactive"
                                  onClick={QuantumPreveHandler}
                                />
                              </div>

                              <div className="slider_container">
                                <Slider
                                  {...Quantumsettings}
                                  ref={QuantumSliderRef}
                                >
                                  {RentQuantumSliderData?.map((data) => {
                                    const { id, img } = data;
                                    return (
                                      <Col lg={4} key={id}>
                                        <img
                                          src={img}
                                          alt="Scoo Luxe Rental in Bangalore"
                                          className="product_slide "
                                        />
                                      </Col>
                                    );
                                  })}
                                </Slider>
                              </div>

                              <div>
                                <img
                                  src={
                                    QuantactiveBtn === true
                                      ? lightIcon
                                      : quntumGreenIcon
                                  }
                                  alt="about us"
                                  className="arrow_icon red_title arrow_active"
                                  onClick={QuantumNextHandler}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row className="product_rows">
                          <Col lg={12} className="qutityScooty_img ">
                            <div className="selectPlan_Card d-flex ">
                              <div>
                                <img
                                  src={
                                    activeBtn === true ? LeftArrow : NYXgreen
                                  }
                                  alt="about us"
                                  className="arrow_title arrow_icon arrow_deactive"
                                  onClick={preveHandler}
                                />
                              </div>

                              <div className="slider_container">
                                <Slider {...settings} ref={sliderRef}>
                                  {RentProductSliderData?.map((data) => {
                                    const { id, img } = data;
                                    return (
                                      <Col lg={4} key={id}>
                                        <img
                                          src={img}
                                          alt="Scoo Luxe Rental in Bangalore"
                                          className="product_slide "
                                        />
                                      </Col>
                                    );
                                  })}
                                </Slider>
                              </div>
                              <div>
                                <img
                                  src={
                                    activeBtn === true
                                      ? quntumGreenIcon
                                      : lightIcon
                                  }
                                  alt="about us"
                                  className="arrow_icon red_title arrow_active"
                                  onClick={nextHandler}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}

                      <div className="py-2">
                        <h5 className="ouarterly_Title pro_Title">{title}</h5>
                        <h1 className="amount_Title">₹{ruppee}/-</h1>
                        <p className="month_title">{month}</p>
                      </div>

                      <Row className=" nyxLEft_padding m-0 p-0">
                        {title === "Quantum"
                          ? RangeData.map((item) => {
                              const { id, img, title, kmeters } = item;
                              return (
                                <>
                                  <Col
                                    md={4}
                                    className="range_col m-0 mb-3"
                                    key={id}
                                  >
                                    <Card className="nyx_Card ">
                                      <div>
                                        <ul className="nyx_list1 mb-2">
                                          <li>
                                            <div className="mb-1">
                                              <img
                                                src={img}
                                                alt="Scoo Rental"
                                              />
                                            </div>
                                          </li>
                                          <li>
                                            <div className="speed_Title">
                                              {title}
                                            </div>
                                          </li>
                                          <li>
                                            <div className="km_title">
                                              {kmeters}
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Card>
                                  </Col>
                                </>
                              );
                            })
                          : RangeData2.map((item) => {
                              const { id, img, title, kmeters } = item;
                              return (
                                <>
                                  <Col
                                    md={4}
                                    className="range_col m-0 mb-3"
                                    key={id}
                                  >
                                    <Card className="nyx_Card ">
                                      <div>
                                        <ul className="nyx_list1 mb-2">
                                          <li>
                                            <div className="mb-1">
                                              <img
                                                src={img}
                                                alt="Scoo Rental"
                                              />
                                            </div>
                                          </li>
                                          <li>
                                            <div className="speed_Title">
                                              {title}
                                            </div>
                                          </li>
                                          <li>
                                            <div className="km_title">
                                              {kmeters}
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Card>
                                  </Col>
                                </>
                              );
                            })}
                      </Row>

                      <div className="rent_btns ">
                        <Link
                          to="https://scoomerchandise.myshopify.com/collections/scoo-rental"
                          className="link_text productRent_lnik"
                          target="_blank"
                        >
                          <Button className="productRent_btn1 ">
                            <img
                              src={rentIcon}
                              alt=" Scoo Luxe Rental in Bangalore"
                              className="rent_img "
                            />
                            <spna className=""> {btn}</spna>
                          </Button>
                        </Link>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default RentalCard;
